import { useDebounce } from "@uidotdev/usehooks";
import { Dispatch, SetStateAction, useState } from "react";

export function useDebouncedValue<S>(
  defaultValue: S
): [S, S, Dispatch<SetStateAction<S>>] {
  const [value, setValue] = useState(defaultValue);
  const debouncedValue = useDebounce(value, 1000);

  return [debouncedValue, value, setValue];
}

export default useDebouncedValue;
