import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  Pagination,
  useTheme,
  useMediaQuery,
  Container,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Footer, Header } from "../components";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const Gizlilik = () => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [width, setWidth] = useState<number>(600);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1); // Yeni bir dosya yüklendiğinde sayfayı birinci sayfaya sıfırlar
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
  };

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = window.innerWidth * (isSmallScreen ? 0.9 : 0.7); // Mobilde %90, diğerlerinde %70
      setWidth(containerWidth > 800 ? 800 : containerWidth);
    };

    handleResize(); // İlk yüklemede çağır
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isSmallScreen]);

  return (
    <Container>
      <Box mx={{ xs: 1, sm: 2, md: 4 }} my={2}>
        <Header />
        <Box
          py={2}
          sx={{
            backgroundColor: "#002B32",
            borderBottomLeftRadius: { xs: "16px", sm: "24px" },
            borderBottomRightRadius: { xs: "16px", sm: "24px" },
          }}
        ></Box>

        <Grid container spacing={2} my={3}>
          {/* Sidebar - Politikalar Menüsü */}
          <Grid item xs={12} sm={3} md={2}>
            <Box
              sx={{
                backgroundColor: "#E6F3F0",
                borderRadius: "8px",
                p: { xs: 2, sm: 3 },
                mb: { xs: 2, sm: 0 },
              }}
            >
              <Typography variant="h6" gutterBottom>
                Politikalar
              </Typography>
              <Divider
                sx={{
                  backgroundColor: "#002B32",
                  opacity: "0.3",
                  height: "2px",
                  my: 2,
                }}
              />
              <Box
                onClick={() => {
                  window.location.href = "/hakkimizda";
                }}
                sx={{
                  borderRadius: "8px",
                  py: 1,
                }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  pl={1}
                  variant="body1"
                >
                  Hakkımızda
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_BACKEND_URL}/uploads/mevzuat.pdf`,
                    "_blank"
                  );
                }}
                sx={{
                  borderRadius: "8px",
                  py: 1,
                  my: 1,
                }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  pl={1}
                  variant="body1"
                >
                  Mevzuat
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_BACKEND_URL}/uploads/yetki-belgesi.pdf`,
                    "_blank"
                  );
                }}
                sx={{
                  borderRadius: "8px",
                  py: 1,
                  my: 1,
                }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  pl={1}
                  variant="body1"
                >
                  Yetki Belgesi
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  window.location.href = "/gizlilik";
                }}
                sx={{
                  backgroundColor: "rgba(0, 0, 0, 0.12)",
                  borderRadius: "8px",
                  py: 1,
                }}
              >
                <Typography
                  sx={{
                    cursor: "pointer",
                  }}
                  pl={1}
                  variant="body1"
                >
                  Gizlilik
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Ana İçerik - PDF Görüntüleyici */}
          <Grid item xs={12} sm={9} md={10} spacing={2}>
            <Box p={{ xs: 2 }}>
              <Typography
                sx={{
                  fontSize: 48,
                  fontWeight: "bold",
                }}
              >
                Gizlilik
              </Typography>

              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: "regular",
                  mt: 2,
                }}
              >
                Karma Grup Dış Ticaret Otomotiv Makina İnşaat Nakliyat Sanayi
                Limited Şirketi ("KARMA GRUP"), 6698 sayılı Kişisel Verilerin
                Korunması Kanunu ("KVKK") kapsamında, Veri Sorumlusu sıfatıyla,
                işleme amacına uygun, sınırlı ve ölçülü şekilde talep ettiğimiz
                ve tarafınızca paylaşılan kişisel verilerinizi işlemektedir. Bu
                doğrultuda, verileriniz, işlenme amacına uygun olarak
                kaydedilecek, depolanacak, muhafaza edilecek, yeniden
                düzenlenecek, hukuken bu verileri talep etmeye yetkili
                kurumlarla paylaşılacak; KVKK ve diğer ilgili mevzuatta
                öngörülen koşullar çerçevesinde yurt içi veya yurt dışındaki
                üçüncü kişilere aktarılabilecek, devredilebilecek,
                sınıflandırılabilecek ve KVKK’da belirtilen diğer yöntemlerle
                işlenebilecektir.<br></br> <br></br> KVKK’nın 11. maddesi
                kapsamındaki haklarınızı kullanmak amacıyla, Beylikdüzü OSB Mah.
                16. Cad. Shell Petrol İstasyonu No:3, İç Kapı No:1 Beylikdüzü /
                İstanbul adresinde bulunan Karma Grup Dış Ticaret Otomotiv
                Makina İnşaat Nakliyat Sanayi Limited Şirketi'ne yazılı
                başvuruda bulunabilirsiniz. KARMA GRUP, başvurunuzu niteliğine
                göre en kısa sürede ve en geç otuz gün içinde ücretsiz olarak
                sonuçlandıracaktır. Ancak, işlemin KARMA GRUP’a ek bir maliyet
                getirmesi halinde, Kişisel Verileri Koruma Kurulu tarafından
                belirlenen tarifedeki ücret alınabilecektir.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Footer />
      </Box>
    </Container>
  );
};

export default Gizlilik;
