export const useStoredRef = () => {
  const reference = localStorage.getItem("reference");
  const referenceTime = localStorage.getItem("reference_time");

  const clearReference = () => {
    localStorage.removeItem("reference");
    localStorage.removeItem("reference_time");
  };

  return { reference, referenceTime, clearReference };
};
