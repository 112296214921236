import { useEffect, useState } from "react";
import {
  startOfMonth,
  getDaysInMonth,
  format,
  addMonths,
  subMonths,
  formatDate,
  isToday,
  isBefore,
  startOfDay,
  setDate,
} from "date-fns";

import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Iconify } from "../../components";
import { IMontageApplication, IServiceProvider } from "../../interface";
import { fDate, fetcher } from "../../utils";
import useSWR from "swr";

const Cell = ({
  index,
  date,
  selectedDates,
  onSelect,
  disabledDates,
}: {
  index: number;
  date: Date;
  selectedDates: string[];
  onSelect: (d: string) => void;
  disabledDates?: string[];
}) => {
  const [myDate, setMyDate] = useState<Date | null>(null);

  useEffect(() => {
    if (date) {
      let newDate = date;
      newDate = setDate(newDate, index + 1 || 1);
      setMyDate(newDate);
    }
  }, [date, index]);

  const isPast = myDate ? isBefore(myDate, new Date()) : false;
  const isSelected = myDate
    ? selectedDates.includes(format(myDate, "yyyy-MM-dd"))
    : false;
  const isTodayCell = myDate ? isToday(myDate) : false;
  const isDisabled = myDate
    ? disabledDates?.includes(format(myDate, "yyyy-MM-dd"))
    : false;

  return (
    <div
      className={`cell${isTodayCell ? " today" : ""}${
        isPast ? " disabled" : ""
      }${isSelected ? " filled" : ""}${isDisabled ? " booked" : ""}`}
      onClick={() => {
        if (myDate) {
          onSelect(format(startOfDay(myDate), "yyyy-MM-dd"));
        }
      }}
    >
      <div>{index + 1}</div>
    </div>
  );
};

export const MontageCalendar = ({
  montage,
  open,
  onSaved,
  handleClose,
  maxCount = Number.MAX_SAFE_INTEGER,
}: {
  montage: IMontageApplication;
  open: boolean;
  onSaved: ({ service, dates }: { service: string; dates: string[] }) => void;
  handleClose: () => void;
  maxCount?: number;
}) => {
  const [selectedService, setSelectedService] = useState("");
  const [date, setDate] = useState(new Date());
  const firstGap = startOfMonth(date).getDay() - 1;
  const dayCount = getDaysInMonth(date);

  const month_str = fDate(date, "MMMM yyy");

  const [selectedDates, setSelectedDates] = useState<string[]>([]);

  useEffect(() => {
    setSelectedDates([]);
  }, [selectedService]);

  const { data: services } = useSWR<IServiceProvider[]>(
    `/service-provider/application`,
    fetcher
  );

  const { data, isLoading } = useSWR<{ available: boolean; date: string }[]>(
    selectedService
      ? `/service-provider/${selectedService}/available?month=${fDate(
          date,
          "yyy-MM"
        )}`
      : "",
    fetcher
  );

  const disabledCalender = !selectedService;

  const disabledDates = (data ?? [])
    .filter((row) => !row.available)
    .map((row) => row.date);

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth
      open={open}
      onClose={() => {
        handleClose();
        setSelectedDates([]);
        setSelectedService("");
      }}
      sx={{ "&> .MuiDialog-container ": { alignItems: "start" } }}
    >
      {isLoading && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 2,
            backgroundColor: "rgba(0,0,0,.3)",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Stack
        sx={{ p: 3, pb: 0 }}
        direction="row"
        justifyContent="space-between"
      >
        <IconButton
          onClick={() => {
            const d = subMonths(new Date(date), 1);
            setDate(d);
          }}
          disabled={disabledCalender}
        >
          <Iconify icon="ic:baseline-chevron-left" />
        </IconButton>
        <Typography
          sx={{
            color: "#344054",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: 500,
          }}
        >
          <b>{month_str}</b>
        </Typography>
        <IconButton
          disabled={disabledCalender}
          onClick={() => {
            const d = addMonths(new Date(date), 1);
            setDate(d);
          }}
        >
          <Iconify icon="ic:baseline-chevron-right" />
        </IconButton>
      </Stack>
      <Stack>
        <Box sx={{ p: 3 }}>
          <div className={`Calendar ${disabledCalender ? "disabled" : ""}`}>
            {[
              "Pazartesi",
              "Salı",
              "Çarşamba",
              "Perşembe",
              "Cuma",
              "Cumartesi",
              "Pazar",
            ].map((str) => (
              <div className="days" key={str}>
                <span>{str}</span>
              </div>
            ))}
            {firstGap > 0 &&
              [...Array.from(Array(firstGap).keys())].map((i) => (
                <div className="hidden" key={i * -1}></div>
              ))}

            {firstGap === -1 &&
              [...Array.from(Array(6).keys())].map((i) => (
                <div className="hidden" key={i * -2}></div>
              ))}

            {[...Array.from(Array(dayCount).keys())].map((i) => (
              <Cell
                onSelect={(date: string) => {
                  const t = [...selectedDates];
                  if (selectedDates.includes(date)) {
                    t.splice(t.indexOf(date), 1);
                  } else {
                    if (selectedDates.length < maxCount) {
                      t.push(date);
                    }
                  }
                  setSelectedDates(t);
                }}
                selectedDates={selectedDates}
                date={date}
                key={i}
                index={i}
                disabledDates={disabledDates}
              />
            ))}
          </div>
        </Box>

        {!!selectedDates.length && (
          <Stack
            sx={{
              py: 2,
              px: 3,
              borderTop: "1px solid",
              borderTopColor: "divider",
            }}
          >
            <Stack gap={1}>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#344054",
                  fontWeight: 500,
                }}
              >
                Seçili Günler
              </Typography>
              <Typography
                sx={{ fontSize: "14px", lineHeight: "20px", color: "#344054" }}
              >
                {selectedDates.map((d) => fDate(d)).join(", ")}
              </Typography>
            </Stack>
          </Stack>
        )}

        <Stack sx={{ p: 3, borderTop: "1px solid", borderTopColor: "divider" }}>
          <Stack
            justifyContent={"space-between"}
            alignItems="center"
            direction="row"
          >
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>Bayii Seç</InputLabel>
              <Select
                value={selectedService}
                onChange={(e) => {
                  setSelectedService(e.target.value);
                }}
                size="small"
                label="Bayii Seç"
              >
                {services?.map((service) => (
                  <MenuItem
                    key={service._id.toString()}
                    value={service._id.toString()}
                  >
                    {`${service.offical_title} ${
                      service.address
                        ? `(${
                            service.address.city ? service.address.city : ""
                          }${
                            service.address.district
                              ? " " + service.address.district
                              : ""
                          })`
                        : ""
                    }`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack gap={1} direction="row">
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => {
                  setSelectedDates([]);
                  setSelectedService("");
                  handleClose();
                }}
              >
                İptal
              </Button>
              <Button
                // disabled={maxCount !== selectedDates.length}
                disabled={selectedDates.length === 0}
                variant="contained"
                color="secondary"
                onClick={() => {
                  onSaved({ dates: selectedDates, service: selectedService });
                  setSelectedDates([]);
                  setSelectedService("");
                  handleClose();
                }}
              >
                {!selectedService
                  ? "Lütfen Bayii Seçin"
                  : // : maxCount !== selectedDates.length
                  selectedDates.length === 0
                  ? `Lütfen Tarih Seçin` // (${maxCount - selectedDates.length})
                  : "Onayla"}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};
