import { Navigate, useParams } from "react-router-dom";
import { AdminLayout } from "../../../components";
import { PATHS } from "../../../routes";
import { ServiceProfileSection } from "../../../section";

export const ServiceProfilePage = () => {
  const { id } = useParams();
  if (!id) {
    return <Navigate to={PATHS.admin.servicesProviders.root} />;
  }
  return (
    <AdminLayout>
      <ServiceProfileSection />
    </AdminLayout>
  );
};
