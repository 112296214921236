import React from "react";
import { Container, Typography, Box, Paper } from "@mui/material";

const KvkkPage: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ my: 2 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Box sx={{ textAlign: "center", mb: 3 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            KARMA GRUP KVKK
          </Typography>
        </Box>
        <Typography variant="body1" component="p" gutterBottom>
          Karma Grup Dış Ticaret Otomotiv Makina İnşaat Nakliyat Sanayi Limited
          Şirketi ("KARMA GRUP"), 6698 sayılı Kişisel Verilerin Korunması Kanunu
          ("KVKK") kapsamında, Veri Sorumlusu sıfatıyla, işleme amacına uygun,
          sınırlı ve ölçülü şekilde talep ettiğimiz ve tarafınızca paylaşılan
          kişisel verilerinizi işlemektedir. Bu doğrultuda, verileriniz, işlenme
          amacına uygun olarak kaydedilecek, depolanacak, muhafaza edilecek,
          yeniden düzenlenecek, hukuken bu verileri talep etmeye yetkili
          kurumlarla paylaşılacak; KVKK ve diğer ilgili mevzuatta öngörülen
          koşullar çerçevesinde yurt içi veya yurt dışındaki üçüncü kişilere
          aktarılabilecek, devredilebilecek, sınıflandırılabilecek ve KVKK’da
          belirtilen diğer yöntemlerle işlenebilecektir.
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          KVKK’nın 11. maddesi kapsamındaki haklarınızı kullanmak amacıyla,
          Beylikdüzü OSB Mah. 16. Cad. Shell Petrol İstasyonu No:3, İç Kapı No:1
          Beylikdüzü / İstanbul adresinde bulunan Karma Grup Dış Ticaret
          Otomotiv Makina İnşaat Nakliyat Sanayi Limited Şirketi'ne yazılı
          başvuruda bulunabilirsiniz. KARMA GRUP, başvurunuzu niteliğine göre en
          kısa sürede ve en geç otuz gün içinde ücretsiz olarak
          sonuçlandıracaktır. Ancak, işlemin KARMA GRUP’a ek bir maliyet
          getirmesi halinde, Kişisel Verileri Koruma Kurulu tarafından
          belirlenen tarifedeki ücret alınabilecektir.
        </Typography>
      </Paper>
    </Container>
  );
};

export default KvkkPage;
