import { format, getTime, formatDistanceToNow, parseISO } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { tr } from "date-fns/locale";

type InputValue = Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm, { locale: tr }) : "";
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm, { locale: tr }) : "";
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale: tr,
      })
    : "";
}

export function formatApplicationDates(dates: string[]) {
  const formattedDates = dates
    .map((date) => {
      const parsedDate = parseISO(date);
      const zonedDate = toZonedTime(parsedDate, "UTC");
      return {
        day: format(zonedDate, "yyyy-MM-dd", { locale: tr }), // ISO formatında sıralama için
        displayDay: format(zonedDate, "dd MMM yyyy", { locale: tr }), // İnsan okuyabilir format
        time: format(zonedDate, "HH:mm", { locale: tr }),
      };
    })
    .reduce<Record<string, { displayDay: string; times: string[] }>>(
      (acc, curr) => {
        const { day, displayDay, time } = curr;
        if (!acc[day]) {
          acc[day] = { displayDay, times: [] };
        }
        acc[day].times.push(time);
        return acc;
      },
      {}
    );

  return Object.entries(formattedDates)
    .sort(([dayA], [dayB]) => {
      return new Date(dayA).getTime() - new Date(dayB).getTime();
    })
    .map(([_, { displayDay, times }]) => {
      const sortedTimes = (times ?? []).sort((a, b) => a.localeCompare(b));
      return `${displayDay} ${sortedTimes.join(" ")}`;
    })
    .join(" | ");
}
