import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Footer, Header } from "../components";
const faqData = [
  // ... mevcut FAQ verileriniz
];

const MusteriTemsilcisi = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={2} sx={{}}>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Header />
        <Box
          sx={{
            backgroundColor: "#002B32",
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
            p: { xs: 3, sm: 5 },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F9FAFB",
              borderRadius: "12px",
              p: { xs: 3, sm: 5 },
            }}
          >
            <Box pt={isMobile ? 5 : 10}>
              <Typography
                sx={{
                  color: "#006C51",
                  fontSize: { xs: 16, sm: 20 },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                İletişim
              </Typography>
              <Typography
                sx={{
                  py: 1,
                  color: "#000",
                  fontSize: { xs: 32, sm: 48 },
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                İletişim Kanalları
              </Typography>

              <Typography
                mb={7}
                sx={{
                  color: "#191C22",
                  fontSize: { xs: 16, sm: 18 },
                  fontWeight: 400,
                  textAlign: "center",
                  lineHeight: 1.6,
                }}
              >
                Başvurun onaylandıktan sonra mobil uygulamadan en yakındaki
                kuryemiz anında aracınıza gelip montaj hizmetini tamamlasın!
                <br />
                Bekleme olmadan anda montaj kaliteli hizmet! Tüm süreci mobil
                uygulamandan takip et!
              </Typography>
            </Box>
            <Grid
              container
              spacing={{ xs: 2, sm: 3, md: 4 }}
              justifyContent="center"
            >
              {/* WhatsApp İletişim Hattı */}
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#E6F3F0",
                    px: 2,
                    py: 4,
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%", // Aynı yükseklikte olmalarını sağlar
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <img
                      src={require("../assets/img/icon1.png")}
                      alt="WhatsApp İletişim Hattı"
                      style={{
                        maxWidth: "80px",
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, sm: 24 },
                      fontWeight: "medium",
                      color: "#1C1B1C",
                      py: 1,
                    }}
                  >
                    WhatsApp İletişim Hattı
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 18 },
                      fontWeight: "regular",
                      color: "#1C1B1C",
                    }}
                  ></Typography>
                </Box>
              </Grid>

              {/* Call Center İletişim Hattı */}
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#E6F3F0",
                    px: 2,
                    py: 4,
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%", // Aynı yükseklikte olmalarını sağlar
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <img
                      src={require("../assets/img/icon2.png")}
                      alt="Call Center İletişim Hattı"
                      style={{
                        maxWidth: "80px",
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, sm: 24 },
                      fontWeight: "medium",
                      color: "#1C1B1C",
                      py: 1,
                    }}
                  >
                    Call Center İletişim Hattı
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 18 },
                      fontWeight: "regular",
                      color: "#1C1B1C",
                    }}
                  >
                    (0212) 599 84 65
                  </Typography>
                </Box>
              </Grid>

              {/* Mail Adresi */}
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#E6F3F0",
                    px: 2,
                    py: 4,
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%", // Aynı yükseklikte olmalarını sağlar
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <img
                      src={require("../assets/img/icon3.png")}
                      alt="Mail Adresi"
                      style={{
                        maxWidth: "80px",
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, sm: 24 },
                      fontWeight: "medium",
                      color: "#1C1B1C",
                      py: 1,
                    }}
                  >
                    Mail Adresi
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 18 },
                      fontWeight: "regular",
                      color: "#1C1B1C",
                    }}
                  >
                    kittak@utts.com
                  </Typography>
                </Box>
              </Grid>

              {/* Adres Bilgisi */}
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  sx={{
                    backgroundColor: "#E6F3F0",
                    px: 2,
                    py: 4,
                    borderRadius: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                    height: "100%", // Aynı yükseklikte olmalarını sağlar
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mb: 2,
                    }}
                  >
                    <img
                      src={require("../assets/img/icon4.png")}
                      alt="Adres Bilgisi"
                      style={{
                        maxWidth: "80px",
                        width: "100%",
                        height: "auto",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: 20, sm: 24 },
                      fontWeight: "medium",
                      color: "#1C1B1C",
                      py: 1,
                    }}
                  >
                    Adres Bilgisi
                  </Typography>

                  <Typography
                    sx={{
                      fontSize: { xs: 16, sm: 18 },
                      fontWeight: "regular",
                      color: "#1C1B1C",
                    }}
                  >
                    Fatih, Küçükçekmece Yolu, 34290 Küçükçekmece/İstanbul
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default MusteriTemsilcisi;
