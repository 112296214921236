import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";

import React, { useEffect } from "react";
import { SWRConfig } from "swr";
import { SnackbarProvider } from "notistack";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
// import ReactGA from "react-ga4";
import Home from "./pages/Home";
import MontajNoktalari from "./pages/MontajNoktalari";
import MontajBasvuruForm from "./pages/MontajBasvuruForm";
import AracMontajBasvuru from "./pages/AracMontajBasvuru";
import UttsNedir from "./pages/UttsNedir";
import NedenKitTakOrtak from "./pages/NedenKitTakOrtak";
import SıkSorulanSorular from "./pages/SıkSorulanSorular";
import MusteriTemsilcisi from "./pages/MusteriTemsilcisi";
import Admin from "./pages/AdminPage";
import BasvuruDetay from "./pages/BasvuruDetay";
import KvkkPage from "./pages/KVKK";
import Mevzuat from "./pages/Mevzuat";
import Hakkimizda from "./pages/Hakkimizda";
import Gizlilik from "./pages/Gizlilik";
import { createGlobalStyle } from "styled-components";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import PageViewTracker from "./components/PageViewTracker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { tr } from "date-fns/locale";
import { AdminProvider } from "./context";
import {
  AdminLoginPage,
  Dashboard,
  NotFound,
  ServiceApplicationList,
  ServiceListPage,
  ServiceProfilePage,
  ReservationAllPage,
  ReservationDetailPage,
  InvitationPasswordPage,
  ServiceDashboardPage,
  RefListPage,
  ReferenceDetailPage,
  RefLoginPage,
  ForgotPasswordPage,
} from "./pages/";
import { PATHS } from "./routes/PATHS";
import { ServiceMyPage } from "./pages/service/ServiceMyPage";

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Inter', 'Roboto', sans-serif;
  }
  a{
    color: unset;
    text-decoration: none;
  }
`;

const theme = createTheme({
  typography: {
    fontFamily: "'Inter', 'Roboto', sans-serif",
  },
  palette: {
    secondary: { main: "#002B32" },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiContainer: {
      defaultProps: {
        maxWidth: "xl",
      },
    },
    MuiTextField: {
      defaultProps: {
        slotProps: {
          inputLabel: {
            shrink: true,
          },
        },
      },
    },
  },
});

// const GA_MEASUREMENT_ID = "G-RHMGJH0EDY"; // Kendi GA4 Ölçüm Kimliğinizi kullanın

const App: React.FC = () => {
  // useEffect(() => {
  //   ReactGA.initialize(GA_MEASUREMENT_ID);
  //   ReactGA.send({
  //     hitType: "pageview",
  //     page: window.location.pathname + window.location.search,
  //   });
  // }, []);

  return (
    <>
      <SnackbarProvider />
      <SWRConfig>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={tr}>
            <CssBaseline />
            <GlobalStyle />
            <AdminProvider>
              <Router>
                <PageViewTracker />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/utts-montaj-noktalari"
                    element={<MontajNoktalari />}
                  />
                  <Route
                    path="/utts-montaj-basvuru"
                    element={<MontajBasvuruForm />}
                  />
                  <Route
                    path="/utts-kayit-sureci"
                    element={<AracMontajBasvuru />}
                  />
                  <Route path="/utts-nedir" element={<UttsNedir />} />
                  <Route
                    path="/utts-kittak-is-ortagi"
                    element={<NedenKitTakOrtak />}
                  />
                  <Route
                    path="/utts-sık-sorulan-sorular"
                    element={<SıkSorulanSorular />}
                  />
                  <Route
                    path="/utts-musteri-temsilcisi"
                    element={<MusteriTemsilcisi />}
                  />
                  <Route path="/kittak-admin" element={<Admin />} />
                  <Route
                    path="/kittak-admin/basvuru/:id"
                    element={<BasvuruDetay />}
                  />
                  <Route path="/kvkk" element={<KvkkPage />} />
                  <Route path="/mevzuat" element={<Mevzuat />} />
                  <Route path="/hakkimizda" element={<Hakkimizda />} />
                  <Route path="/gizlilik" element={<Gizlilik />} />

                  <Route
                    path="/invitation/:token"
                    element={<InvitationPasswordPage />}
                  />

                  <Route
                    path={PATHS.admin.login}
                    element={<AdminLoginPage />}
                  />
                  <Route path={PATHS.admin.root} element={<Dashboard />} />
                  <Route
                    path={PATHS.admin.montageApplication.root}
                    element={<Dashboard />}
                  />
                  <Route
                    path={PATHS.admin.servicesProviders.root}
                    element={<ServiceListPage />}
                  />
                  <Route
                    path={PATHS.admin.servicesProviders.application}
                    element={<ServiceApplicationList />}
                  />
                  <Route
                    path={PATHS.admin.servicesProviders.profile.root}
                    element={<ServiceProfilePage />}
                  />
                  <Route
                    path={PATHS.admin.reservation.root}
                    element={<ReservationAllPage />}
                  />
                  <Route
                    path={PATHS.admin.reservation.detail.root}
                    element={<ReservationDetailPage />}
                  />

                  <Route
                    path={PATHS.admin.service.root}
                    element={<ServiceDashboardPage />}
                  />

                  <Route
                    path={PATHS.admin.service.profile.root}
                    element={<ServiceMyPage />}
                  />

                  <Route
                    path={PATHS.admin.referance.root}
                    element={<RefListPage />}
                  />

                  <Route
                    path={PATHS.ref.root}
                    element={<ReferenceDetailPage />}
                  />

                  <Route path={PATHS.ref.login} element={<RefLoginPage />} />

                  <Route
                    path={PATHS.forgotPassword}
                    element={<ForgotPasswordPage />}
                  />

                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Router>
            </AdminProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </SWRConfig>
    </>
  );
};

export default App;
