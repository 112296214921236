import { useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../../utils";
import { IconButton, Stack, Typography } from "@mui/material";
import { Iconify } from "../../../components";
import { Link } from "react-router-dom";
import { PATHS } from "../../../routes";
import { MontageDetailModal } from "../montage";

export const ReservationDetailSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  //   const { data } = useSWR(`/montage/application/${id}`, fetcher);

  return (
    <Stack gap={2}>
      {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{ fontSize: "20px", lineHeight: "30px", fontWeight: 500 }}
        >
          Montaj Başvuru Bilgileri
        </Typography>
        <Link to={PATHS.admin.reservation.root}>
          <IconButton>
            <Iconify icon="ic:baseline-close" />
          </IconButton>
        </Link>
      </Stack> */}

      {id && (
        <MontageDetailModal
          selectedMontage={id}
          setSelectedMontage={() => {
            navigate(PATHS.admin.reservation.root);
          }}
        />
      )}
    </Stack>
  );
};
