import { ReactNode } from "react";
import { useAuth } from "../context";
import { Navigate } from "react-router-dom";
import { Box, CircularProgress, Dialog } from "@mui/material";
import { PATHS } from "../routes";

export const ProtectedRoute: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, isLoading } = useAuth();

  if (isLoading) {
    return (
      <Dialog open={true}>
        <Box sx={{ p: 3, backgroundColor: "white" }}>
          <CircularProgress color="secondary" />
        </Box>
      </Dialog>
    );
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to={PATHS.admin.login} />;
  }

  return <>{children}</>;
};
