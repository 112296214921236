import {
  Alert,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import MaskedInput from "react-text-mask";
import useSWR from "swr";
import { Iconify } from "../../components";
import { useAuth } from "../../context";
import {
  DayNames,
  Days,
  ServiceActiveTimes,
  ServiceBusinessTypes,
  ServiceVehicleTypes,
  ServiceWorkspaceTypes,
  YesNo,
} from "../../data";
import { useMe } from "../../hooks";
import { ServiceStatusList } from "../../interface";
import { axiosInstance, fetcher } from "../../utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface IFile {
  url: string;
  name: string;
  uploadTime: string;
}

export const UploadZone = ({
  onSave,
  files,
  showInfo,
  disabled,
}: {
  onSave: (files: any[]) => void;
  files?: any[];
  showInfo?: boolean;
  disabled?: boolean;
}) => {
  const [loading, setLoading] = useState(false);

  const filesTabs = [
    "Vergi levhası",
    "Dekont",
    "Ruhsat",
    "Ticari Sicil Gazetesi",
    "Faaliyet Belgesi",
    "İmza Sirküleri",
    "1. Teknisyen Kimlik Arkalı Önlü",
    "2. Teknisyen Kimlik Arkalı Önlü",
    "İşyeri Görselleri",
  ];
  const [selectedTab, setSelectedTab] = useState(filesTabs[0]);
  const activeTab = filesTabs.indexOf(selectedTab);

  const onSelectFile = async (selectedFiles?: FileList) => {
    setLoading(true);
    try {
      if (selectedFiles && selectedFiles[0]) {
        const form = new FormData();
        form.append("file", selectedFiles[0]);
        const { data, status } = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/upload`,
          form
        );

        if (status === 200) {
          const file = {
            ...data,
            name: selectedTab,
          };

          if (files) {
            const fileList = [...files];
            fileList[activeTab] = file;
            onSave(fileList);
            enqueueSnackbar(`Dosya başarıyla yüklendi.`, {
              variant: "success",
            });
          }
        }
      }
    } catch (error) {
      enqueueSnackbar("Beklenmedik bir hata oluştu.", { variant: "error" });
    }
    setLoading(false);
  };

  return (
    <Stack gap={1}>
      <Stack direction="row" gap={1} flexWrap={"wrap"}>
        {filesTabs.map((tab, index) => (
          <Chip
            label={tab}
            key={index}
            sx={{ cursor: "pointer" }}
            variant="filled"
            color={selectedTab === tab ? "secondary" : "default"}
            onClick={() => {
              setSelectedTab(tab);
            }}
            icon={
              files?.length && files[index] ? (
                <Iconify
                  sx={{ ml: 1, mr: -1 }}
                  icon="ic:round-check"
                  width={12}
                />
              ) : undefined
            }
          />
        ))}
      </Stack>
      <Box
        sx={{
          py: 4,
          borderRadius: 2,
          border: "1px solid",
          borderColor: "divider",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {!!files?.length && files[activeTab] ? (
          <Stack direction="row" gap={2} py={2} alignItems="center">
            <Typography>{files[activeTab].name} Yüklendi</Typography>
            <IconButton
              color="secondary"
              onClick={() => {
                const fileList = [...files];
                fileList[activeTab] = null;
                onSave(fileList);
                enqueueSnackbar(`Dosya başarıyla silindi.`, {
                  variant: "success",
                });
              }}
            >
              <Iconify icon="ic:round-delete" />
            </IconButton>
          </Stack>
        ) : (
          <>
            <Button
              disabled={loading || disabled}
              component="label"
              role={undefined}
              variant="contained"
              color="secondary"
              startIcon={<Iconify icon="ic:round-upload" />}
            >
              {loading ? "Yükleniyor" : "Dosya Seç ve Yükle"}
              <VisuallyHiddenInput
                accept="application/pdf, image/jpeg, image/png"
                type="file"
                onChange={(e) => {
                  onSelectFile(e.target.files!);
                }}
              />
            </Button>
            <small style={{ opacity: 0.5 }}>pdf-png formatında.max 10 MB</small>
          </>
        )}
      </Box>
      {showInfo && (
        <Alert variant="filled" color="info">
          Evraklarınız genel müdürlüğe ulaştı sürecin devamını profil
          ekranınızdan takip edebilirsiniz
        </Alert>
      )}
    </Stack>
  );
};

export const ServiceOnboardingSection = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const { user } = useAuth();
  const { me } = useMe();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const [hitCount, setHitCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [firstZoom, setFirstZoom] = useState(true);

  const [isSended, setSended] = useState(false);

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const [selectedPosition, setSelectedPosition] =
    useState<google.maps.LatLngLiteral | null>(null);

  const [mapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 40.98691,
    lng: 28.76995,
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    map.setCenter(mapCenter);
    map.setZoom(10);

    setMap(map);
  }, []);

  useEffect(() => {
    if (me?.coordinates && map) {
      const location = {
        lat: me.coordinates.lat,
        lng: me.coordinates.lng,
      };
      setSelectedPosition(location);

      map.setCenter(location); // Merkezi ayarlıyoruz
      map.setZoom(16);
    }
  }, [me, map]);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const [formData, setFormData] = useState({
    authorized_person_name: "",
    authorized_person_phone: "",
    signage_name: "",
    offical_title: "",
    tax_office: "",
    tax_number: "",
    address: {
      city: "",
      district: "",
      zip: "",
      fullAddress: "",
      neighborhood: "",
    },
    business_type: "",
    workplace_type: "",
    total_vehicle_capacity: null,
    vehicles_for_installation: null,
    has_internet_service: "Evet",
    vehicle_types_for_service: [],
    technicians: [],
    total_employees: null,
    employees_for_installation: null,
    appointment_phone: "",
    working_days: [],
    files: [null, null, null, null, null, null, null, null],
    coordinates: {
      lat: 0,
      lng: 0,
    },
    working_hours: [],
  });

  const [techForm, setTechForm] = useState({ name: "", email: "", phone: "" });

  useEffect(() => {
    if (me && hitCount === 0 && isLoaded && map) {
      const f = {
        ...formData,
        ...{
          authorized_person_name: me.authorized_person_name,
          authorized_person_phone: me.authorized_person_phone,
          signage_name: me.signage_name ?? "",
          offical_title: me.offical_title ?? "",
          tax_office: me.tax_office ?? "",
          tax_number: me.tax_number ?? "",
          ...(me.address
            ? {
                address: {
                  city: me.address.city,
                  district: me.address.district,
                  neighborhood: me.address.neighborhood ?? "",
                  fullAddress: me.address.fullAddress ?? "",
                  zip: "",
                },
              }
            : {}),
          ...(me.coordinates
            ? {
                coordinates: {
                  lat: me.coordinates.lat,
                  lng: me.coordinates.lng,
                },
              }
            : {}),
          business_type: me.business_type,
          workplace_type: me.workplace_type,
          total_employees: me.total_employees ?? null,
          employees_for_installation: me.employees_for_installation ?? null,
          appointment_phone: me.appointment_phone,
          working_days: me.working_days ?? [],
          total_vehicle_capacity: me.total_vehicle_capacity ?? null,
          vehicles_for_installation: me.vehicles_for_installation ?? null,
          has_internet_service: me.has_internet_service ? YesNo[0] : YesNo[1],
          vehicle_types_for_service: me.vehicle_types_for_service ?? [],
          working_hours: me.working_hours ?? [],
          ...((me.technicians ?? []).length
            ? {
                technicians: me.technicians,
              }
            : {}),
          files: (me.files ?? []).some((f) => !!f)
            ? me.files
            : [null, null, null, null, null, null, null, null],
        },
      };

      // @ts-ignore
      setFormData(f);
      setHitCount(1);
    }
  }, [me, isLoaded, map]);

  const { data: citys } = useSWR("/montage/city", fetcher);

  const styles = {
    title: { fontSize: "20px", lineHeight: "30px", fontWeight: 500 },
    subtitle: { color: "#667085", fontSize: "16px", lineHeight: "24px" },
    row: {
      flexDirection: "row",
      gap: 3,
      ...(isMobile ? { flexDirection: "column" } : {}),
      ...(me?.status !== ServiceStatusList.ACCEPTED
        ? {}
        : { userSelect: "none", pointerEvents: "none" }),
    },
    formControl: {
      flex: 1,
      ...(isMobile
        ? { width: "100% !important", flex: 1, minWidth: "100%" }
        : {}),
    },
    nativeLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    divider: {},
    vehicle_input: {
      maxWidth: "180px",
      ...(isMobile ? { maxWidth: "initial", width: "100%" } : {}),
    },
  };

  const onChangeForm = (key: string, value: any, extend?: any) => {
    const f: any = { ...formData };
    f[key] = value;

    let ff = { ...f };
    if (extend) {
      ff = { ...f, ...extend };
    }
    setFormData(ff);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (me?.status === ServiceStatusList.ACCEPTED) {
      return;
    }

    setLoading(true);

    try {
      const f = { ...formData };
      // @ts-ignore
      f.has_internet_service = f.has_internet_service === YesNo[0];
      await axiosInstance.put(`/service-provider/${user?.id}`, f);

      enqueueSnackbar("Form başarıyla gönderildi.", { variant: "success" });
      setSended(true);
      onClose();
    } catch (error) {
      enqueueSnackbar("Bekleyen bir hata oluştu.", { variant: "error" });
    }

    setLoading(false);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (me?.status === ServiceStatusList.ACCEPTED) return;
    if (event.latLng) {
      const position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setSelectedPosition(position);
      onChangeForm("coordinates", position);
    }
  };

  const computedDistict =
    formData.address.city && citys ? citys[formData.address.city] : [];

  return (
    <Box>
      <Box sx={{ position: "relative" }}>
        <IconButton
          sx={{ position: "absolute" }}
          onClick={() => {
            onClose();
          }}
        >
          <Iconify icon="ic:baseline-chevron-left" />
        </IconButton>
        {me?.status !== ServiceStatusList.ACCEPTED && (
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: 500,
              textAlign: "center",
              mb: 3,
              ...(isMobile ? {} : { px: 10 }),
            }}
          >
            Yetkili Montaj Noktası Olmak İçin Darphane Tarafından Talep Edilen
            Bilgiler
          </Typography>
        )}
      </Box>
      <Stack
        gap={3}
        sx={{
          p: 3,
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 4,
          backgroundColor: "#fff",
        }}
      >
        <form onSubmit={onSubmit}>
          <Stack gap={3}>
            <Stack gap={1}>
              <Typography sx={styles.title}>Yetkili Bilgileri</Typography>
              <Typography sx={styles.subtitle}>
                Başvuru Yapan Firma Adına İşlem Yapma Yetkisine Sahip Kullanıcı
                Bilgileri
              </Typography>
            </Stack>
            <Stack direction="row" sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Yetkili Kişi Adı"
                  variant="outlined"
                  value={formData.authorized_person_name}
                  onChange={(e) =>
                    onChangeForm("authorized_person_name", e.target.value)
                  }
                />
              </FormControl>
              <FormControl sx={styles.formControl}>
                <TextField
                  type="email"
                  disabled
                  value={user?.username}
                  label="Yetkili Mail Adresi"
                  required
                />
              </FormControl>
            </Stack>
            <Stack direction="row" sx={styles.row}>
              <FormControl sx={styles.formControl}>
                {/* <TextField
                  required
                  label="Yetkili Kişi Telefon"
                  variant="outlined"
                  value={formData.authorized_person_phone}
                  onChange={(e) =>
                    onChangeForm("authorized_person_phone", e.target.value)
                  }
                /> */}
                <MaskedInput
                  required
                  value={formData.authorized_person_phone}
                  onChange={(e) =>
                    onChangeForm("authorized_person_phone", e.target.value)
                  }
                  guide={false}
                  render={(innerRef, props) => (
                    <TextField
                      {...props}
                      inputRef={innerRef}
                      // placeholder="+90"
                      label="Yetkili Kişi Telefon"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                  mask={[
                    "0",
                    " ",
                    "(",
                    /\d/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                  ]}
                />
              </FormControl>
              <FormControl sx={styles.formControl} />
            </Stack>
            <Stack />
            <Stack>
              <Typography sx={styles.title}>
                Montaj Noktasına Ait Bilgiler
              </Typography>
            </Stack>
            <Stack direction="row" sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Montaj Noktası Tabela İsmi"
                  variant="outlined"
                  value={formData.signage_name}
                  onChange={(e) => onChangeForm("signage_name", e.target.value)}
                />
              </FormControl>

              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Resmi Ünvan"
                  variant="outlined"
                  value={formData.offical_title}
                  onChange={(e) =>
                    onChangeForm("offical_title", e.target.value)
                  }
                />
              </FormControl>
            </Stack>
            <Stack direction="row" sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Vergi Dairesi"
                  variant="outlined"
                  value={formData.tax_office}
                  onChange={(e) => onChangeForm("tax_office", e.target.value)}
                />
              </FormControl>

              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Vergi Numarası"
                  variant="outlined"
                  value={formData.tax_number}
                  onChange={(e) => onChangeForm("tax_number", e.target.value)}
                />
              </FormControl>
            </Stack>
            <Stack sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <InputLabel>İl *</InputLabel>
                <Select
                  label="İl *"
                  value={formData.address.city}
                  onChange={(e) => {
                    onChangeForm("address", {
                      city: e.target.value,
                      district: "",
                      neighborhood: "",
                    });
                  }}
                >
                  {!!citys &&
                    Object.keys(citys).map((key) => (
                      <MenuItem value={key} key={key}>
                        {key}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl sx={styles.formControl}>
                <InputLabel>İlçe *</InputLabel>
                <Select
                  value={formData.address.district}
                  onChange={(e) => {
                    const address = formData.address;
                    onChangeForm("address", {
                      ...address,
                      district: e.target.value,
                    });
                  }}
                  label="İlçe *"
                  required
                  disabled={!formData.address.city}
                >
                  {computedDistict.map((row: any, index: number) => (
                    <MenuItem key={index} value={row.district}>
                      {`${row.district}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Mahalle"
                  variant="outlined"
                  value={formData.address.neighborhood}
                  onChange={(e) => {
                    const address = formData.address;
                    onChangeForm("address", {
                      ...address,
                      neighborhood: e.target.value,
                    });
                  }}
                />
              </FormControl>
            </Stack>
            {isLoaded && (
              <Box sx={{ overflow: "hidden", borderRadius: 2 }}>
                <GoogleMap
                  zoom={14}
                  mapContainerStyle={{ height: 500 }}
                  onLoad={onLoad}
                  onUnmount={onUnmount}
                  onClick={handleMapClick}
                >
                  {selectedPosition && <Marker position={selectedPosition} />}
                </GoogleMap>
              </Box>
            )}
            <Stack>
              <Typography sx={styles.title}>İşletmeye Ait Bilgiler</Typography>
            </Stack>
            <Stack sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <InputLabel>İşletme Tipi</InputLabel>
                <Select
                  label="İşletme Tipi"
                  value={formData.business_type}
                  onChange={(e) => {
                    onChangeForm("business_type", e.target.value);
                  }}
                >
                  {ServiceBusinessTypes.map((val, index) => (
                    <MenuItem value={val} key={index}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={styles.formControl}>
                <InputLabel>İşyeri Tipi</InputLabel>
                <Select
                  label="İşyeri Tipi"
                  value={formData.workplace_type}
                  onChange={(e) => {
                    onChangeForm("workplace_type", e.target.value);
                  }}
                >
                  {ServiceWorkspaceTypes.map((val, index) => (
                    <MenuItem value={val} key={index}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Toplam Çalışan Sayısı"
                  variant="outlined"
                  value={formData.total_employees}
                  onChange={(e) => {
                    onChangeForm("total_employees", e.target.value);
                  }}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </FormControl>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Montaj Hizmeti Verebilecek Çalışan Sayısı"
                  variant="outlined"
                  value={formData.employees_for_installation}
                  onChange={(e) => {
                    onChangeForm("employees_for_installation", e.target.value);
                  }}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </FormControl>
            </Stack>
            <Stack sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <MaskedInput
                  required
                  value={formData.appointment_phone}
                  onChange={(e) => {
                    onChangeForm("appointment_phone", e.target.value);
                  }}
                  guide={false}
                  render={(innerRef, props) => (
                    <TextField
                      {...props}
                      inputRef={innerRef}
                      // placeholder="+90"
                      label="Randevu Telefonu"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                  mask={[
                    "0",
                    " ",
                    "(",
                    /\d/,
                    /\d/,
                    /\d/,
                    ")",
                    " ",
                    /\d/,
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                    " ",
                    /\d/,
                    /\d/,
                  ]}
                />
              </FormControl>
              <FormControl sx={styles.formControl} />
            </Stack>
            <Stack sx={styles.row}>
              <Stack gap={1}>
                <Typography sx={styles.subtitle}>Mesai Günleri</Typography>
                <Stack sx={styles.row} gap={1} flexWrap="wrap">
                  {Days.map((day, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => {
                            const v = [...formData.working_days];
                            if (e.target.checked) {
                              // @ts-ignore
                              v.push(day);
                            } else {
                              // @ts-ignore
                              v.splice(v.indexOf(day), 1);
                            }
                            onChangeForm("working_days", v);
                          }}
                          checked={formData.working_days.includes(
                            // @ts-ignore
                            day
                          )}
                        />
                      }
                      // @ts-ignore
                      label={DayNames[day]}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>

            <Stack sx={styles.row}>
              <Stack gap={1}>
                <Typography sx={styles.subtitle}>Mesai Saatleri</Typography>
                <Alert
                  severity="info"
                  action={
                    <Button
                      sx={{ whiteSpace: "nowrap" }}
                      size="small"
                      onClick={() => {
                        const v = [...ServiceActiveTimes];
                        onChangeForm("working_hours", v);
                      }}
                    >
                      Tümünü Seç
                    </Button>
                  }
                >
                  Sadece seçili saatler içerisinde montaj başvuru
                  bulunabilecektir.
                  <br />
                  Örn: <b>12:00</b> seçili olması <b>12:00-12:15-12:30-12:45</b>{" "}
                  için randevu alınabilmesini sağlar.
                </Alert>
                <Stack sx={{ ...styles.row, gap: 1 }} flexWrap="wrap">
                  {ServiceActiveTimes.map((time, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => {
                            const v = [...formData.working_hours];
                            if (e.target.checked) {
                              // @ts-ignore
                              v.push(time);
                            } else {
                              // @ts-ignore
                              v.splice(v.indexOf(time), 1);
                            }
                            onChangeForm("working_hours", v);
                          }}
                          checked={formData.working_hours.includes(
                            // @ts-ignore
                            time
                          )}
                        />
                      }
                      // @ts-ignore
                      label={time}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>

            <Stack sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Toplam Araç Kapasitesi"
                  variant="outlined"
                  value={formData.total_vehicle_capacity}
                  onChange={(e) => {
                    onChangeForm("total_vehicle_capacity", e.target.value);
                  }}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </FormControl>
              <FormControl sx={styles.formControl}>
                <TextField
                  required
                  label="Aynı Anda Montaj Hizmeti Verilebilecek Taşıt Sayısı"
                  variant="outlined"
                  value={formData.vehicles_for_installation}
                  onChange={(e) => {
                    onChangeForm("vehicles_for_installation", e.target.value);
                  }}
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </FormControl>
            </Stack>
            <Stack sx={styles.row}>
              <FormControl sx={styles.formControl}>
                <InputLabel>
                  İnternet Hizmeti/ Montaj yapılacak alanda internet hizmeti
                  mevcut mu?
                </InputLabel>
                <Select
                  label="İnternet Hizmeti/ Montaj yapılacak alanda internet hizmeti mevcut mu?"
                  value={formData.has_internet_service}
                  onChange={(e) => {
                    onChangeForm("has_internet_service", e.target.value);
                  }}
                >
                  {YesNo.map((val, index) => (
                    <MenuItem value={val} key={index}>
                      {val}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack sx={styles.row}>
              <Stack gap={1}>
                <Typography sx={styles.subtitle}>
                  Hizmet Verilebilecek Taşıt Türü
                </Typography>
                <Stack sx={styles.row} gap={1}>
                  {ServiceVehicleTypes.map((vehicle, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => {
                            const v = [...formData.vehicle_types_for_service];
                            if (e.target.checked) {
                              // @ts-ignore
                              v.push(vehicle);
                            } else {
                              // @ts-ignore
                              v.splice(v.indexOf(vehicle), 1);
                            }
                            onChangeForm("vehicle_types_for_service", v);
                          }}
                          checked={formData.vehicle_types_for_service.includes(
                            // @ts-ignore
                            vehicle
                          )}
                        />
                      }
                      label={vehicle}
                    />
                  ))}
                </Stack>
              </Stack>
            </Stack>
            <Stack>
              <Typography sx={styles.title}>Teknisyen Bilgileri</Typography>
            </Stack>
            {!!formData.technicians.length && (
              <Stack sx={styles.row} flexWrap="wrap">
                {formData.technicians.map(
                  (
                    t: { name: string; email: string; phone: string },
                    index
                  ) => {
                    return (
                      <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        key={index}
                        sx={{
                          fontFamily: "inherit",
                          fontSize: "16px",
                        }}
                      >
                        <span>{`${t.name} - ${t.email} - ${t.phone}`}</span>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            const f = { ...formData };
                            f.technicians.splice(index, 1);
                            setFormData(f);
                          }}
                        >
                          <Iconify icon="mdi:user-remove" />
                        </IconButton>
                      </Stack>
                    );
                  }
                )}
              </Stack>
            )}
            {formData.technicians.length < 2 && (
              <Stack sx={styles.row}>
                <FormControl sx={styles.formControl}>
                  <TextField
                    label="Teknisyen Adı Soyadı"
                    variant="outlined"
                    value={techForm.name}
                    onChange={(e) =>
                      setTechForm((f) => ({ ...f, name: e.target.value }))
                    }
                  />
                </FormControl>
                <FormControl sx={styles.formControl}>
                  <TextField
                    label="Teknisyen E-mail"
                    variant="outlined"
                    value={techForm.email}
                    onChange={(e) =>
                      setTechForm((f) => ({ ...f, email: e.target.value }))
                    }
                  />
                </FormControl>
                <FormControl sx={styles.formControl}>
                  {/* <TextField
                    label="Teknisyen Telefon Numarası"
                    variant="outlined"
                    value={techForm.phone}
                    onChange={(e) =>
                      setTechForm((f) => ({ ...f, phone: e.target.value }))
                    }
                  /> */}

                  <MaskedInput
                    required
                    value={techForm.phone}
                    onChange={(e) =>
                      setTechForm((f) => ({ ...f, phone: e.target.value }))
                    }
                    guide={false}
                    render={(innerRef, props) => (
                      <TextField
                        {...props}
                        inputRef={innerRef}
                        label="Teknisyen Telefon Numarası"
                        // placeholder="+90"
                        slotProps={{
                          inputLabel: {
                            shrink: true,
                          },
                        }}
                      />
                    )}
                    mask={[
                      "0",
                      " ",
                      "(",
                      /\d/,
                      /\d/,
                      /\d/,
                      ")",
                      " ",
                      /\d/,
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                      " ",
                      /\d/,
                      /\d/,
                    ]}
                  />
                </FormControl>
                <Button
                  disabled={
                    !techForm.email.length ||
                    !techForm.name.length ||
                    !techForm.phone.length
                  }
                  variant="contained"
                  color="secondary"
                  startIcon={<Iconify icon="mdi:user-add" />}
                  onClick={() => {
                    const f = { ...formData };
                    // @ts-ignore
                    f.technicians.push({
                      email: techForm.email,
                      name: techForm.name,
                      phone: techForm.phone,
                    });

                    setTechForm({ name: "", email: "", phone: "" });

                    setFormData(f);
                  }}
                >
                  Teknisyen Ekle
                </Button>
              </Stack>
            )}
            <Stack>
              <Typography sx={styles.title}>Gerekli Evraklar</Typography>
            </Stack>
            <UploadZone
              files={formData.files}
              showInfo={
                isSended ||
                formData.files.filter((f) => f).length === formData.files.length
              }
              disabled={me?.status === ServiceStatusList.ACCEPTED}
              onSave={(files) => {
                const f = { ...formData };
                f.files = files;
                setFormData(f);
              }}
            />
            <Stack sx={styles.row}>
              <Stack>
                <label style={styles.nativeLabel as any}>
                  <Checkbox required />
                  <Typography>
                    Yukardaki şartları sağladığımı, ilgili bilgilerin doğru
                    olduğunu ve başvurumun kabul edilmesi halinde bunu tevsik
                    eden evrakları temin edeceğimi beyan ederim.
                  </Typography>
                </label>
                <label style={styles.nativeLabel as any}>
                  <Checkbox required />
                  <Typography>
                    KVKK yürürlüğündeki{" "}
                    <span
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        window.open("/gizlilik", "_blank");
                      }}
                    >
                      metni
                    </span>{" "}
                    okudum ve onaylıyorum.
                  </Typography>
                </label>
              </Stack>
            </Stack>
            <Stack direction="row" gap={2}>
              {me?.status !== ServiceStatusList.ACCEPTED && (
                <Button
                  type="submit"
                  disabled={
                    // formData.files.some((f) => !f) ||
                    !formData.working_days.length ||
                    formData.technicians.length !== 2
                  }
                  variant="contained"
                  color="secondary"
                >
                  Başvuruyu Tamamla
                </Button>
              )}
              {/* <Button
                onClick={() => {
                  onClose();
                }}
                variant="outlined"
                color="secondary"
              >
                Formu Kapat
              </Button> */}
            </Stack>
          </Stack>
        </form>
      </Stack>
    </Box>
  );
};
