import { IAddress } from "./IServiceProvider";
import { IVehicle } from "./IVehicle";

export interface IMontageApplication {
  company_name: string;
  authorized_person_name: string;
  authorized_person_email: string;
  authorized_person_phone: string;
  address: IAddress;
  reference?: string;
  service_provider?: string;
  mint_code: boolean;
  vehicles: IVehicle[];
  application_date?: Date[];
  type: "mobile" | "service";
  status: IMontageStatus;
  location?: {
    lat: number;
    lng: number;
  };
  completed_vehicle_count?: string;
  is_rejected?: boolean;
}

export enum IMontageStatus {
  "waiting" = "waiting",
  "not_came" = "not_came",
  "came_missing_vehicle" = "came_missing_vehicle",
  "completed" = "completed",
}

export const IMontageStatusLabel = {
  waiting: "Montaj bekleniyor",
  not_came: "Müşteri gelmedi",
  came_missing_vehicle: "Eksik araç ile geldi",
  completed: "Montaj tamamlandı",
};

export enum IMontageApplicationType {
  mobile = "mobile",
  service = "service",
}
