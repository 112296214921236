import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useSWR from "swr";
import { Iconify, ServiceDetailModal } from "../../../components";
import { ServiceStatusList } from "../../../interface";
import { fDateTime, fetcher } from "../../../utils";
import { PATHS } from "../../../routes";
import { Link } from "react-router-dom";

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

export const ServiceAppListSection = () => {
  const [selectedService, setSelectedService] = useState<any>(null);

  const params = new URLSearchParams();
  params.append("status", ServiceStatusList.PENDING_APPROVAL);

  const { data, isLoading } = useSWR<any[]>(
    `/service-provider/query?${params.toString()}`,
    fetcher
  );

  return (
    <Stack gap={2} sx={{ minHeight: 1200 }}>
      <Stack
        alignItems="center"
        justifyContent="end"
        gap={2}
        direction="row"
        sx={{ height: 40 }}
      >
        <Link to={PATHS.admin.servicesProviders.root}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="end"
          >
            <Iconify icon="solar:alt-arrow-left-line-duotone" />
            Geri Dön
          </Stack>
        </Link>
      </Stack>
      <ServiceDetailModal
        isModal
        selectedService={selectedService}
        setSelectedService={setSelectedService}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell>
                <Typography sx={TableLableStyle}>Başvuru Tarihi</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableLableStyle}>İsim</Typography>
              </TableCell>
              {/* <TableCell>
                <Typography sx={TableLableStyle}>Randevu Durumu</Typography>
              </TableCell> */}
              <TableCell>{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && !data?.length && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                    Veri Bulunamadı
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && (
              <>
                {Array(5)
                  .fill("")
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      {/*<TableCell>
                        <Skeleton />
                      </TableCell> */}
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </>
            )}
            {data?.map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell>
                  <Typography sx={{}}>{fDateTime(row.createdAt)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row.authorized_person_name}
                  </Typography>
                </TableCell>
                {/* <TableCell>
                  <Typography sx={TableLableStyle}>
                    <ServiceStatusChanger id={row._id} status={row.status} />
                  </Typography>
                </TableCell> */}
                <TableCell>
                  <Stack
                    gap={1}
                    direction="row"
                    alignItems="center"
                    justifyContent={"end"}
                  >
                    <IconButton color="success">
                      <Iconify icon="ic:baseline-check" />
                    </IconButton>
                    <Box
                      sx={{
                        width: "1px",
                        backgroundColor: "divider",
                        height: "16px",
                      }}
                    />
                    <IconButton
                      onClick={() => {
                        setSelectedService(row);
                      }}
                    >
                      <Iconify icon="ic:baseline-remove-red-eye" />
                    </IconButton>
                  </Stack>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
