import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import useSWR from "swr";
import { IconSuccess } from "../../../components";
import { axiosInstance, fetcher } from "../../../utils";
import { ServiceBusinessTypes } from "../../../data";
import MaskedInput from "react-text-mask";

export interface Address {
  city: string;
  district: string;
  neighborhood?: string;
  zip?: string;
  fullAddress: string;
}

export interface ServiceProvider {
  authorized_person_name: string;
  authorized_person_phone: string;
  authorized_person_email: string;
  offical_title: string;
  business_type: string;
  address: Address;
}

export const ServiceFormSection = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const { data: citys } = useSWR("/montage/city", fetcher);

  const styles = {
    title: { fontSize: "20px", lineHeight: "30px", fontWeight: 500 },
    subtitle: { color: "#667085", fontSize: "16px", lineHeight: "24px" },
    row: {
      flexDirection: "row",
      gap: 3,
      ...(isMobile ? { flexDirection: "column" } : {}),
    },
    formControl: {
      flex: 1,
      ...(isMobile
        ? { width: "100% !important", flex: 1, minWidth: "100%" }
        : {}),
    },
    nativeLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    divider: {},
    vehicle_input: {
      maxWidth: "180px",
      ...(isMobile ? { maxWidth: "initial", width: "100%" } : {}),
    },
  };

  const [formData, setFormData] = useState<ServiceProvider>({
    authorized_person_name: "",
    authorized_person_phone: "",
    authorized_person_email: "",
    offical_title: "",
    business_type: "",
    address: {
      city: "",
      district: "",
      fullAddress: "",
    },
  });

  const onChangeForm = (key: string, value: any, extend?: any) => {
    const f: any = { ...formData };
    f[key] = value;

    let ff = { ...f };
    if (extend) {
      ff = { ...f, ...extend };
    }
    setFormData(ff);
  };

  const onSubmit = async () => {
    setLoading(true);

    try {
      const reference = localStorage.getItem("reference") ?? "";
      const body = { ...formData };
      const { data, status } = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/service-provider`,
        { ...body, reference }
      );

      localStorage.removeItem("reference");
      if (status === 200 || status === 201) {
        setSuccess(true);
        setError("");
      } else {
        const { errors } = data;

        const errorString = errors
          ? errors
              ?.map((err: any) => (err.messages ?? []).join("\n"))
              .join("\n")
          : "Beklenmedik bir hata oluştu.";

        setError(
          "Başvuru gönderilirken beklenmedik bir hata oluştu.<br/>" +
            errorString
        );
      }
    } catch (error: any) {
      const { data } = error.response ?? {};
      const { errors } = data;
      const errorString = errors
        ? errors?.join("\n")
        : "Beklenmedik bir hata oluştu.";

      setError(
        "Başvuru gönderilirken beklenmedik bir hata oluştu.<br/>" + errorString
      );
    }

    setLoading(false);
  };

  const computedDistict =
    formData.address.city && citys ? citys[formData.address.city] : [];

  if (success) {
    return (
      <Stack alignItems="center" gap={3} sx={{ py: 4 }}>
        <IconSuccess />
        <Typography
          sx={{
            fontSize: "32px",
            lineHeight: "40px",
            textAlign: "center",
          }}
        >
          Ön Başvurunuz başarıyla tamamlandı
        </Typography>
        <Typography
          sx={{
            fontSize: "24px",
            lineHeight: "34px",
            textAlign: "center",
          }}
        >
          Başvurunuz ekiplerimiz tarafından değerlendirilecek ve en kısa zamanda
          sizinle iletişime geçilecektir.
        </Typography>
      </Stack>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Stack gap={3}>
        <Stack gap={1}>
          <Typography sx={styles.title}>Yetkili Bilgileri</Typography>
          <Typography sx={styles.subtitle}>
            Başvuru Yapan Firma Adına İşlem Yapma Yetkisine Sahip Kullanıcı
            Bilgileri
          </Typography>
        </Stack>
        <Stack direction="row" sx={styles.row}>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              label="Yetkili Kişi Adı"
              variant="outlined"
              value={formData.authorized_person_name}
              onChange={(e) =>
                onChangeForm("authorized_person_name", e.target.value)
              }
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <TextField
              type="email"
              value={formData.authorized_person_email}
              onChange={(e) => {
                onChangeForm("authorized_person_email", e.target.value);
              }}
              label="Yetkili Mail Adresi"
              required
            />
          </FormControl>
        </Stack>

        <Stack direction="row" sx={styles.row}>
          <FormControl sx={styles.formControl}>
            {/* <TextField
              required
              label="Yetkili Kişi Telefon"
              variant="outlined"
              value={formData.authorized_person_phone}
              onChange={(e) =>
                onChangeForm("authorized_person_phone", e.target.value)
              }
            /> */}
            <MaskedInput
              required
              value={formData.authorized_person_phone}
              onChange={(e) =>
                onChangeForm("authorized_person_phone", e.target.value)
              }
              guide={false}
              render={(innerRef, props) => (
                <TextField
                  {...props}
                  inputRef={innerRef}
                  label="Yetkili Kişi Telefon"
                  // placeholder="+90 "
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
              mask={[
                "0",
                " ",
                "(",
                /\d/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
              ]}
            />
          </FormControl>
          <FormControl sx={styles.formControl} />
        </Stack>
        <Stack />
        <Stack>
          <Typography sx={styles.title}>Firma Bilgileri</Typography>
        </Stack>
        <Stack direction="row" sx={styles.row}>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              label="Firma Ünvanı"
              variant="outlined"
              value={formData.offical_title}
              onChange={(e) => onChangeForm("offical_title", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <InputLabel>Firma Faliyet Alanı</InputLabel>
            <Select
              required
              value={formData.business_type}
              label="Firma Faliyet Alanı"
              onChange={(e) => onChangeForm("business_type", e.target.value)}
            >
              {ServiceBusinessTypes.map((val, index) => (
                <MenuItem value={val} key={index}>
                  {val}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack />
        <Typography sx={styles.title}>Firma Adres Bilgileri</Typography>
        <Stack sx={styles.row}>
          <FormControl sx={styles.formControl}>
            <InputLabel>İl *</InputLabel>
            <Select
              label="İl *"
              value={formData.address.city}
              onChange={(e) => {
                onChangeForm("address", {
                  city: e.target.value,
                  district: "",
                  neighborhood: "",
                });
              }}
            >
              {!!citys &&
                Object.keys(citys).map((key) => (
                  <MenuItem value={key} key={key}>
                    {key}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl sx={styles.formControl}>
            <InputLabel>İlçe *</InputLabel>
            <Select
              value={formData.address.district}
              onChange={(e) => {
                const address = formData.address;
                onChangeForm("address", {
                  ...address,
                  district: e.target.value,
                });
              }}
              label="İlçe *"
              required
              disabled={!formData.address.city}
            >
              {computedDistict.map((row: any, index: number) => (
                <MenuItem key={index} value={row.district}>
                  {`${row.district}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              multiline
              rows={4}
              label="Adres"
              value={formData.address.fullAddress}
              onChange={(e) => {
                const address = formData.address;
                onChangeForm("address", {
                  ...address,
                  fullAddress: e.target.value,
                });
              }}
            />
          </FormControl>
        </Stack>
        <Stack sx={styles.row}>
          <Stack>
            <label style={styles.nativeLabel as any}>
              <Checkbox required />
              <Typography>
                Yukardaki şartları sağladığımı, ilgili bilgilerin doğru olduğunu
                ve başvurumun kabul edilmesi halinde bunu tevsik eden evrakları
                temin edeceğimi beyan ederim.
              </Typography>
            </label>
            <label style={styles.nativeLabel as any}>
              <Checkbox required />
              <Typography>
                KVKK yürürlüğündeki{" "}
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    window.open("/gizlilik", "_blank");
                  }}
                >
                  metni
                </span>{" "}
                okudum ve onaylıyorum.
              </Typography>
            </label>
          </Stack>
        </Stack>
        {error && (
          <Alert severity="error">
            <span dangerouslySetInnerHTML={{ __html: error }}></span>
          </Alert>
        )}
        <Stack>
          <div>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              disabled={loading}
            >
              Başvuruyu Tamamla
            </Button>
          </div>
        </Stack>
      </Stack>
    </form>
  );
};
