import React from "react";
import {
  Box,
  Button,
  Container,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import { Footer, Header } from "../components";
import { Link } from "react-router-dom";
const OldHome = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={2}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          position: "relative",
        }}
      >
        <Box py={{ xs: 3, md: 5 }} px={2}>
          {/*   <Typography
            sx={{
              fontSize: isMobile ? 24 : "calc(100vw*60/1920)",
              fontWeight: "bold",
              color: "white",
              textAlign: "center",
            }}
          >
            Türkiye Cumhuriyeti Resmi UTTS Montaj Partneri
          </Typography> */}
          <Typography
            pt={2}
            sx={{
              fontSize: isMobile ? 24 : "calc(100vw*60/1920)",
              fontWeight: "semibold",
              color: "white",
              textAlign: "center",
            }}
          >
            Kit-Tak ile Kolay UTTS
          </Typography>
        </Box>

        <Box
          sx={{
            paddingX: { xs: 2, md: 5 },
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column", md: "row" },
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
              position: "relative",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <img
                src={require(isMobile
                  ? "../assets/img/homemobile1.png"
                  : "../assets/img/b1.png")}
                width={"100%"}
                style={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/utts-kayit-sureci")}
              />
              {/* Sol alt %40 genişlik ve %25 yükseklik */}
              {!isMobile && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "40%",
                    height: "25%",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0)", // Görünmez alan
                  }}
                  onClick={() => (window.location.href = "https://utts.gov.tr")}
                ></Box>
              )}
              {/* Alt %15 yükseklik ve %100 genişlik */}
              {isMobile && (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "10%",
                    cursor: "pointer",
                    backgroundColor: "rgba(0,0,0,0)", // Görünmez alan
                  }}
                  onClick={() => (window.location.href = "https://utts.gov.tr")}
                ></Box>
              )}
            </Box>
            <Box>
              <img
                src={require("../assets/img/b2.png")}
                width={"100%"}
                style={{ cursor: "pointer" }}
                onClick={() => (window.location.href = "/utts-montaj-basvuru")}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

const Home = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const styles = {
    heading2: {
      fontSize: "32px",
      lineHeight: "42px",
      fontWeight: 500,
      ...(isMobile ? { fontSize: "18px", lineHeight: "28px" } : {}),
    },
    text: {
      fontSize: "18px",
      lineHeight: "28px",
      ...(isMobile ? { fontSize: "14px", lineHeight: "18px" } : {}),
    },
    section_img: {
      maxWidth: "440px",
      ...(isTablet ? { marginLeft: "24px", marginRight: "10px" } : {}),
      ...(isMobile
        ? { maxWidth: "50vw", marginLeft: "0", marginRight: 0 }
        : {}),
    },
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          pb: 4,
        }}
      >
        <Typography
          component={"h1"}
          sx={{
            fontSize: "60px",
            lineHeight: "90px",
            textAlign: "center",
            color: "#fff",
            mb: "60px",
            ...(isMobile
              ? { fontSize: "34px", lineHeight: "46px", mb: "24px" }
              : {}),
          }}
        >
          Kit-Tak ile Kolay UTTS
        </Typography>
        <Stack
          direction={isMobile ? "column-reverse" : "row"}
          sx={{ mx: 4, pb: 4 }}
          gap={4}
        >
          <Box sx={{ flex: 1080 }}>
            <Box
              sx={{
                borderRadius: 4,
                overflow: "hidden",
                background: "#fff",
                pt: "56.25%",
                position: "relative",
              }}
            >
              <video
                poster="/images/poster.jpg"
                style={{
                  width: "100%",
                  height: "100%",
                  border: "none",
                  outline: "none",
                  position: "absolute",
                  inset: 0,
                }}
                src="/video/kamu-spotu.mp4"
                muted
                controls
                loop
                autoPlay
              />
            </Box>
          </Box>

          <Box sx={{ fontSize: 0, flex: 442 }}>
            <Box
              sx={{
                borderRadius: 6,
                overflow: "hidden",
                backgroundColor: "#fff",
                pt: "137.31%",
                position: "relative",
              }}
            >
              <Link to={"/utts-montaj-basvuru"}>
                <img
                  src="/images/hero-right.jpg"
                  alt="Yetkili Satış Montaj Servisi"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    outline: "none",
                    position: "absolute",
                    inset: 0,
                  }}
                />
              </Link>
            </Box>
          </Box>
        </Stack>
        <Stack
          sx={{ backgroundColor: "#fff", mx: 4, borderRadius: 4, p: 3, gap: 6 }}
        >
          <Stack gap={4}>
            <Typography
              component={"h2"}
              sx={{
                ...styles.heading2,
                textAlign: "center",
                maxWidth: "1200px",
                mx: "auto",
              }}
            >
              Ulusal Taşıt Tanıma Sistemi (UTTS)
            </Typography>
            <Typography
              sx={{
                ...styles.text,
                textAlign: "center",
                maxWidth: "1200px",
                mx: "auto",
              }}
            >
              UTTS, akaryakıt piyasasında rekabet eşitliğini sağlamak ve kayıt
              dışı ekonomiyle etkin bir şekilde mücadele etmek amacıyla
              geliştirilmiştir. Sistem, taşıt plaka bilgilerinin akaryakıt
              pompalarına otomatik aktarılmasını sağlayarak şeffaf bir ekosistem
              sunar.
            </Typography>
          </Stack>

          {/* 1-1 */}
          <Stack
            direction={isMobile ? "column-reverse" : "row"}
            sx={{
              maxWidth: "1200px",
              mx: "auto",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Stack gap={2} sx={{ maxWidth: "545px" }}>
              <Typography component={"h3"} sx={{ ...styles.heading2 }}>
                UTTS’ nin Paydaşları Kimlerdir?
              </Typography>
              <Typography sx={{ ...styles.text, textAlign: "justify" }}>
                UTTS, geniş bir paydaş grubunu bir araya getirir:
                <br />
                <br />
                <b>Kamu Paydaşları:</b> Hazine ve Maliye Bakanlığı, Gelir
                İdaresi Başkanlığı, Darphane ve Damga Matbaası Genel Müdürlüğü.
                <br />
                <br />
                <b>Özel Sektör Paydaşları:</b> Akaryakıt dağıtım şirketleri,
                akaryakıt istasyon işletmecileri, yetkili donanım üreticileri ve
                montaj firmaları. Yetkili Taşıt Montaj Firması <b>Kit-Tak</b>{" "}
                olarak, uzman ekibimiz ve güvenilir hizmet anlayışımızla her
                zaman yanınızdayız. Sunduğumuz kaliteli montaj çözümleriyle
                taşıt sahiplerine hızlı ve etkili destek sağlamaktan gurur
                duyuyoruz.
              </Typography>
            </Stack>
            <img
              src="/images/home-01.png"
              alt="UTTS’ nin Paydaşları Kimlerdir?"
              style={styles.section_img}
            />
          </Stack>

          {/* 1-1 */}
          <Stack
            direction={isMobile ? "column" : "row"}
            sx={{
              maxWidth: "1200px",
              mx: "auto",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <img
              src="/images/home-02.png"
              alt="UTTS Kapsamındaki Taşıt Sahipleri"
              style={styles.section_img}
            />
            <Stack gap={2} sx={{ maxWidth: "545px" }}>
              <Typography component={"h3"} sx={{ ...styles.heading2 }}>
                UTTS Kapsamındaki Taşıt Sahipleri
              </Typography>
              <Typography sx={{ ...styles.text, textAlign: "justify" }}>
                <ol>
                  <li>
                    <b>Kaydınızı Tamamlayın.</b> Yakıt harcamalarını gider
                    olarak göstermek isteyen taşıt sahipleri, “Taşıt Sahibi
                    Mükellef İşlemleri” sekmesinden kayıt oluşturur. Bu işlemi
                    yetkili bir kişi de yapabilir.
                  </li>
                  <li>
                    <b>Taşıt Ekleyin.</b> Vergi levhası yükledikten sonra
                    sisteme eklemek istediğiniz aracın bilgilerini girerek taşıt
                    ekleme işlemini tamamlayın.
                  </li>
                  <li>
                    <b>TTB Siparişi Oluşturun.</b> Kaydedilen aracınız için
                    montaj kodunu almak amacıyla siparişinizi tamamlayın.
                  </li>
                  <li>
                    <b>Ödemenizi Yapın.</b> Havale/EFT ya da kredi kartıyla
                    ödemenizi Darphane’ ye gerçekleştirin. Ödemeniz Darphane
                    tarafından onaylandığında, size montaj kodu iletilecektir.
                  </li>
                  <li>
                    <b>Montajı Gerçekleştirin.</b> Yetkili Montaj Noktası’nı
                    seçerek montaj kodunuzu ibraz edin ve montaj işlemini
                    tamamlattırın. Bu işlemler sırasında ek bir ücret talep
                    edilmez. <b>Kit-Tak</b>, montaj ağı ve hızlı hizmet
                    anlayışıyla bu süreçte taşıt sahiplerinin yanındadır.
                  </li>
                </ol>
              </Typography>
            </Stack>
          </Stack>

          {/* 1-1 */}
          <Stack
            direction={isMobile ? "column-reverse" : "row"}
            sx={{
              maxWidth: "1200px",
              mx: "auto",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <Stack gap={2} sx={{ maxWidth: "545px" }}>
              <Typography component={"h3"} sx={{ ...styles.heading2 }}>
                Sistem Nasıl Çalışır?
              </Typography>
              <Typography sx={{ ...styles.text, textAlign: "justify" }}>
                UTTS, Taşıt Tanıma Birimi (TTB) ve diğer istasyon donanımları
                aracılığıyla otomatik ve güvenli bir şekilde yakıt dolumunu ve
                veri iletimini gerçekleştirir.
                <br /> <br />
                <ul>
                  <li>
                    <b>Taşıt Tanıma Birimi (TTB):</b> TTB, aracın yakıt deposuna
                    monte edilen, plaka ve mükellef bilgilerini hafızada tutan,
                    söküldüğünde tekrar kullanılamayan bir cihazdır.
                  </li>
                </ul>
                <br />
                <b>Akaryakıt İstasyonu Donanımları:</b>
                <ul>
                  <li>
                    <b>Taşıt Tanıma Okuyucu (TTO):</b> TTB’den gelen bilgileri
                    okur ve aktarır.
                  </li>
                  <li>
                    <b>Tabanca İletişim Modülü (TİM):</b> TTO’ dan gelen
                    verileri şifreli olarak UTTS veri merkezine ve Gelir İdaresi
                    Başkanlığı veri tabanına iletir.
                  </li>
                </ul>
                <br />
                <b>Yakıt Dolum Süreci:</b>
                <ul>
                  <li>
                    Araç istasyona geldiğinde TTB, TTO ile iletişim kurar ve
                    kimlik bilgilerini iletir.
                  </li>
                  <li>
                    TİM aracılığıyla bilgiler doğrulanır ve Yeni Nesil Ödeme
                    Kaydedici Cihaza (YN Pompa ÖKC) aktarılır.
                  </li>
                  <li>
                    Yakıt dolumu tamamlanır ve UTTS Satış Fiişi oluşturulur.
                  </li>
                  <li>
                    Tüm bilgiler, Gelir İdaresi Başkanlığı veri tabanına eş
                    zamanlı olarak iletilir.
                  </li>
                </ul>
                UTTS sayesinde yakıt dolum işlemleri hızlı, güvenli ve düzenli
                bir şekilde yürütülmektedir.
              </Typography>
            </Stack>
            <img
              src="/images/home-03.png"
              alt="Sistem Nasıl Çalışır?"
              style={styles.section_img}
            />
          </Stack>

          {/* 1-1 */}
          <Stack
            direction={isMobile ? "column" : "row"}
            sx={{
              maxWidth: "1200px",
              mx: "auto",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 4,
            }}
          >
            <img
              src="/images/home-04.png"
              alt="Taşıt Sahibi Başvuru Süreci"
              style={styles.section_img}
            />
            <Stack gap={2} sx={{ maxWidth: "545px" }}>
              <Typography component={"h3"} sx={{ ...styles.heading2 }}>
                Taşıt Sahibi Başvuru Süreci
              </Typography>
              <Typography sx={{ ...styles.text, textAlign: "justify" }}>
                <b>Kayıt ve Hesap Onay:</b>
                <br />
                Taşıt sahibi veya yetkilisi,{" "}
                <a href="utts.gov.tr" target="_blank" rel="noopener noreferrer">
                  utts.gov.tr
                </a>{" "}
                adresindeki "Kayıt Yap - Taşıt Sahibi Mükellef İşlemleri"
                sekmesinden bilgileri doldurur. <br />
                <br />
                <b>Araç Ekleme: </b>
                <br />
                Vergi levhasını yükleyip "Taşıt Ekle" menüsünden araç
                bilgilerini ve ruhsatlarını sisteme girer.
                <br />
                <br />
                <b>TTB Siparişi:</b>
                <br />
                Kaydedilen araçlar için "Siparişlerim" menüsünden TTB montajı
                talebi yapılır. Montajı gerçekleştirecek kişi bilgileri sisteme
                eklenir.
                <br />
                <br />
                <b>Ödeme:</b>
                <br />
                Havale/EFT veya kredi kartı ile Darphane’ ye ödeme yapılır.
                Sipariş numarası açıklamaya yazılmalı. Ödeme onaylandıktan sonra
                montaj kodu iletilir.
                <br />
                <br />
                <b>Montaj:</b>
                <br />
                Taşıt sahibi, uygun montaj noktasını seçer, montaj kodunu
                gösterir ve ücretsiz montaj işlemini yaptırır.
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Footer />
    </Container>
  );
};

export default Home;
