import {
  Alert,
  Box,
  Button,
  ButtonBase,
  Container,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Navigate, useParams } from "react-router-dom";
import {
  CircularProgressModal,
  Footer,
  GreenPaper,
  Header,
  Iconify,
} from "../../components";
import useSWR, { mutate } from "swr";
import { axiosInstance, fetcher } from "../../utils";
import { IReference } from "../../interface";
import { format, parse } from "date-fns";
import { tr } from "date-fns/locale";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import MaskedInput from "react-text-mask";
import { Masks } from "../../data";

const ModalItem = ({
  title,
  value,
  sx,
}: {
  title?: string;
  value?: string | number;
  sx?: any;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack gap={1} sx={{ flex: 1, ...sx }}>
      {title && (
        <Typography
          sx={{
            fontSize: isMobile ? "14px" : "16px",
            lineHeight: "24px",
            color: "grey.700",
          }}
        >
          {title}
        </Typography>
      )}
      {value && (
        <Typography
          sx={{
            fontSize: isMobile ? "16px" : "20px",
            lineHeight: "30px",
            color: "gray.900",
            fontWeight: 500,
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const RefForm = ({
  mutateString,
  onClose,
  reference,
}: {
  mutateString: string;
  onClose: () => void;
  reference: IReference;
}) => {
  const { token } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: citys } = useSWR("/montage/city", fetcher);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const styles = {
    row: {
      flexDirection: "row",
      gap: 3,
      ...(isMobile ? { flexDirection: "column" } : {}),
    },
    formControl: {
      flex: 1,
      ...(isMobile
        ? { width: "100% !important", flex: 1, minWidth: "100%" }
        : {}),
    },
    nativeLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };

  const [form, setForm] = useState({
    name_surname: "",
    email: "",
    phone: "",
    iban: "",
    id_number: "",
    type: "freelance",
    company_name: "",
    tax_number: "",
    tax_office: "",
    company_iban: "",
    address: {
      city: "",
      district: "",
      neighborhood: "",
    },
  } as IReference);

  useEffect(() => {
    console.log(reference);

    const f = {
      email: reference.email,
      id_number: reference.id_number,
      iban: reference.iban,
      _id: reference._id,
      name_surname: reference.name_surname,
      phone: reference.phone,
      tax_number: reference.tax_number,
      tax_office: reference.tax_office,
      company_name: reference.company_name,
      company_iban: reference.company_iban,
      type: reference.type,
      createdAt: reference.createdAt,
      ...(reference.address
        ? {
            address: {
              city: reference.address?.city ?? "",
              district: reference.address?.district ?? "",
              neighborhood: reference.address?.neighborhood ?? "",
            },
          }
        : {}),
    };

    // @ts-ignore
    setForm(f);
  }, [reference]);

  const onChangeForm = (key: string, value: any, extend?: any) => {
    const f: any = { ...form };
    f[key] = value;

    let ff = { ...f };
    if (extend) {
      ff = { ...f, ...extend };
    }
    setForm(ff);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      const filteredForm = Object.keys(form).reduce((acc, key) => {
        const typedKey = key as keyof IReference;

        if (
          form[typedKey] &&
          (typeof form[typedKey] !== "object" ||
            Object.values(form[typedKey] as object).some((val) => val))
        ) {
          // @ts-ignore
          acc[typedKey] = form[typedKey];
        }
        return acc;
      }, {} as IReference);

      await axiosInstance.put("/reference/create/" + token, filteredForm);
      enqueueSnackbar("Profil düzenlendi.", { variant: "success" });
      mutate(mutateString);
      onClose();
    } catch (error: any) {
      let errorString = "Beklenmedik bir hata oluştu.";
      const { data } = error.response ?? {};
      if (data) {
        const { errors } = data;
        if (errors) {
          errorString = errors
            ? errors?.join("<br/>")
            : "Beklenmedik bir hata oluştu.";
          console.log(errors);
        }
      }
      setError(errorString);
    }

    setLoading(false);
  };

  const computedDistict =
    form.address?.city && citys ? citys[form.address.city] : [];

  return (
    <Stack sx={{ px: 3, pb: 3 }}>
      <form onSubmit={onSubmit}>
        <Stack gap={2}>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              label="İsim Soyisim"
              variant="outlined"
              value={form.name_surname}
              onChange={(e) => onChangeForm("name_surname", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              label="Mail Adresi"
              variant="outlined"
              disabled
              value={form.email}
              onChange={(e) => onChangeForm("email", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            {/* <TextField
              label="Telefon Numarası"
              variant="outlined"
              value={form.phone}
              onChange={(e) => onChangeForm("phone", e.target.value)}
            /> */}
            <MaskedInput
              value={form.phone}
              onChange={(e) => onChangeForm("phone", e.target.value)}
              guide={false}
              render={(innerRef, props) => (
                <TextField
                  {...props}
                  inputRef={innerRef}
                  // placeholder="+90 "
                  label="Telefon Numarası"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
              mask={[
                "0",
                " ",
                "(",
                /\d/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
              ]}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <TextField
              label="TC Kimlik Numarası"
              variant="outlined"
              slotProps={{
                htmlInput: {
                  maxLength: 11,
                  minLength: 11,
                },
              }}
              value={form.id_number}
              onChange={(e) => onChangeForm("id_number", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            {/* <TextField
              label="IBAN Numarası"
              variant="outlined"
              value={form.iban}
              onChange={(e) => onChangeForm("iban", e.target.value)}
            /> */}
            <MaskedInput
              value={form.iban}
              onChange={(e) => onChangeForm("iban", e.target.value)}
              guide={false}
              render={(innerRef, props) => (
                <TextField
                  {...props}
                  inputRef={innerRef}
                  placeholder="TR "
                  label="IBAN Numarası"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
              mask={Masks.iban}
            />
          </FormControl>
          <Stack sx={styles.row}>
            <FormControl>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Radio
                  //@ts-ignore
                  checked={form.type === "owner"}
                  onChange={(e) => {
                    onChangeForm(
                      "type",
                      e.target.checked ? "owner" : "freelance"
                    );
                  }}
                />
                <Typography>Şirketim var</Typography>
              </label>
            </FormControl>
            <FormControl>
              <label style={styles.nativeLabel as any}>
                <Radio
                  //@ts-ignore
                  checked={form.type === "freelance"}
                  onChange={(e) => {
                    onChangeForm(
                      "type",
                      e.target.checked ? "freelance" : "owner",
                      {
                        company_name: "",
                        company_iban: "",
                        tax_number: "",
                        tax_office: "",
                        address: { city: "", district: "", neighborhood: "" },
                      }
                    );
                  }}
                />
                <Typography>Şirketim yok</Typography>
              </label>
            </FormControl>
          </Stack>
          {/* @ts-ignore */}
          {form.type === "owner" && (
            <>
              <Stack sx={styles.row}>
                <TextField
                  fullWidth
                  label="Şirket Ünvanı"
                  variant="outlined"
                  value={form.company_name}
                  onChange={(e) => onChangeForm("company_name", e.target.value)}
                />
              </Stack>
              <Stack sx={styles.row}>
                <TextField
                  fullWidth
                  label="Vergi Dairesi"
                  variant="outlined"
                  value={form.tax_office}
                  onChange={(e) => onChangeForm("tax_office", e.target.value)}
                />
              </Stack>
              <Stack sx={styles.row}>
                <TextField
                  fullWidth
                  label="Vergi No"
                  variant="outlined"
                  value={form.tax_number}
                  onChange={(e) => onChangeForm("tax_number", e.target.value)}
                />
              </Stack>
              <Stack sx={{ ...styles.row, gap: 1 }}>
                <FormControl sx={styles.formControl}>
                  <InputLabel>İl</InputLabel>
                  <Select
                    label="İl"
                    value={form.address?.city}
                    onChange={(e) => {
                      onChangeForm("address", {
                        city: e.target.value,
                        district: "",
                        neighborhood: "",
                      });
                    }}
                  >
                    {!!citys &&
                      Object.keys(citys).map((key) => (
                        <MenuItem value={key} key={key}>
                          {key}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={styles.formControl}>
                  <InputLabel>İlçe</InputLabel>
                  <Select
                    value={form.address?.district}
                    onChange={(e) => {
                      const address = form.address;
                      onChangeForm("address", {
                        ...address,
                        district: e.target.value,
                      });
                    }}
                    label="İlçe"
                    disabled={!form.address?.city}
                  >
                    {computedDistict.map((row: any, index: number) => (
                      <MenuItem key={index} value={row.district}>
                        {`${row.district}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={styles.formControl}>
                  <TextField
                    label="Mahalle"
                    variant="outlined"
                    value={form.address?.neighborhood}
                    onChange={(e) => {
                      const address = form.address;
                      onChangeForm("address", {
                        ...address,
                        neighborhood: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack sx={styles.row}>
                {/* <TextField
                  fullWidth
                  label="Şirket IBAN Numarası"
                  variant="outlined"
                  value={form.company_iban}
                  onChange={(e) => onChangeForm("company_iban", e.target.value)}
                /> */}
                <MaskedInput
                  value={form.company_iban}
                  onChange={(e) => onChangeForm("company_iban", e.target.value)}
                  guide={false}
                  render={(innerRef, props) => (
                    <TextField
                      {...props}
                      fullWidth
                      inputRef={innerRef}
                      placeholder="TR "
                      label="Şirket IBAN Numarası"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                  mask={Masks.iban}
                />
              </Stack>
            </>
          )}
          {error && (
            <Alert severity="error">
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          )}
          <Button
            disabled={loading}
            type={"submit"}
            variant="contained"
            color="secondary"
          >
            Kaydet
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

const RefOverview = ({ reference }: { reference: IReference }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const { token } = useParams();
  const [showRef, setShowRef] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const styles = {
    row: {
      flexDirection: isMobile ? "column" : "row",
      gap: 1,
    },
  };

  const handleCopyClick = (ref: string) => {
    const url = `${process.env.REACT_APP_FRONTEND_URL}/?ref=${ref}`;
    navigator.clipboard.writeText(url).then(() => {
      enqueueSnackbar("Link kopyalandı.", { variant: "success" });
    });
  };

  return (
    <Stack gap={2}>
      <Dialog
        open={showEditModal}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setShowEditModal(false);
        }}
      >
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            p={3}
            sx={{ borderBottom: "1px solid", borderBottomColor: "divider" }}
            alignItems="center"
          >
            <Typography
              sx={{ fontSize: isMobile ? "16px" : "24px", fontWeight: 500 }}
            >
              Profil Düzenleme
            </Typography>
            <IconButton
              onClick={() => {
                setShowEditModal(false);
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <RefForm
            reference={reference}
            mutateString={`/reference/token/${token}`}
            onClose={() => {
              setShowEditModal(false);
            }}
          />
        </Stack>
      </Dialog>

      <Dialog
        open={showRef}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setShowRef(false);
        }}
      >
        <Stack gap={3} sx={{ p: 3 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                fontSize: isMobile ? "18px" : "24px",
                lineHeight: "32px",
                fontWeight: 600,
              }}
            >
              Referans Linkiniz
            </Typography>

            <IconButton
              onClick={() => {
                setShowRef(false);
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <Stack gap={3}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              sx={{
                gap: 2,
                p: 3,
                backgroundColor: "secondary.main",
                borderRadius: "12px",
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() => {
                handleCopyClick(reference.ref);
              }}
            >
              <Typography
                sx={{
                  fontSize: isMobile ? "16px" : "18px",
                  lineHeight: "24px",
                  fontWeight: 500,
                  color: "common.white",
                  textAlign: "center",
                  fontFamily: "monospace",
                }}
              >
                {process.env.REACT_APP_FRONTEND_URL}/?ref={reference.ref}
              </Typography>
              <Iconify color="white" icon="ic:round-content-copy" />
            </Stack>
            <Stack
              direction="row"
              gap={2}
              flexWrap="wrap"
              sx={{ borderTop: "1px solid", borderTopColor: "divider", pt: 3 }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleCopyClick(reference.ref);
                }}
                endIcon={<Iconify icon="ic:round-content-copy" />}
              >
                Linki Kopyala
              </Button>
              <Button
                variant="contained"
                color="info"
                onClick={() => {
                  window.location.href = `mailto:?subject=Referans Linkiniz&body=${process.env.REACT_APP_FRONTEND_URL}/?ref=${reference.ref}`;
                }}
                endIcon={<Iconify icon="ic:round-email" />}
              >
                Email Gönder
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  const url = `${process.env.REACT_APP_FRONTEND_URL}/?ref=${reference.ref}`;
                  window.open(
                    `https://wa.me/?text=${encodeURIComponent(url)}`,
                    "_blank"
                  );
                }}
                endIcon={<Iconify icon="ic:round-whatsapp" />}
              >
                WhatsApp'tan Paylaş
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>

      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-start" : "center"}
        justifyContent="space-between"
      >
        <Stack direction="row" gap={1} alignItems="center">
          <Typography
            sx={{ fontSize: isMobile ? "16px" : "24px", lineHeight: "32px" }}
          >
            Referans Bilgileri
          </Typography>
          <IconButton
            onClick={() => {
              setShowEditModal(true);
            }}
          >
            <Iconify icon="ic:round-edit" />
          </IconButton>
        </Stack>

        <Stack direction="row" alignItems="center" gap={1}>
          <Box sx={{ position: "relative" }}>
            <ButtonBase
              onClick={() => {
                setShowRef(true);
              }}
              sx={{
                gap: 1,
                fontFamily: "inherit",
                borderRadius: 4,
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              Referans Linkim
              <Iconify icon="ic:outline-share" />
            </ButtonBase>
          </Box>
        </Stack>
      </Stack>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: 4,
          border: "1px solid",
          borderColor: "divider",
        }}
      >
        <Stack
          sx={{
            "&>*": {
              borderBottom: "1px solid",
              borderBottomColor: "divider",
              p: 3,
            },
            "&>*:last-child": {
              borderBottom: "0px",
            },
          }}
        >
          <Stack sx={styles.row}>
            <ModalItem title="İsim Soyisim" value={reference.name_surname} />
            <ModalItem title="Mail Adresi" value={reference.email} />
            <Box sx={{ flex: 1 }} />
            <Box sx={{ flex: 1 }} />
          </Stack>
          <Stack sx={styles.row}>
            <ModalItem
              title="Telefon Numarası"
              value={reference.phone ?? "-"}
            />
            <ModalItem
              title="TC Kimlik Numarası"
              value={reference.id_number ?? "-"}
            />
            <ModalItem
              title="Referans Sayısı"
              value={
                reference.report?.reduce((total, item) => {
                  return total + item.total;
                }, 0) ?? "-"
              }
            />
            <ModalItem
              title="Onaylanan"
              value={
                reference.report?.reduce((total, item) => {
                  return total + item.confirmed;
                }, 0) ?? "-"
              }
            />
          </Stack>
          <Stack sx={styles.row}>
            <ModalItem
              title="Bayi Referans Sayısı"
              value={reference.services?.length}
            />
          </Stack>
          <Stack sx={styles.row}>
            <ModalItem title="IBAN Numarası" value={reference.iban ?? "-"} />

            {reference.type === "owner" && (
              <ModalItem
                title="Şirket IBAN"
                value={reference.company_iban ?? "-"}
              />
            )}
          </Stack>
          {reference.type === "owner" && (
            <>
              <Stack direction="row" gap={1}>
                <ModalItem
                  title="Şirket Adı"
                  value={reference.company_name ?? "-"}
                />
                <ModalItem
                  title="Vergi Dairesi"
                  value={reference.tax_office ?? "-"}
                />
                <ModalItem
                  title="Vergi No"
                  value={reference.tax_number ?? "-"}
                />
              </Stack>
            </>
          )}
          <Stack sx={styles.row}>
            <ModalItem title="İl" value={reference?.address?.city ?? "-"} />
            <ModalItem
              title="İlçe"
              value={reference?.address?.district ?? "-"}
            />
            <ModalItem
              title="Mahalle"
              value={reference?.address?.neighborhood ?? "-"}
            />
          </Stack>
          {(reference.report ?? []).map((report, index) => (
            <Stack gap={2} key={index}>
              <ModalItem
                title={format(
                  parse(report.date, "MM-yyyy", new Date()),
                  "MMMM yyyy",
                  { locale: tr }
                )}
              />
              <Stack sx={styles.row}>
                <ModalItem title="Referans Sayısı" value={report.total} />
                <ModalItem title="Onaylanan" value={report.confirmed} />
                <Box sx={{ flex: 1 }} />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export const ReferenceDetailPage = () => {
  const { token } = useParams();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { data, isLoading, error } = useSWR<IReference>(
    token ? `/reference/token/${token}` : null,
    fetcher
  );

  if (!token || error) {
    return <Navigate to="/" />;
  }

  return (
    <Container sx={{ my: 2 }}>
      <Header />
      <GreenPaper>
        <Stack gap={2} sx={{ p: isMobile ? 2 : 3 }}>
          {isLoading && <CircularProgressModal />}

          {data && <RefOverview reference={data} />}
        </Stack>
      </GreenPaper>
      <Footer />
    </Container>
  );
};
