import { useEffect, useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../utils";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { PATHS } from "../../routes";

export const RefLink = ({ reference }: { reference: string }) => {
  const [id, setId] = useState("");
  const [mode, setMode] = useState("");

  useEffect(() => {
    if (reference.startsWith("S-")) {
      const ref = reference.replace("S-", "");
      setMode("service");
      setId(ref);
    } else {
      setId(reference);
      setMode("ref");
    }
  }, [reference]);

  const { data, isLoading } = useSWR(
    id && mode === "service" ? `${"/service-provider/profile"}/${id}` : null,
    fetcher
  );

  if (!id) {
    return <span>{"-"}</span>;
  }

  if (isLoading && !data) {
    return <span>Yükleniyor...</span>;
  }

  return (
    <span>
      <Tooltip arrow title="Referansı göster">
        <Link
          to={
            mode === "service"
              ? PATHS.admin.servicesProviders.root +
                "?search=" +
                data.offical_title
              : PATHS.admin.referance.root + "?search=" + id
          }
        >
          {mode === "service" ? data?.offical_title : id}
        </Link>
      </Tooltip>
    </span>
  );
};
