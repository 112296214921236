import useSWR from "swr";
import { fDateTime, fetcher } from "../../../utils";
import {
  Box,
  ButtonBase,
  Chip,
  Dialog,
  IconButton,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useDebouncedValue, useMe } from "../../../hooks";
import {
  IServiceProvider,
  ServiceProviderStatusLabels,
  ServiceStatusList,
} from "../../../interface";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import { PATHS } from "../../../routes";
import { Iconify } from "../../../components";
import { useEffect, useState } from "react";
import { useAuth } from "../../../context";
import { UploadZone } from "../../service";

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

const getChipColor = (status: string): any => {
  switch (status) {
    case ServiceStatusList.ACCEPTED:
      return "success";
    case ServiceStatusList.APPROVED_BY_MINT:
    case ServiceStatusList.FIELD_CONTROL:
      return "warning";
    case ServiceStatusList.REJECTED:
      return "error";
    case ServiceStatusList.PENDING_APPROVAL:
    case ServiceStatusList.ONBOARDING:
      return "info";
    default:
      return "inherit";
  }
};

export const ServiceListSection = () => {
  const [searchParams] = useSearchParams();
  const [debouncedSearch, search, setSearch] = useDebouncedValue("");

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search")!);
    }
  }, [searchParams.get("search")]);

  const params = new URLSearchParams();
  if (debouncedSearch) params.append("search", debouncedSearch);

  const { data, isLoading } = useSWR<IServiceProvider[]>(
    `/service-provider/all?${params.toString()}`,
    fetcher
  );

  const paramsCount = new URLSearchParams();
  paramsCount.append("status", ServiceStatusList.PENDING_APPROVAL);
  const { data: waitingList } = useSWR<any[]>(
    `/service-provider/query?${paramsCount.toString()}`,
    fetcher
  );
  const waitingCount = waitingList?.length ?? -1;

  return (
    <Stack gap={2}>
      <Stack
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        direction="row"
      >
        <TextField
          placeholder="Ara"
          sx={{ maxWidth: 350 }}
          size="small"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
        />
        <Link to={PATHS.admin.servicesProviders.application}>
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            justifyContent="end"
          >
            Bayii Başvuruları
            {waitingCount > 0 ? ` (${waitingCount})` : ""}
            <Iconify icon="solar:alt-arrow-right-line-duotone" />
          </Stack>
        </Link>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell>
                <Typography sx={TableLableStyle}>
                  Bayii Başvuru Tarihi
                </Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableLableStyle}>Bayii</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableLableStyle}>Şehir</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableLableStyle}>Cihaz Sayısı</Typography>
              </TableCell>
              <TableCell>
                <Typography sx={TableLableStyle}>Bayii Durumu</Typography>
              </TableCell>
              <TableCell>{""}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && !data?.length && (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                    Veri Bulunamadı
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {isLoading && !data?.length && (
              <>
                {Array(5)
                  .fill("")
                  .map((row, index) => (
                    <StyledTableRow key={index}>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                      <TableCell>
                        <Skeleton />
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </>
            )}
            {data?.map((row, index) => (
              <StyledTableRow key={index}>
                <TableCell>
                  <Typography sx={{}}>{fDateTime(row.createdAt)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row.offical_title}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row?.address?.city ?? "-"}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    {row?.device_count ?? 0}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Chip
                    size="small"
                    color={getChipColor(row.status)}
                    label={(ServiceProviderStatusLabels as any)[row.status]}
                  />
                </TableCell>
                <TableCell align="right">
                  <Link to={PATHS.admin.servicesProviders.profile.get(row._id)}>
                    <ButtonBase sx={{ fontFamily: "unset", fontSize: 16 }}>
                      Profile Git
                      <Iconify icon="solar:alt-arrow-right-line-duotone" />
                    </ButtonBase>
                  </Link>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
