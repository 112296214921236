import { AdminLayout } from "../../components";
import { ServiceStatusList } from "../../interface";
import {
  ServiceOnboardingSection,
  ServiceOverview,
  ServiceSettinsModal,
} from "../../section";
import { useMe } from "../../hooks";
import { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";

export const ServiceDashboardPage = () => {
  const { me } = useMe();

  const [showForm, setShowForm] = useState(false);

  return (
    <AdminLayout>
      {!showForm && me?.status === ServiceStatusList.ONBOARDING && (
        <>
          <Alert
            color="warning"
            variant="standard"
            action={
              <Button
                size="small"
                variant="contained"
                color="secondary"
                onClick={() => {
                  setShowForm(true);
                }}
              >
                {me.files.filter((f) => f).length
                  ? "Başvuru formunu revize et"
                  : "Başvuruya Devam Et"}
              </Button>
            }
          >
            Yetkili montaj noktası olmak için darphane tarafından talep edilen
            bilgileri doldurmanız gerekmektedir.
          </Alert>
        </>
      )}
      {!showForm && (
        <ServiceOverview
          openForm={() => {
            setShowForm(true);
          }}
        />
      )}
      {showForm && (
        <ServiceOnboardingSection
          onClose={() => {
            setShowForm(false);
          }}
        />
      )}
    </AdminLayout>
  );
};
