export const fCapitalize = (val: string) => {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
};

export const stringToHex = (str: string) => {
  let hexCode = "";
  for (let i = 0; i < str.length; i++) {
    hexCode += str.charCodeAt(i).toString(16).padStart(2, "0");
  }
  return "#" + hexCode.substring(0, 6);
};

export const generateColorsFromString = (str: string) => {
  let hexCode = 0;

  for (let i = 0; i < str.length; i++) {
    hexCode += str.charCodeAt(i);
  }

  let color = "#" + (hexCode % 16777215).toString(16).padStart(6, "0");

  const r = parseInt(color.substring(1, 3), 16);
  const g = parseInt(color.substring(3, 5), 16);
  const b = parseInt(color.substring(5, 7), 16);

  const pastelFactor = 0.35;

  const softR = Math.min(255, Math.floor(r + (255 - r) * pastelFactor));
  const softG = Math.min(255, Math.floor(g + (255 - g) * pastelFactor));
  const softB = Math.min(255, Math.floor(b + (255 - b) * pastelFactor));

  const softColor = `#${softR.toString(16).padStart(2, "0")}${softG
    .toString(16)
    .padStart(2, "0")}${softB.toString(16).padStart(2, "0")}dd`;

  let a = [softR, softG, softB].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  const luminance = a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;

  const textColor = luminance < 0.5 ? "white" : "black";

  return {
    backgroundColor: softColor,
    color: textColor,
  };
};
