import React from "react";
import { Container, Stack } from "@mui/material";
import { Header } from "../Header/Header";
import { GreenPaper } from "../common";
import { AdminNav } from "./AdminNav";
import { Footer } from "../Footer/Footer";
import { ProtectedRoute } from "../../hooks";
import { ServiceNav } from "./ServiceNav";

export const AdminLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <ProtectedRoute>
      <Container sx={{ my: 2 }}>
        <Header />
        <GreenPaper sx={{ p: 3, position: "relative", overflow: "hidden" }}>
          <Stack gap={2}>
            <AdminNav />
            <ServiceNav />
            {children}
          </Stack>
        </GreenPaper>
        <Footer />
      </Container>
    </ProtectedRoute>
  );
};
