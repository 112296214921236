import useSWR from "swr";
import { AdminLayout } from "../../../components";
import { ReservationDetailSection } from "../../../section";
import { IServiceProviderStatus, ServiceStatusList } from "../../../interface";
import { fetcher } from "../../../utils";
import { Navigate } from "react-router-dom";
import { PATHS } from "../../../routes";
import { useAuth } from "../../../context";

export const ReservationDetailPage = () => {
  const { user } = useAuth();
  const { data: response } = useSWR<{
    data: { status: IServiceProviderStatus };
  }>(user ? `/api/auth/me` : null, fetcher);

  const { data } = response ?? {};
  const { status } = data ?? {};

  if (
    status !== ServiceStatusList.ACCEPTED &&
    user?.role === "service_provider"
  ) {
    return <Navigate to={PATHS.admin.root} />;
  }

  return (
    <AdminLayout>
      <ReservationDetailSection />
    </AdminLayout>
  );
};
