import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  timeout: 40000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json; charset=utf-8",
  },
});

export const setAuthToken = (token: string | null) => {
  if (token) {
    instance.interceptors.request.use((config) => {
      config.headers.authorization = `Bearer ${token}`;
      return config;
    });
  } else {
    instance.interceptors.request.use((config) => {
      delete config.headers.authorization;
      return config;
    });
  }
};

export const fetcher = (url: string) => {
  return instance.get(url).then((res) => {
    if (!res.data) {
      throw Error(res.data.message);
    }

    return res.data;
  });
};

export { instance as axiosInstance };
