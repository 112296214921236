import {
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Iconify } from "../../../components";
import { useEffect, useState } from "react";
import { DayNames, Days, ServiceActiveTimes } from "../../../data";
import { useMe } from "../../../hooks";
import { axiosInstance } from "../../../utils";
import { enqueueSnackbar } from "notistack";
import { mutate } from "swr";
import { useAuth } from "../../../context";

export const ServiceSettinsModal = ({ forceMe }: { forceMe?: any }) => {
  const { me } = useMe();
  const { user } = useAuth();
  const [show, setShow] = useState(false);
  const [hitCount, setHitCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    working_hours: [],
    working_days: [],
  });

  const onChangeForm = (key: string, value: any, extend?: any) => {
    const f: any = { ...formData };
    f[key] = value;

    let ff = { ...f };
    if (extend) {
      ff = { ...f, ...extend };
    }
    setFormData(ff);
  };

  const onSave = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const id = forceMe ? forceMe._id : user?.id;
      await axiosInstance.put(`/service-provider/${id}`, {
        working_hours: formData.working_hours ?? [],
        working_days: formData.working_days ?? [],
      });
      enqueueSnackbar("Güncelleme başarılı.", { variant: "success" });
      if (forceMe) {
        mutate(`/service-provider/profile/${forceMe._id}`);
      } else {
        mutate("/api/auth/me");
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Bir hata oluştu.", { variant: "error" });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (forceMe && hitCount === 0) {
      onChangeForm("working_hours", forceMe.working_hours ?? [], {
        working_days: forceMe.working_days ?? [],
      });
      setHitCount(1);
    } else if (hitCount === 0 && me) {
      onChangeForm("working_hours", me.working_hours ?? [], {
        working_days: me.working_days ?? [],
      });
      setHitCount(1);
    }
  }, [me, forceMe]);

  return (
    <>
      <IconButton
        onClick={() => {
          setShow(true);
        }}
      >
        <Iconify icon="ic:round-settings" />
      </IconButton>
      <Dialog
        open={show}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setShow(false);
        }}
      >
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{
              borderBottom: "1px solid",
              borderBottomColor: "divider",
              p: 2,
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: "18px", lineHeight: "24px", fontWeight: 500 }}
            >
              Ayarlar
            </Typography>
            <IconButton
              onClick={() => {
                setShow(false);
              }}
            >
              <Iconify icon="ic:round-close" />
            </IconButton>
          </Stack>
          <Stack gap={3} sx={{ p: 2 }}>
            <Stack gap={2}>
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "18px",
                  color: "grey.700",
                  fontWeight: 500,
                }}
              >
                Çalıştırma Saati
              </Typography>
              <Stack direction="row" flexWrap="wrap">
                {ServiceActiveTimes.map((time, index) => (
                  <Box sx={{ width: "25%" }} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => {
                            const v = [...formData.working_hours];
                            if (e.target.checked) {
                              // @ts-ignore
                              v.push(time);
                            } else {
                              // @ts-ignore
                              v.splice(v.indexOf(time), 1);
                            }
                            onChangeForm("working_hours", v);
                          }}
                          checked={formData.working_hours.includes(
                            // @ts-ignore
                            time
                          )}
                        />
                      }
                      // @ts-ignore
                      label={time}
                    />
                  </Box>
                ))}
              </Stack>
              <Stack gap={2}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "18px",
                    color: "grey.700",
                    fontWeight: 500,
                  }}
                >
                  Çalıştırma Günleri
                </Typography>
                <Stack direction="row" gap={1} flexWrap="wrap">
                  {Days.map((day, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          size="small"
                          onChange={(e) => {
                            const v = [...formData.working_days];
                            if (e.target.checked) {
                              // @ts-ignore
                              v.push(day);
                            } else {
                              // @ts-ignore
                              v.splice(v.indexOf(day), 1);
                            }
                            onChangeForm("working_days", v);
                          }}
                          checked={formData.working_days.includes(
                            // @ts-ignore
                            day
                          )}
                        />
                      }
                      // @ts-ignore
                      label={DayNames[day]}
                    />
                  ))}
                </Stack>
              </Stack>
              <Stack justifyContent="end" alignItems="end">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {
                    onSave();
                  }}
                >
                  Kaydet
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};
