import React, { forwardRef, useImperativeHandle, useRef } from "react";
import MaskedInput, { MaskedInputProps } from "react-text-mask";

// Extending MaskedInputProps for any additional props
interface PhoneInputProps extends MaskedInputProps {}

const PhoneInput = forwardRef<MaskedInput, PhoneInputProps>((props, ref) => {
  const maskedInputRef = useRef<MaskedInput>(null!);

  useImperativeHandle(ref, () => maskedInputRef.current, []);

  return (
    <MaskedInput
      {...props}
      mask={[
        "+",
        "9",
        "0",
        " ",
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
        " ",
        /\d/,
        /\d/,
      ]}
      placeholder="+90 "
      ref={maskedInputRef}
    />
  );
});

export { PhoneInput };
