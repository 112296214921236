import {
  Box,
  Divider,
  Grid,
  Typography,
  Button,
  Pagination,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Footer, Header } from "../components";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const Mevzuat = () => {
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [width, setWidth] = useState<number>(600);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setPageNumber(1); // Yeni bir dosya yüklendiğinde sayfayı birinci sayfaya sıfırlar
  };

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
  };

  useEffect(() => {
    const handleResize = () => {
      const containerWidth = window.innerWidth * (isSmallScreen ? 0.9 : 0.7); // Mobilde %90, diğerlerinde %70
      setWidth(containerWidth > 800 ? 800 : containerWidth);
    };

    handleResize(); // İlk yüklemede çağır
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [isSmallScreen]);

  return (
    <Box mx={{ xs: 1, sm: 2, md: 4 }} my={2}>
      <Header />
      <Box
        py={2}
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: { xs: "16px", sm: "24px" },
          borderBottomRightRadius: { xs: "16px", sm: "24px" },
        }}
      ></Box>

      <Grid container spacing={2} my={3}>
        {/* Sidebar - Politikalar Menüsü */}
        <Grid item xs={12} sm={3} md={2}>
          <Box
            sx={{
              backgroundColor: "#E6F3F0",
              borderRadius: "8px",
              p: { xs: 2, sm: 3 },
              mb: { xs: 2, sm: 0 },
            }}
          >
            <Typography variant="h6" gutterBottom>
              Politikalar
            </Typography>
            <Divider
              sx={{
                backgroundColor: "#002B32",
                opacity: "0.3",
                height: "2px",
                my: 2,
              }}
            />
            <Box
              onClick={() => {
                window.location.href = "/hakkimizda";
              }}
              sx={{
                borderRadius: "8px",
                py: 1,
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                pl={1}
                variant="body1"
              >
                Hakkımızda
              </Typography>
            </Box>
            <Box
              onClick={() => {
                window.location.href = "/mevzuat";
              }}
              sx={{
                backgroundColor: "rgba(0, 0, 0, 0.12)",
                borderRadius: "8px",
                py: 1,
                my: 1,
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                pl={1}
                variant="body1"
              >
                Mevzuat
              </Typography>
            </Box>
            <Box
              onClick={() => {
                window.location.href = "/yetki-belgesi";
              }}
              sx={{
                borderRadius: "8px",
                py: 1,
                my: 1,
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                pl={1}
                variant="body1"
              >
                Yetki Belgesi
              </Typography>
            </Box>
            <Box
              onClick={() => {
                window.location.href = "/gizlilik";
              }}
              sx={{
                borderRadius: "8px",
                py: 1,
              }}
            >
              <Typography
                sx={{
                  cursor: "pointer",
                }}
                pl={1}
                variant="body1"
              >
                Gizlilik
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Ana İçerik - PDF Görüntüleyici */}
        <Grid item xs={12} sm={9} md={10}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Document
              file={`${process.env.REACT_APP_BACKEND_URL}/api/uploads/mevzuat.pdf`}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<Typography>PDF Yükleniyor...</Typography>}
              error={<Typography>PDF Yüklenirken Hata Oluştu.</Typography>}
            >
              <Page
                pageNumber={pageNumber}
                width={width} // Responsive genişlik
                loading={<Typography>Sayfa Yükleniyor...</Typography>}
              />
            </Document>

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                marginTop: 2,
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={pageNumber <= 1}
                onClick={() => setPageNumber((prev) => prev - 1)}
                sx={{
                  backgroundColor: "#002B32",
                  mr: { xs: 0, sm: 2 },
                  mb: { xs: 1, sm: 0 },
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Önceki
              </Button>
              <Pagination
                count={numPages}
                page={pageNumber}
                onChange={handleChangePage}
                color="primary"
                siblingCount={isSmallScreen ? 0 : 1}
                boundaryCount={isSmallScreen ? 1 : 1}
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": {
                    fontSize: { xs: "0.8rem", sm: "1rem" },
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#002B32",
                    color: "#fff", // Metin rengini beyaz yaparak okunabilirliği artırır
                    "&:hover": {
                      backgroundColor: "#002B32", // Hover durumunda da aynı renk
                    },
                  },
                  mx: { xs: 0, sm: 2 },
                  mb: { xs: 1, sm: 0 },
                }}
              />
              <Button
                variant="contained"
                color="primary"
                disabled={pageNumber >= numPages}
                onClick={() => setPageNumber((prev) => prev + 1)}
                sx={{
                  backgroundColor: "#002B32",
                  ml: { xs: 0, sm: 2 },
                  width: { xs: "100%", sm: "auto" },
                }}
              >
                Sonraki
              </Button>
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ mt: 1, textAlign: "center" }}
            >
              Sayfa {pageNumber} / {numPages}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BACKEND_URL}/uploads/mevzuat.pdf`,
                  "_blank"
                );
              }}
              sx={{
                backgroundColor: "#002B32",
                color: "white",
                borderRadius: 20,
                px: 3,
                py: 1.5,
                fontSize: 16,
                fontWeight: "medium",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#e0b33d",
                },
              }}
            >
              Mevzuat PDF Görüntüle
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </Box>
  );
};

export default Mevzuat;
