import { Box } from "@mui/material";
import { AdminLayout } from "../../components";
import { MontageListSection } from "../../section";
import { useAuth } from "../../context";
import { Navigate } from "react-router-dom";
import { PATHS } from "../../routes";

export const Dashboard = () => {
  return (
    <AdminLayout>
      <MontageListSection />
    </AdminLayout>
  );
};
