import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Footer, GreenPaper, Header } from "../../components";
import { FormEvent, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../utils";
import { enqueueSnackbar } from "notistack";
import { PATHS } from "../../routes";

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const theme = useTheme();

  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordA, setShowPasswordA] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    password: "",
    passwordAgain: "",
  });

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    try {
      await axiosInstance.put("/api/auth/forgot-password", {
        password: form.password,
        token,
      });
      enqueueSnackbar("Şifreniz başarıyla güncellenmiştir.", {
        variant: "success",
      });
      navigate(PATHS.admin.login);
    } catch (e: any) {
      let errorString = "Beklenmedik bir hata oluştu.";
      const { data } = e.response;
      if (data) {
        const { errors } = data;
        if (errors) {
          errorString = e
            ? errors?.join("<br/>")
            : "Beklenmedik bir hata oluştu.";
          console.log(errors);
        }
      }
      setError(errorString);
    }
    setLoading(false);
  };

  if (!token) {
    return <Navigate to="/" />;
  }

  return (
    <Container sx={{ my: 4 }}>
      <Header />
      <GreenPaper>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: 4,
            py: 16,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: 542,
              flex: 1,
              borderRadius: 4,
              p: 3,
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <form onSubmit={onSubmit}>
              <Stack gap={4}>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 500,
                  }}
                >
                  Şifremi Yenile
                </Typography>
                <Stack gap={2}>
                  <TextField
                    required
                    type={showPassword ? "text" : "password"}
                    label={"Şifre"}
                    value={form.password}
                    onChange={(e) => {
                      setForm((cur) => ({
                        ...cur,
                        password: e.target.value,
                      }));
                    }}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                      input: {
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPassword((p) => !p);
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      },
                    }}
                  />
                  <TextField
                    required
                    type={showPasswordA ? "text" : "password"}
                    label={"Şifre Tekrarı"}
                    value={form.passwordAgain}
                    onChange={(e) => {
                      setForm((cur) => ({
                        ...cur,
                        passwordAgain: e.target.value,
                      }));
                    }}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                      input: {
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPasswordA((p) => !p);
                            }}
                          >
                            {showPasswordA ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      },
                    }}
                  />
                </Stack>
                {error && <Alert severity="error">{error}</Alert>}
                <Button
                  disabled={
                    form.password.trim().length === 0 ||
                    form.password.trim() !== form.passwordAgain.trim() ||
                    loading
                  }
                  variant={"contained"}
                  color={"secondary"}
                  type="submit"
                >
                  Kaydet
                </Button>
              </Stack>
            </form>
          </Box>
        </Box>
      </GreenPaper>
      <Footer />
    </Container>
  );
};
