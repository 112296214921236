import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { addDays, format, subDays } from "date-fns";
import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { Iconify } from "../../../components";
import { IMontageApplication, IServiceProvider } from "../../../interface";
import {
  fCapitalize,
  fDate,
  fetcher,
  generateColorsFromString,
  stringToHex,
} from "../../../utils";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../../../routes";
import { useMe } from "../../../hooks";
import { useAuth } from "../../../context";

interface IMontageResponse extends IMontageApplication {
  _id: string;
  service: IServiceProvider;
}

export const ReservationAllSection = () => {
  const { user } = useAuth();
  const [selectedService, setSelectedService] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    if (user?.role === "service_provider") {
      setSelectedService(user.id);
    }
  }, [user]);

  const datePicker = useRef<HTMLInputElement>(null);

  const { data: services } = useSWR<IServiceProvider[]>(
    `/service-provider/application`,
    fetcher
  );

  const { data, isLoading } = useSWR<IMontageResponse[]>(
    `/montage/application/all?date=${format(currentDate, "yyyy-MM-dd")}${
      selectedService ? "&service=" + selectedService : ""
    }`,
    fetcher
  );

  const workingHours = [];

  for (let hour = 0; hour <= 23; hour++) {
    for (let minute = 0; minute < 60; minute += 15) {
      const hourString = hour < 10 ? `0${hour}` : `${hour}`;
      const minuteString = minute < 10 ? `0${minute}` : `${minute}`;
      workingHours.push(`${hourString}:${minuteString}`);
    }
  }

  return (
    <Stack gap={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack gap={1}>
          <Typography
            sx={{ fontSize: "32px", lineHeight: "40px", fontWeight: 500 }}
          >
            Randevular
          </Typography>
          <Typography
            sx={{ fontSize: "18px", lineHeight: "26px", color: "gray.700" }}
          >
            Yaklaşan randevularınızı buradan takip edebilirsiniz
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1}>
          <IconButton
            color="secondary"
            onClick={() => {
              const d = subDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
          >
            <Iconify icon="ic:baseline-chevron-left" />
          </IconButton>
          <Button
            onClick={() => {
              const d = subDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
            color="secondary"
          >
            {fDate(subDays(new Date(currentDate), 1))}
          </Button>
          <Tooltip title="Tarih Seçin" arrow>
            <Box
              sx={{
                position: "relative",
                cursor: "pointer",
                borderRadius: 2,
                transition: ".2s",
                border: "1px solid #eee",
                "&:hover": { cursor: "pointer", borderColor: "divider" },
              }}
            >
              <MobileDatePicker
                localeText={{
                  okButtonLabel: "Seç",
                  cancelButtonLabel: "İptal",
                  toolbarTitle: "Tarih Seçin",
                }}
                sx={{
                  "& > .MuiInputBase-root": { pr: 0 },
                  "& input": {
                    textAlign: "center",
                    pl: 0,
                    py: 1,
                    width: "150px",
                  },
                  "& fieldset": { border: "none" },
                }}
                value={currentDate}
                format="dd MMMM yyyy"
                onChange={(e) => {
                  if (!e) return;
                  setCurrentDate(e);
                }}
                slotProps={{
                  textField: {
                    InputProps: {
                      endAdornment: (
                        <Iconify
                          sx={{ transform: "translateX(-8px)" }}
                          icon="ic:round-calendar-month"
                        />
                      ),
                    },
                  },
                }}
              />
            </Box>
          </Tooltip>
          <Button
            onClick={() => {
              const d = addDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
            color="secondary"
          >
            {fDate(addDays(new Date(currentDate), 1))}
          </Button>
          <IconButton
            color="secondary"
            onClick={() => {
              const d = addDays(new Date(currentDate), 1);
              setCurrentDate(d);
            }}
          >
            <Iconify icon="ic:baseline-chevron-right" />
          </IconButton>
        </Stack>
      </Stack>
      {user?.role === "admin" && (
        <Stack>
          <Stack direction="row" gap={1} alignItems="center">
            <FormControl size="small" sx={{ width: 300 }}>
              <InputLabel>Bayii Seç</InputLabel>
              <Select
                value={selectedService}
                onChange={(e) => {
                  setSelectedService(e.target.value);
                }}
                size="small"
                label="Bayii Seç"
              >
                {services?.map((service) => (
                  <MenuItem
                    key={service._id.toString()}
                    value={service._id.toString()}
                  >
                    {service.offical_title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedService && (
              <IconButton
                onClick={() => {
                  setSelectedService("");
                }}
              >
                <Iconify icon="ic:round-clear" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      )}
      {isLoading && !data?.length && (
        <Stack gap={2}>
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
          <Skeleton height={64} />
        </Stack>
      )}
      <Stack
        sx={{ border: "1px solid", borderColor: "divider", borderRadius: 2 }}
      >
        <Stack
          direction="row"
          sx={{ borderBottom: "1px solid", borderBottomColor: "divider" }}
        >
          <Box
            sx={{
              width: 100,
              borderRight: "1px solid",
              borderRightColor: "divider",
              pl: 2,
              py: 1,
            }}
          >
            <Typography sx={{ fontSize: "16px", lineHeight: "28px" }}>
              Saatler
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}></Box>
        </Stack>
        <Stack>
          {!isLoading &&
            workingHours
              // .reverse()
              .map((time) => (
                <Stack
                  direction="row"
                  sx={{
                    borderBottom: "1px solid",
                    borderBottomColor: "divider",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: 100,
                      minWidth: 100,
                      borderRight: "1px solid",
                      borderRightColor: "divider",
                      py: 1,
                      pl: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", lineHeight: "28px" }}>
                      {time}
                    </Typography>
                  </Box>
                  <Box sx={{ p: 1, flex: 1 }}>
                    <HourDetail
                      date={currentDate}
                      time={time}
                      data={data ?? []}
                    />
                  </Box>
                </Stack>
              ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

const HourDetail = ({
  time,
  data,
  date,
}: {
  time: string;
  data: IMontageResponse[];
  date: any;
}) => {
  const selectedDateISO = date.toISOString().split("T")[0];

  const { user } = useAuth();
  const navigate = useNavigate();
  const filteredData = data.filter((row) => {
    const times = row.application_date?.filter((t) => {
      const [datePart, timePart] = t.toString().split("T");
      const currentTime = timePart.split(".")[0];

      return (
        datePart === selectedDateISO && currentTime.substring(0, 5) === time
      );
    });

    if (times?.length) return row;
  });

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(auto-fill,minmax(180px,1fr))`,
        gridTemplateRows: "masonry",
        gap: 0.25,
        width: "100%",
      }}
    >
      {filteredData.map((row, index) => {
        const colors = generateColorsFromString(row.company_name);

        const carCount = row.vehicles.reduce((total, data) => {
          return total + data.quantity;
        }, 0);

        return (
          <Box
            key={index}
            sx={{
              ...colors,
              borderRadius: 2,
              border: "2px solid rgba(0,0,0,.25)",
              p: 1,
              pl: 2,
              px: 3,
              boxShadow: "0 20px 20px rgba(0,0,0,.15)",
              position: "relative",
              overflow: "hidden",
              verticalAlign: "top",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate(PATHS.admin.reservation.detail.get(row._id));
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                top: 0,
                height: "100%",
                width: 10,
                ...colors,
              }}
            />

            {user?.role === "admin" && (
              <Typography
                sx={{
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {row.service.offical_title}
              </Typography>
            )}
            <Typography>{row.company_name}</Typography>
            {/* <Stack direction="row" gap={1}>
              <Iconify icon="mdi:car" />
              <Typography sx={{ fontSize: "14px", lineHeight: "20px" }}>
                {carCount} Taşıt
              </Typography>
            </Stack> */}
            {row.is_rejected && <div>Reddedildi.</div>}
            {row.type === "mobile" && (
              <Tooltip title="Mobil Montaj" arrow>
                <Iconify icon="hugeicons:mobile-navigator-01" />
              </Tooltip>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
