import { Navigate, useNavigate, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { axiosInstance, fetcher } from "../../../utils";
import {
  CircularProgressModal,
  ServiceDetailModal,
  ServiceStatusChanger,
} from "../../../components";
import {
  Box,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { IServiceProvider, ServiceStatusList } from "../../../interface";
import { PATHS } from "../../../routes";
import { ServiceLevelTypes } from "../../../data";

const LabelStyle = (theme: any) => ({
  fontSize: "14px",
  lineHeight: "20px",
  color: theme.palette.grey[700],
});

export const ServiceProfileSection = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { data, isLoading, error } = useSWR<IServiceProvider>(
    `/service-provider/profile/${id}`,
    fetcher
  );

  const onChange = async (key: string, value: number | string) => {
    setLoading(true);

    try {
      await axiosInstance.put(`/service-provider/${id}`, {
        [key]: value,
      });

      mutate(`/service-provider/profile/${id}`);

      enqueueSnackbar("Bayii durumu değiştirildi.", { variant: "success" });
    } catch (error) {}

    setLoading(false);
  };

  if (!id) {
    return null;
  }

  if (isLoading && !data) {
    return <CircularProgressModal />;
  }

  if (data?.status === ServiceStatusList.PENDING_APPROVAL) {
    return <Navigate to={PATHS.admin.servicesProviders.application} />;
  }

  if (data) {
    return (
      <Stack gap={2}>
        <ServiceDetailModal
          isModal={false}
          selectedService={data}
          setSelectedService={() => {}}
        />
        <Box
          sx={{
            p: 3,
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 4,
            background: "#fff",
          }}
        >
          <Stack gap={3}>
            <Stack direction="row" gap={2}>
              <Stack gap={1} sx={{ flex: 1 }}>
                <Typography sx={LabelStyle(theme)}>Bayii Durumu</Typography>
                <ServiceStatusChanger
                  id={id}
                  status={data.status}
                  onChange={() => {
                    mutate(`/service-provider/profile/${id}`);
                  }}
                />
              </Stack>
              <Box sx={{ flex: 1 }} />
              <Box sx={{ flex: 1 }} />
            </Stack>
            <Stack gap={2} direction="row" sx={{ opacity: loading ? 0.5 : 1 }}>
              <Stack gap={1} flex={1}>
                <Typography sx={LabelStyle(theme)}>
                  Bayiye Verilecek Sabit Cihaz Sayısı
                </Typography>
                <Select
                  disabled={loading}
                  size="small"
                  value={data.device_count ?? 0}
                  onChange={(e) => {
                    const val = e.target.value as number;
                    onChange("device_count", val);
                  }}
                >
                  {Array(4)
                    .fill("")
                    .map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <Box sx={{ flex: 1 }} />
              <Box sx={{ flex: 1 }} />
            </Stack>
            <Stack direction="row" gap={2} sx={{ opacity: loading ? 0.5 : 1 }}>
              <Stack gap={1} flex={1}>
                <Typography sx={LabelStyle(theme)}>
                  Bayiye Verilecek Mobil Cihaz Sayısı
                </Typography>
                <Select
                  disabled={loading}
                  size="small"
                  value={data.mobile_device_count ?? 0}
                  onChange={(e) => {
                    const val = e.target.value as number;
                    onChange("mobile_device_count", val);
                  }}
                >
                  {Array(4)
                    .fill("")
                    .map((val, index) => (
                      <MenuItem key={index} value={index}>
                        {index}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <Stack gap={1} flex={1}>
                <Typography sx={LabelStyle(theme)}>Bayiilik Tipi</Typography>
                <Select
                  disabled={loading}
                  value={data.level ?? 0}
                  onChange={(e) => {
                    const val = e.target.value as number;
                    onChange("level", val);
                  }}
                  size="small"
                >
                  {ServiceLevelTypes.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Box sx={{ flex: 1 }} />
            </Stack>
          </Stack>
        </Box>
      </Stack>
    );
  }

  return null;
};
