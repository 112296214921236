import { Box, Stack, Typography } from "@mui/material";
import { useAuth } from "../../context";
import { Link, useLocation } from "react-router-dom";
import { PATHS } from "../../routes";
import useSWR from "swr";
import { fetcher } from "../../utils";
import { IServiceProviderStatus, ServiceStatusList } from "../../interface";
import path from "path";

const NavItem = ({
  title,
  href,
  onClick,
  disabled,
}: {
  title: string;
  href: string;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  const { pathname } = useLocation();

  // const isActive = pathname.startsWith(href);
  const isActive = pathname === href;

  if (disabled) {
    return (
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "48px",
          fontWeight: 500,
          transition: ".2s",
          opacity: 0.5,
        }}
      >
        {title}
      </Typography>
    );
  }

  return (
    <Link to={href} {...(onClick ? { onClick: () => onClick() } : {})}>
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "48px",
          fontWeight: 500,
          transition: ".2s",
          opacity: isActive ? 1 : 0.5,
        }}
      >
        {title}
      </Typography>
    </Link>
  );
};

export const ServiceNav = () => {
  const { user, logout } = useAuth();

  const { data: response } = useSWR<{
    data: { status: IServiceProviderStatus };
  }>(user ? `/api/auth/me` : null, fetcher);

  const { data } = response ?? {};
  const { status } = data ?? {};

  if (user?.role !== "service_provider") return null;

  return (
    <Stack direction="row" gap={2}>
      <NavItem title="Profil" href={PATHS.admin.service.root} />
      <NavItem
        disabled={status !== ServiceStatusList.ACCEPTED}
        title="Randevular"
        href={PATHS.admin.reservation.root}
      />
      <NavItem
        title="Montaj Başvuruları"
        disabled={status !== ServiceStatusList.ACCEPTED}
        href={PATHS.admin.root}
      />
      <Box sx={{ flex: 1 }} />
      <NavItem title="Çıkış" href={"#"} onClick={() => logout()} />
    </Stack>
  );
};
