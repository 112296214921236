import { Box, Stack, Typography } from "@mui/material";
import { useAuth } from "../../context";
import { PATHS } from "../../routes";
import { Link, useLocation } from "react-router-dom";

const NavItem = ({
  title,
  href,
  onClick,
}: {
  title: string;
  href: string;
  onClick?: () => void;
}) => {
  const { pathname } = useLocation();

  const isActive = pathname.startsWith(href);

  return (
    <Link to={href} {...(onClick ? { onClick: () => onClick() } : {})}>
      <Typography
        sx={{
          fontSize: "24px",
          lineHeight: "48px",
          fontWeight: 500,
          transition: ".2s",
          opacity: isActive ? 1 : 0.5,
        }}
      >
        {title}
      </Typography>
    </Link>
  );
};

export const AdminNav = () => {
  const { user, logout } = useAuth();

  if (user?.role !== "admin") return null;

  return (
    <Stack direction="row" gap={2}>
      <NavItem
        title="Montaj Başvuruları"
        href={PATHS.admin.montageApplication.root}
      />
      {/* <NavItem
        title="Bayii Başvuruları"
        href={PATHS.admin.servicesProviders.application}
      /> */}
      <NavItem title="Bayiiler" href={PATHS.admin.servicesProviders.root} />
      <NavItem title="Randevu Sistemi" href={PATHS.admin.reservation.root} />
      <NavItem title="Referans Sistemi" href={PATHS.admin.referance.root} />
      <Box sx={{ flex: 1 }} />
      <NavItem title="Çıkış" href={"#"} onClick={() => logout()} />
    </Stack>
  );
};
