export const ServiceBusinessTypes = [
  "Yetkili Motorlu Taşıt Teknik Servis",
  "Özel Motorlu Taşıt Teknik Servis",
  "Araç Muayene İstasyonu",
  "Egzoz Gazı Emisyon Ölçümü",
  "EPDK Yetkili Bayi Otomasyonu",
  "Diğer Motorlu Taşıt Teknik Servis",
];

export const ServiceVehicleTypes = [
  "Binek Araç",
  "Tır",
  "Minibüs",
  "Otobus-Kamyon",
  "Motorsiklet",
  "Kamyonet",
];

export const ServiceWorkspaceTypes = ["Mülk Sahibi", "Kiralık"];

export const ServiceLevelTypes = ["GOLD", "SILVER", "MONTAJ TEKNİSYENİ"];

export const ServiceActiveTimes = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];
