import { Box, CircularProgress, Dialog } from "@mui/material";

export const CircularProgressModal = () => {
  return (
    <Dialog open={true}>
      <Box sx={{ p: 3, backgroundColor: "white" }}>
        <CircularProgress color="secondary" />
      </Box>
    </Dialog>
  );
};
