import {
  Alert,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Footer, GreenPaper, Header } from "../../components";
import { FormEvent, useState } from "react";
import { useAuth } from "../../context";
import { Navigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { axiosInstance } from "../../utils";
import { enqueueSnackbar } from "notistack";

export const AdminLoginPage = () => {
  const theme = useTheme();

  const { login, error, isLoading, user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [showForgotModal, setShowForgotModal] = useState(false);
  const [email, setEmail] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    login(form.email, form.password);
  };

  const onForgot = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email.trim().length === 0) {
      return;
    }

    setLoading(true);
    try {
      await axiosInstance.post("/api/auth/forgot-password", {
        email,
      });

      enqueueSnackbar("Şifre yenileme bağlantısı emailinize gönderildi", {
        variant: "success",
      });
      setShowForgotModal(false);
      setEmail("");
    } catch (error) {}
    setLoading(false);
  };

  if (user) {
    if (user.role === "service_provider") {
      return <Navigate to="/admin/service" />;
    }
    return <Navigate to={"/admin"} />;
  }

  return (
    <Container sx={{ my: 2 }}>
      <Header />

      <Dialog
        maxWidth="xs"
        fullWidth
        open={showForgotModal}
        onClose={() => setShowForgotModal(false)}
      >
        <form onSubmit={onForgot}>
          <Typography sx={{ p: 3, fontSize: "18px", pb: 0 }}>
            Şifremi Unuttum
          </Typography>
          <DialogContent>
            <TextField
              helperText="Şifrenizi sıfırlamanız için email adresine link göndereceğiz."
              label="E-posta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              autoFocus
              type="email"
              required
            />
          </DialogContent>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="end"
            sx={{ p: 3, pt: 0, gap: 3 }}
          >
            <Button onClick={() => setShowForgotModal(false)}>İptal</Button>
            <Button
              disabled={loading}
              type="submit"
              variant="contained"
              color="secondary"
            >
              Şifremi Yenile
            </Button>
          </Stack>
        </form>
      </Dialog>

      <GreenPaper>
        <Box
          sx={{
            backgroundColor: theme.palette.common.white,
            borderRadius: 4,
            py: 16,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: 542,
              flex: 1,
              borderRadius: 4,
              p: 3,
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <form onSubmit={onSubmit}>
              <Stack gap={4}>
                <Stack gap={1.5}>
                  <Typography
                    sx={{
                      fontSize: "24px",
                      lineHeight: "32px",
                      fontWeight: 500,
                    }}
                  >
                    Bayi Portalı
                  </Typography>
                </Stack>
                <Stack gap={1.5}>
                  <TextField
                    required
                    type="email"
                    label={"Eposta"}
                    value={form.email}
                    onChange={(e) => {
                      setForm((cur) => ({
                        ...cur,
                        email: e.target.value,
                      }));
                    }}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                    }}
                  />
                  <TextField
                    required
                    type={showPassword ? "text" : "password"}
                    label={"Şifre"}
                    value={form.password}
                    onChange={(e) => {
                      setForm((cur) => ({
                        ...cur,
                        password: e.target.value,
                      }));
                    }}
                    slotProps={{
                      inputLabel: {
                        shrink: true,
                      },
                      input: {
                        endAdornment: (
                          <IconButton
                            onClick={() => {
                              setShowPassword((p) => !p);
                            }}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        ),
                      },
                    }}
                  />
                  <Typography
                    onClick={() => {
                      setEmail("");
                      setShowForgotModal(true);
                    }}
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "grey.600",
                      cursor: "pointer",
                      textAlign: "right",
                    }}
                  >
                    Şifremi Unuttum
                  </Typography>
                </Stack>
                <Button
                  disabled={isLoading}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="secondary"
                >
                  Giriş Yap
                </Button>
                {error && <Alert severity="error">{error}</Alert>}
              </Stack>
            </form>
          </Box>
        </Box>
      </GreenPaper>

      <Footer />
    </Container>
  );
};
