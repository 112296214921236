import useSWR from "swr";
import { AdminLayout } from "../../components";
import { useAuth } from "../../context";
import { IServiceProvider } from "../../interface";
import { fetcher } from "../../utils";

export const ServiceMyPage = () => {
  const { user } = useAuth();

  const { data: response } = useSWR<{
    data: IServiceProvider;
  }>(user ? `/api/auth/me` : null, fetcher);

  const { data } = response ?? {};

  return (
    <AdminLayout>
      <pre>{JSON.stringify(data)}</pre>
    </AdminLayout>
  );
};
