import { useEffect, useState } from "react";
import { IMontageStatusLabel } from "../../interface";
import { axiosInstance } from "../../utils";
import { mutate } from "swr";
import { enqueueSnackbar } from "notistack";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useDebouncedValue } from "../../hooks";
import { Iconify } from "../common";

export const MontageStatusChanger = ({
  id,
  status,
  onChange,
  forceKey,
}: {
  forceKey?: string;
  id: string;
  status: string;
  onChange?: () => void;
}) => {
  const list: any = IMontageStatusLabel;

  const [debounced, value, setValue] = useDebouncedValue(status);

  useEffect(() => {
    setValue(status);
  }, [status]);

  const [loading, setLoading] = useState(false);

  const handleOnChange = async (status: string) => {
    setLoading(true);

    try {
      const body: any = {};
      if (forceKey) {
        body[forceKey] = value;
      } else {
        body.status = status;
      }
      await axiosInstance.put(`/montage/application/${id}`, body);

      mutate(`/montage/application/${id}`);
      enqueueSnackbar(
        forceKey ? "Montaj düzenlendi." : "Montaj durumu düzenlendi.",
        { variant: "success" }
      );

      if (onChange) {
        onChange();
      }
    } catch (error) {}

    setLoading(false);
  };

  if (forceKey) {
    return (
      <Stack direction="row" gap={1} alignItems="center">
        <TextField
          sx={{ flex: 1 }}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          size="small"
        />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            handleOnChange(debounced);
          }}
        >
          Kaydet
        </Button>
      </Stack>
    );
  }

  return (
    <>
      <Select
        disabled={loading}
        size="small"
        sx={{ minWidth: 200 }}
        value={status}
        onChange={(e) => {
          handleOnChange(e.target.value);
        }}
      >
        {Object.keys(list as any).map((key) => (
          <MenuItem key={key} value={key}>
            {list[key]}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
