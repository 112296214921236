import { useEffect, useRef, useState } from "react";
import { subDays, addDays, format, setMinutes } from "date-fns";

import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Iconify } from "../../components";
import { fDate, fetcher } from "../../utils";
import useSWR from "swr";
import { DatePicker } from "@mui/x-date-pickers";

export const MontageTimePicker = ({
  service,
  onClose,
  title,
  count,
  onSave,
  showButton,
}: {
  count: number;
  title?: string;
  service?: string;
  onClose: () => void;
  onSave?: (times: { date: string; time: string }[]) => void;
  showButton?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const picker = useRef<any>(null);
  const [show, setShow] = useState(false);
  const [date, setDate] = useState(() => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  });
  const [times, setTimes] = useState<string[]>([]);
  const [showPicker, setShowPicker] = useState(false);

  const { data, isLoading } = useSWR<{ available: boolean; time: string }[]>(
    service
      ? `/service-provider/${service}/available?date=${fDate(
          date,
          "yyy-MM-dd"
        )}`
      : "",
    fetcher,
    { keepPreviousData: false }
  );

  console.log(data);

  const now = new Date().valueOf();

  const styles = {
    dateText: {
      fontSize: isMobile ? "10px" : "16px",
      lineHeight: isMobile ? "14px" : "24px",
      fontWeight: 500,
      px: isMobile ? 1 : 2,
      py: 1,
      borderRadius: "4px",
    },
  };

  const disabledCalender = !service;

  const disabledDates = (data ?? [])
    .filter((row) => !row.available)
    .map((row) => `${format(date, "yyyy.MM.dd")}-${row.time}`);

  if (!service) {
    return null;
  }

  return (
    <>
      {(count <= 15 || showButton) && (
        <div>
          <Button
            disabled={!count}
            onClick={() => {
              setShow(true);
            }}
            variant="contained"
            color="secondary"
          >
            {count ? "Randevu Tarihi Belirle" : "Lütfen Araç Bilgisi Girin"}
          </Button>
        </div>
      )}
      <Dialog
        maxWidth={"md"}
        fullWidth
        open={show}
        onClose={() => {
          setShow(false);
          setTimes([]);
          onClose();
          setDate(new Date());
        }}
        sx={{
          "&> .MuiDialog-container ": { alignItems: "start" },
          ...(isMobile
            ? {
                "& .MuiDialog-paper": {
                  margin: "0px",
                },
              }
            : {}),
        }}
      >
        <Stack sx={{ pt: 3, px: 3 }}>
          <Button
            onClick={() => {
              setShowPicker((d) => !d);
            }}
            sx={{
              backgroundColor: "transparent",
              border: "1px solid",
              borderColor: "error.main",
              color: "#000",
            }}
            variant="contained"
            endIcon={<Iconify icon="ic:round-calendar-month" />}
          >
            Randevu Tarihi Seçiniz
          </Button>
        </Stack>
        <Stack
          sx={{
            p: isMobile ? 1 : 3,
            pb: 0,
            borderBottom: "1px solid",
            borderBottomColor: "divider",
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <IconButton
            onClick={() => {
              const d = subDays(new Date(date), 1);
              setDate(d);
            }}
            disabled={disabledCalender}
          >
            <Iconify icon="ic:baseline-chevron-left" />
          </IconButton>

          <Box
            sx={{
              position: "relative",
              px: isMobile ? 0 : 4,
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
            }}
          >
            <ButtonBase
              sx={styles.dateText}
              onClick={() => {
                const f = addDays(date, -1);
                setDate(f);
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: isMobile ? "10px" : "16px",
                    lineHeight: isMobile ? "14px" : "24px",
                  }}
                >
                  {fDate(addDays(date, -1), "dd MMM yyyy")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: isMobile ? "10px" : "16px",
                    lineHeight: isMobile ? "14px" : "24px",
                  }}
                >
                  {fDate(addDays(date, -1), "EEEE")}
                </Typography>
              </Stack>
            </ButtonBase>
            <span style={{ position: "relative" }}>
              <Stack
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  borderBottom: "4px solid",
                  borderBottomColor: "error.main",
                  px: 2,
                  py: 1,
                  zIndex: 3,
                  transform: "translateY(4px)",
                }}
              >
                <Typography
                  onClick={() => {
                    setShowPicker((d) => !d);
                  }}
                  sx={{
                    color: "#344054",
                    display: "inline-block",
                    fontSize: isMobile ? "10px" : "16px",
                    lineHeight: isMobile ? "14px" : "24px",
                    fontWeight: 600,
                    zIndex: 2,
                    position: "relative",
                  }}
                >
                  {fDate(date, "dd MMM yyyy")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: isMobile ? "10px" : "16px",
                    lineHeight: isMobile ? "14px" : "24px",
                  }}
                >
                  {fDate(date, "EEEE")}
                </Typography>
              </Stack>
              <DatePicker
                ref={picker}
                open={showPicker}
                onClose={() => setShowPicker(false)}
                value={date}
                onChange={(e) => {
                  setDate(e as Date);
                  setShowPicker(false);
                }}
                sx={{
                  position: "absolute",
                  inset: 0,
                  opacity: 0,
                  zIndex: 1,
                  mt: "-32px",
                }}
              />
            </span>
            <ButtonBase
              sx={styles.dateText}
              onClick={() => {
                const f = addDays(date, 1);
                setDate(f);
              }}
            >
              <Stack>
                <Typography
                  sx={{
                    fontSize: isMobile ? "10px" : "16px",
                    lineHeight: isMobile ? "14px" : "24px",
                  }}
                >
                  {fDate(addDays(date, 1), "dd MMM yyyy")}
                </Typography>
                <Typography
                  sx={{
                    fontSize: isMobile ? "10px" : "16px",
                    lineHeight: isMobile ? "14px" : "24px",
                  }}
                >
                  {fDate(addDays(date, 1), "EEEE")}
                </Typography>
              </Stack>
            </ButtonBase>
          </Box>
          <IconButton
            disabled={disabledCalender}
            onClick={() => {
              const d = addDays(new Date(date), 1);
              setDate(d);
            }}
          >
            <Iconify icon="ic:baseline-chevron-right" />
          </IconButton>
        </Stack>
        {isLoading && (
          <Box
            sx={{
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              py: 10,
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {data?.length === 0 && (
          <Typography sx={{ opacity: 0.75, py: 4, textAlign: "center" }}>
            Uygun zaman bulunamadı.
          </Typography>
        )}
        {data && (
          <Stack sx={{ p: 3, gap: 3 }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(4, 1fr)",
                gap: 0.25,
              }}
            >
              {(data ?? []).map((row, index: number) => {
                const val = `${format(date, "yyyy.MM.dd")}-${row.time}`;
                const parts = row.time.split(":");

                const d = new Date();
                d.setHours(parseInt(parts[0]));
                d.setMinutes(parseInt(parts[1]));
                d.setDate(date.getDate());
                d.setFullYear(date.getFullYear());
                d.setMonth(date.getMonth());

                const isPast = d < new Date();

                return (
                  <ButtonBase
                    onClick={() => {
                      const t = [...times];
                      if (t.includes(val)) {
                        t.splice(t.indexOf(val), 1);
                      } else {
                        if (t.length >= count) {
                          return;
                        }
                        t.push(val);
                      }

                      setTimes(t);
                    }}
                    disabled={isPast || disabledDates.includes(val)}
                    sx={{
                      fontFamily: "inherit",
                      color: "gray.400",
                      height: "40px",
                      borderRadius: "4px",
                      overflow: "hidden",
                      transition: ".2s",
                      border: "1px solid",
                      borderColor: "#22AB74aa",
                      "&:hover": {
                        backgroundColor: "#eee",
                      },
                      ...(isPast || disabledDates.includes(val)
                        ? { opacity: 0.5 }
                        : {}),
                      ...(times.includes(val)
                        ? {
                            color: "black",
                            backgroundColor: "#22AB74aa",
                            borderColor: "#22AB74aa",
                            "&:hover": {
                              backgroundColor: "#22AB74dd",
                            },
                          }
                        : {}),
                    }}
                    key={index}
                  >
                    {row.time}
                  </ButtonBase>
                );
              })}
            </Box>
            <Stack
              gap={2}
              justifyContent={"end"}
              direction={isMobile ? "column" : "row"}
              alignItems="center"
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#868080",
                  mr: "auto",
                }}
              >
                1 araç montajı 15 dk sürmektedir. Her araç için bir randevu
                seçmeniz gerekmektedir.
              </Typography>
              <Button
                onClick={() => {
                  setShow(false);
                  setTimes([]);
                  onClose();
                  setDate(new Date());
                }}
                sx={{ ...(isMobile ? { width: "100%" } : {}) }}
                variant="outlined"
                color="secondary"
              >
                İptal
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={!times.length || times.length !== count}
                sx={{ ...(isMobile ? { width: "100%" } : {}) }}
                onClick={() => {
                  if (onSave) {
                    onSave(
                      times.map((row: string) => {
                        const parts = row.split("-");
                        const date = parts[0];
                        const time = parts[1];
                        return { date, time };
                      })
                    );
                    setShow(false);
                    setTimes([]);
                    onClose();
                    setDate(new Date());
                  }
                }}
              >
                Onayla
              </Button>
            </Stack>
          </Stack>
        )}
      </Dialog>
    </>
  );
};
