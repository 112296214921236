import {
  Box,
  Button,
  Dialog,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import React, { Dispatch, useState } from "react";
import { mutate } from "swr";
import { Iconify, RefLink } from "../../components";
import {
  IServiceProvider,
  IServiceProviderStatus,
  ServiceDays,
  ServiceStatusList,
} from "../../interface";
import { axiosInstance, fDateTime, wait } from "../../utils";
import { ServiceSettinsModal, UploadZone } from "../../section";
import { Days } from "../../data";
import { useAuth } from "../../context";

const ModalItem = ({
  title,
  value,
  sx,
}: {
  title?: string;
  value?: string | number | React.ReactNode;
  sx?: any;
}) => {
  return (
    <Stack gap={1} sx={{ flex: 1, ...sx }}>
      {title && (
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "24px",
            color: "grey.700",
          }}
        >
          {title}
        </Typography>
      )}
      {!!value && typeof value === "object" && value}
      {value && (typeof value === "string" || typeof value === "number") && (
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "30px",
            color: "gray.900",
            fontWeight: 500,
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const ModalContainer = ({
  isModal,
  children,
}: {
  isModal: boolean;
  children: React.ReactNode;
}) => {
  if (isModal) {
    return (
      <Box
        sx={{
          position: "absolute",
          inset: 0,
          backgroundColor: "grey.50",
          zIndex: 1,
          overflowY: "auto",
        }}
      >
        {children}
      </Box>
    );
  }
  return <>{children}</>;
};

const filesTabs = [
  "Vergi levhası",
  "Dekont",
  "Ruhsat",
  "Ticari Sicil Gazetesi",
  "Faaliyet Belgesi",
  "İmza Sirküleri",
  "1. Teknisyen Kimlik Arkalı Önlü",
  "2. Teknisyen Kimlik Arkalı Önlü",
  "İşyeri Görselleri",
];

export const ServiceDetailModal = ({
  selectedService,
  setSelectedService,
  isModal,
}: {
  selectedService: IServiceProvider;
  setSelectedService: Dispatch<React.SetStateAction<IServiceProvider | null>>;
  isModal?: boolean;
}) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);

  const [showFiles, setShowFiles] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const onConfirm = async () => {
    setLoading(true);

    try {
      await axiosInstance.put(`/service-provider/${selectedService._id}`, {
        status: ServiceStatusList.SET_PASSWORD,
      });

      const params = new URLSearchParams();
      params.append("status", ServiceStatusList.PENDING_APPROVAL);
      mutate("/service-provider");
      mutate(`/service-provider/query?${params.toString()}`);

      setSelectedService(null);

      enqueueSnackbar("Bayii durumu değiştirildi.", { variant: "success" });
    } catch (error) {}

    setLoading(false);
  };

  const onSaveFile = async (files: any[]) => {
    setDisabled(true);
    try {
      await axiosInstance.put(`/service-provider/${selectedService?._id}`, {
        files,
      });
      enqueueSnackbar("Bayi evrakları düzenlendi.", {
        variant: "success",
      });

      const params = new URLSearchParams();
      params.append("status", ServiceStatusList.PENDING_APPROVAL);
      mutate("/service-provider");
      mutate(`/service-provider/query?${params.toString()}`);
      mutate(`/service-provider/profile/${selectedService._id}`);
    } catch (error) {
      enqueueSnackbar(`Bilinmeyen bir hata oluştu.`, { variant: "error" });
      console.log(error);
    }
    setDisabled(false);
  };

  const getReferans = () => {
    let ref = selectedService.reference;

    if (ref && ref.startsWith("S-")) {
      ref = ref.replace("S-", "");
    }

    return ref;
  };

  if (!selectedService) return null;

  return (
    <ModalContainer isModal={!!isModal}>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showFiles}
        onClose={() => {
          setShowFiles(false);
        }}
      >
        <Stack gap={2} sx={{ p: 3 }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>
              {selectedService?.offical_title} - Gerekli Evraklar{" "}
            </Typography>
            <IconButton
              onClick={() => {
                setShowFiles(false);
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <UploadZone
            disabled={disabled}
            files={selectedService?.files}
            onSave={(newFiles) => {
              onSaveFile(newFiles);
            }}
          />
        </Stack>
      </Dialog>

      <Stack gap={2} p={isModal ? 3 : 0}>
        {isModal && (
          <>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{ fontSize: "24px", lineHeight: "48px", fontWeight: 500 }}
              >
                Bayii Başvuru Bilgileri
              </Typography>
              <IconButton
                onClick={() => {
                  setSelectedService(null);
                }}
              >
                <Iconify icon="ic:baseline-close" />
              </IconButton>
            </Stack>
          </>
        )}
        <Box
          sx={{
            border: "1px solid",
            borderColor: "divider",
            borderRadius: 4,
            backgroundColor: "#fff",
          }}
        >
          <Stack
            sx={{
              "&>*": {
                borderBottom: "1px solid",
                borderBottomColor: "divider",
                p: 3,
              },
              "&>*:last-child": {
                borderBottom: "0px",
              },
            }}
          >
            <Stack gap={3}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <ModalItem value="Montaj Noktası Yetkilisine Ait Bilgiler" />

                <Stack direction="row" alignItems="center" gap={1}>
                  {user?.role === "admin" && (
                    <IconButton
                      onClick={() => {
                        setShowFiles(true);
                      }}
                    >
                      <Iconify icon="ic:round-upload-file" />
                    </IconButton>
                  )}
                  <ServiceSettinsModal forceMe={selectedService} />
                </Stack>
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Yetkili İsmi Soyisim"
                  value={selectedService.authorized_person_name}
                />
                <ModalItem
                  title="Yetkili Mail Adresi"
                  value={selectedService.authorized_person_email}
                />
                <ModalItem
                  title="Yetkili Telefon Numarası"
                  value={selectedService.authorized_person_phone}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Referans"
                  value={
                    <RefLink reference={selectedService.reference ?? ""} />
                  }
                />
                {!!selectedService.services?.length ? (
                  <ModalItem
                    title="Bayi Referans Sayısı"
                    value={selectedService.services.length}
                  />
                ) : (
                  <Box sx={{ flex: 1 }} />
                )}
                <Box sx={{ flex: 1 }} />
              </Stack>
            </Stack>

            <Stack gap={3}>
              <Stack direction="row">
                <ModalItem value="Montaj Noktasına Ait Bilgiler" />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Montaj Noktası Tabela İsmi"
                  value={selectedService?.signage_name ?? "-"}
                />
                <ModalItem
                  title="Resmi Ünvanı"
                  value={selectedService?.offical_title ?? "-"}
                />
                <ModalItem
                  title="Vergi Dairesi"
                  value={selectedService?.tax_office ?? "-"}
                />
                <ModalItem
                  title="Vergi Numarası"
                  value={selectedService?.tax_number ?? "-"}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="İl"
                  value={selectedService.address?.city ?? "-"}
                />
                <ModalItem
                  title="İlçe"
                  value={selectedService.address?.district ?? "-"}
                />
                {/* <ModalItem
                  title="Mahalle"
                  value={selectedService.address?.neighborhood ?? "-"}
                /> */}
                {/* <ModalItem
                  title="Sokak"
                  value={selectedService.address?.street ?? "-"}
                /> */}
                <Box sx={{ flex: 1 }} />
                <Box sx={{ flex: 1 }} />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Adres"
                  value={selectedService.address?.fullAddress ?? "-"}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Enlem"
                  value={selectedService.coordinates?.lat ?? "-"}
                />
                <ModalItem
                  title="Boylam"
                  value={selectedService.coordinates?.lng ?? "-"}
                />
                <Box sx={{ flex: 1 }} />
                <Box sx={{ flex: 1 }} />
              </Stack>
            </Stack>
            <Stack gap={3}>
              <Stack direction="row">
                <ModalItem value="İşletmeye Ait Bilgiler" />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="İşletme Tipi"
                  value={selectedService?.business_type ?? "-"}
                />
                <ModalItem
                  title="İşyeri Tipi"
                  value={selectedService?.workplace_type ?? "-"}
                />
                <ModalItem
                  title="Toplam Çalışan Sayısı"
                  value={selectedService?.total_employees?.toString() ?? "-"}
                />
                <ModalItem
                  title="Toplam Çalışan Sayısı"
                  value={
                    selectedService?.employees_for_installation?.toString() ??
                    "-"
                  }
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Randevu Telefonu"
                  value={selectedService?.appointment_phone ?? "-"}
                />
                <ModalItem
                  title="Çalışma Günleri"
                  value={(selectedService.working_days ?? [])
                    .sort((a, b) => Days.indexOf(a) - Days.indexOf(b))
                    .map((r: any) => (ServiceDays as any)[r])
                    .join(", ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Çalışma Saatleri"
                  value={selectedService.working_hours?.join(", ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Toplam Araç Kapasitesi"
                  value={selectedService.total_vehicle_capacity ?? "-"}
                />
                <ModalItem
                  title="Montaj hizmeti için uygun taşıt sayısı?"
                  value={selectedService?.vehicles_for_installation ?? "-"}
                />
                <ModalItem
                  title="İşletme Tipi/ Montaj Hizmetleri Sağlayıcı Tipi"
                  value={selectedService.business_type}
                />
                <ModalItem
                  title="Montaj alanında internet hizmeti var mı?"
                  value={selectedService.has_internet_service ? "Var" : "-"}
                />
              </Stack>
            </Stack>
            {selectedService.technicians.length > 0 && (
              <Stack gap={3}>
                <Stack direction="row">
                  <ModalItem value="Teknisyen Bilgileri" />
                </Stack>
                {selectedService.technicians.map((tec, index) => (
                  <Stack key={index} direction="row">
                    <ModalItem title="Teknisyen Adı Soyadı" value={tec.name} />
                    <ModalItem title="Teknisyen E-mail" value={tec.email} />
                    <ModalItem
                      title="Teknisyen Telefon Numarası"
                      value={tec.phone}
                    />
                    <Box sx={{ flex: 1 }} />
                  </Stack>
                ))}
              </Stack>
            )}
            {!!selectedService.files.length && (
              <Stack>
                <Stack direction="row">
                  <ModalItem value="Yüklenen Dosyalar" />
                </Stack>
                <Stack
                  direction="row"
                  flexWrap={"wrap"}
                  gap={2}
                  sx={{ mx: -2 }}
                >
                  {selectedService.files.map((file, index) => (
                    <Stack key={index} gap={1} sx={{ p: 2 }}>
                      {file ? (
                        <>
                          <Stack gap={0.5}>
                            <Typography>{file.name}</Typography>
                            <a
                              href={`${process.env.REACT_APP_BACKEND_URL}/${file.url}`}
                              target="_blank"
                            >
                              <Button
                                size="small"
                                variant="contained"
                                color="secondary"
                              >
                                İncele
                              </Button>
                            </a>
                          </Stack>
                          <Typography
                            sx={{ fontSize: "12px", lineHeight: "12px" }}
                          >
                            {fDateTime(file.uploadTime)}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography>{filesTabs[index]}</Typography>
                          <Typography>Yüklenmedi</Typography>
                        </>
                      )}
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            )}
            {!!selectedService.montages?.length && (
              <Stack direction="row">
                <ModalItem
                  title="Referanslı Montaj Adeti"
                  value={selectedService.montages.length}
                />
                <ModalItem
                  title="Onaylanan Montaj Adeti"
                  value={
                    selectedService.montages.filter(
                      (montage) => montage.status === "completed"
                    ).length
                  }
                />
                <Box sx={{ flex: 1 }} />
              </Stack>
            )}
          </Stack>
        </Box>
        {selectedService.status === ServiceStatusList.PENDING_APPROVAL && (
          <Stack direction="row" gap={2} mt={1}>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => {
                setSelectedService(null);
              }}
              sx={{ backgroundColor: "black", minWidth: "256px" }}
            >
              Reddet
            </Button>
            <Button
              variant="contained"
              color={"secondary"}
              sx={{ minWidth: "256px" }}
              onClick={() => onConfirm()}
              disabled={loading}
            >
              Ön Başvuruyu Onayla
            </Button>
          </Stack>
        )}
      </Stack>
    </ModalContainer>
  );
};
