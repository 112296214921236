import { toZonedTime } from "date-fns-tz";
import { Dispatch, useCallback, useEffect, useState } from "react";
import useSWR, { mutate } from "swr";
import {
  axiosInstance,
  fDate,
  fDateTime,
  fetcher,
  formatApplicationDates,
} from "../../../utils";
import { useDebouncedValue, useMe } from "../../../hooks";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { PATHS } from "../../../routes";
import {
  CircularProgressModal,
  Iconify,
  MontageCalendar,
  MontageStatusChanger,
  MontageTimePicker,
} from "../../../components";
import {
  IMontageApplication,
  IMontageStatusLabel,
  IServiceProvider,
  ServiceStatusList,
} from "../../../interface";
import {
  GoogleMap,
  LoadScript,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import { enqueueSnackbar } from "notistack";
import { format, parseISO } from "date-fns";
import { tr } from "date-fns/locale";
import { useAuth } from "../../../context";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.grey[100],
  },
}));

const ModalItem = ({
  title,
  value,
  sx,
  arrow,
}: {
  title: string;
  value?: string;
  sx?: any;
  arrow?: boolean;
}) => {
  return (
    <Stack gap={1} sx={{ flex: 1, ...sx }}>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "grey.700",
        }}
      >
        {title}
      </Typography>
      {value && (
        <Stack direction="row" gap={1} alignItems={"center"}>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "30px",
              color: "gray.900",
              fontWeight: 500,
            }}
          >
            {value}
          </Typography>
          {arrow && <Iconify icon="mdi:arrow-right" />}
        </Stack>
      )}
    </Stack>
  );
};

export const MontageDetailModal = ({
  selectedMontage,
  setSelectedMontage,
}: {
  selectedMontage: string;
  setSelectedMontage: Dispatch<React.SetStateAction<string>>;
}) => {
  const { user } = useAuth();

  const [selectedDateTimes, setSelectedDateTimes] = useState<
    { date: string; time: string }[]
  >([]);
  const [selectedService, setSelectedService] = useState("");
  const [postError, setError] = useState("");
  const [selectedPosition, setSelectedPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [savedPosition, setSavedPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 40.98691,
    lng: 28.76995,
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    map.setZoom(10);
    map.setCenter(mapCenter);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      setSelectedPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
    }
  };

  const { data: services } = useSWR<IServiceProvider[]>(
    `/service-provider/application`,
    fetcher
  );

  const { data, error, isLoading } = useSWR<
    IMontageApplication & { service_provider: IServiceProvider }
  >(
    selectedMontage ? `/montage/application/${selectedMontage}` : null,
    fetcher,
    { keepPreviousData: false }
  );

  const onSave = async ({
    position,
    dates,
    service,
  }: {
    position: google.maps.LatLngLiteral;
    dates: string[];
    service: string;
  }) => {
    setError("");
    try {
      const { data } = await axiosInstance.post(
        `/service-provider/${service}/reservation/mobile`,
        {
          montage: selectedMontage,
          position,
          dates,
        }
      );

      mutate(`/montage/application/${selectedMontage}`);
      enqueueSnackbar("İşlem Başarılı", { variant: "success" });
    } catch (error: any) {
      let errorString = "Beklenmedik bir hata oluştu.";
      console.log(error);
      const { data } = error.response ?? {};
      if (data) {
        const { errors } = data;
        if (errors) {
          errorString = errors
            ? errors?.join("<br/>")
            : "Beklenmedik bir hata oluştu.";
          console.log(errors);
        }
      }
      setError(errorString);
      // enqueueSnackbar(e.message, { variant: "error" });
    }
  };
  useEffect(() => {
    if (data && map) {
      if (data.type !== "mobile") return;
      if (!data.location) return;

      const mapCenter = {
        lat: data.location.lat,
        lng: data.location.lng,
      };
      console.log(mapCenter);
      map.setZoom(17);
      map.setCenter(mapCenter);

      setSelectedPosition(mapCenter);
    }
  }, [data, map]);

  useEffect(() => {
    if (data) {
      const totalVehicles =
        data?.vehicles?.reduce((total: number, row: any) => {
          total += row.quantity;
          return total;
        }, 0) ?? 0;

      if (totalVehicles > 15 && data.type === "service") {
        setSelectedService(data.service_provider._id);
      }
    }
  }, [data]);

  const onReject = async () => {
    if (!window.confirm("Devam etmek istiyor musunuz?")) {
      return;
    }
    await axiosInstance.put(`/montage/application/${selectedMontage}`, {
      is_rejected: true,
    });
    mutate(`/montage/application/${selectedMontage}`);
    enqueueSnackbar("Montaj durumu düzenlendi.", { variant: "success" });

    setSelectedMontage("");
  };

  const updateDateTimes = async () => {
    const timesWithUTC: { date: string; time: string }[] = [];

    const times = selectedDateTimes;
    times.forEach((row: { time: string; date: string }) => {
      const utcDate = new Date();

      const { time, date } = row; // yyyy.MM.dd hh:mm

      const dateParts = date.split(".");
      const timeParts = time.split(":");

      utcDate.setUTCHours(parseInt(timeParts[0]));
      utcDate.setUTCMinutes(parseInt(timeParts[1]));

      utcDate.setUTCDate(parseInt(dateParts[2]));
      utcDate.setUTCMonth(parseInt(dateParts[1]) - 1);
      utcDate.setUTCFullYear(parseInt(dateParts[0]));
      utcDate.setUTCSeconds(0);
      utcDate.setUTCMilliseconds(0);

      timesWithUTC.push({
        date: utcDate.toISOString(),
        time: time,
      });
    });

    if (!window.confirm("Devam etmek istiyor musunuz?")) {
      return;
    }
    await axiosInstance.put(`/montage/application/${selectedMontage}`, {
      application_date: timesWithUTC.map((row) => row.date),
      service_provider: selectedService,
    });
    mutate(`/montage/application/${selectedMontage}`);
    enqueueSnackbar("Montaj randevu tarihleri düzenlendi.", {
      variant: "success",
    });
  };

  const totalVehicles =
    data?.vehicles?.reduce((total: number, row: any) => {
      total += row.quantity;
      return total;
    }, 0) ?? 0;

  if (!selectedMontage) return null;

  if (isLoading) {
    return <CircularProgressModal />;
  }

  if (!data) return null;

  return (
    <>
      <Box
        sx={{
          backgroundColor: "grey.50",
          zIndex: 1,
          mt: "-64px",
        }}
      >
        <Stack gap={2}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{ fontSize: "24px", lineHeight: "48px", fontWeight: 500 }}
            >
              Montaj Başvuru Bilgileri
            </Typography>
            <IconButton
              onClick={() => {
                setSelectedMontage("");
              }}
            >
              <Iconify icon="ic:baseline-close" />
            </IconButton>
          </Stack>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "divider",
              borderRadius: 4,
              backgroundColor: "#fff",
            }}
          >
            <Stack
              sx={{
                "&>*": {
                  borderBottom: "1px solid",
                  borderBottomColor: "divider",
                  p: 3,
                },
                "&>*:last-child": {
                  borderBottom: "0px",
                },
              }}
            >
              <Stack direction="row">
                <ModalItem
                  title="Firma İsmi"
                  value={data?.company_name ?? "-"}
                />
                <ModalItem
                  title="Montaj Türü"
                  value={data?.type === "mobile" ? "Mobil" : "Standart"}
                />
                <Box sx={{ flex: 1 }} />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title="Yetkili İsmi Soyisim"
                  value={data.authorized_person_name}
                />
                <ModalItem
                  title="Yetkili Mail Adresi"
                  value={data.authorized_person_email}
                />
                <ModalItem
                  title="Yetkili Telefon Numarası"
                  value={data.authorized_person_phone}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem
                  title={`Taşıt Bilgisi (${totalVehicles} Araç)`}
                  value={(data.vehicles ?? [])
                    .map((v: any) => `${v.vehicle_type} ${v.quantity} Adet`)
                    .join(" - ")}
                />
              </Stack>
              <Stack direction="row">
                <ModalItem title="İl" value={data.address?.city ?? "-"} />
                <ModalItem title="İlçe" value={data.address?.district ?? "-"} />
                {/* <ModalItem title="Sokak" value={data.address?.street ?? "-"} /> */}
              </Stack>
              <Stack gap={3}>
                <Stack direction="row">
                  {data.service_provider && user?.role === "admin" && (
                    <div style={{ flex: 1 }}>
                      <Tooltip title="Bayii Detayı" arrow>
                        <span style={{ display: "inline-block" }}>
                          <Link
                            to={
                              PATHS.admin.servicesProviders.root +
                              `?search=${data.service_provider.offical_title}`
                            }
                          >
                            <ModalItem
                              arrow
                              title="Randevu Alınan Bayii Bilgisi"
                              value={data.service_provider.offical_title}
                            />
                          </Link>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {/* {user?.role === "service_provider" &&
                    data.application_date?.length === 0 &&
                    data.type === "service" && (
                      <ModalItem
                        title="Randevu Tarihi"
                        value="Atama bekliyor."
                      />
                    )} */}
                  {!!(data?.application_date ?? []).length && (
                    <ModalItem
                      title="Randevu Tarihi"
                      value={
                        formatApplicationDates(
                          data?.application_date as unknown as string[]
                        )
                        // .map((date: any) => {
                        //   const parsedDate = parseISO(date);
                        //   const zonedDate = toZonedTime(parsedDate, "UTC");
                        //   return format(zonedDate, "dd MMM yyyy HH:mm", {
                        //     locale: tr,
                        //   });
                        // })
                        // .join(" - ")
                      }
                    />
                  )}
                </Stack>
                <Stack direction="row">
                  <ModalItem
                    title="Aktivasyon Kodu"
                    value={data.mint_code ? "Alındı." : "Alınmadı."}
                  />
                  {data.type === "mobile" && data.location && (
                    <Stack sx={{ flex: 1 }}>
                      <ModalItem title="Konum" />
                      <a
                        target="_blank"
                        href={`https://www.google.com/maps/search/${data.location.lat},${data.location.lng}`}
                        rel="noreferrer"
                      >
                        <Stack direction="row" gap={0.25} alignItems="center">
                          Google'da Göster
                          <Iconify icon="mdi:map-marker-outline" />
                        </Stack>
                      </a>
                    </Stack>
                  )}
                  {user?.role === "admin" &&
                    data.reference &&
                    data.reference.length === 6 && (
                      <div style={{ flex: 1 }}>
                        <Tooltip title="Referansı Göster" arrow>
                          <span style={{ display: "inline-block" }}>
                            <Link
                              to={`/admin/referance?search=${data.reference}`}
                            >
                              <ModalItem
                                sx={{ flex: 0 }}
                                title="Referans"
                                value={data.reference ?? "-"}
                                arrow
                              />
                            </Link>
                          </span>
                        </Tooltip>
                      </div>
                    )}
                </Stack>
              </Stack>
              <Stack direction="row" gap={3}>
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "grey.700",
                    }}
                  >
                    Montaj Durumu
                  </Typography>
                  <MontageStatusChanger
                    id={selectedMontage}
                    status={data.status ?? ""}
                  />
                </Stack>
                <Stack gap={1} sx={{ flex: 1 }}>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "grey.700",
                    }}
                  >
                    Tamamlanan Araç Sayısı
                  </Typography>
                  <MontageStatusChanger
                    id={selectedMontage}
                    status={data.completed_vehicle_count ?? ""}
                    forceKey={"completed_vehicle_count"}
                  />
                </Stack>
              </Stack>
              {user?.role === "admin" &&
                data.type === "service" &&
                data.application_date?.length === 0 && (
                  <Stack direction="row" gap={3} alignItems="center">
                    <FormControl size="small" sx={{ minWidth: 200 }}>
                      <InputLabel>Bayii Seç</InputLabel>
                      <Select
                        value={selectedService}
                        onChange={(e) => {
                          setSelectedService(e.target.value);
                        }}
                        size="small"
                        label="Bayii Seç"
                      >
                        {services?.map((service) => (
                          <MenuItem
                            key={service._id.toString()}
                            value={service._id.toString()}
                          >
                            {`${service.offical_title} ${
                              service.address
                                ? `(${
                                    service.address.city
                                      ? service.address.city
                                      : ""
                                  }${
                                    service.address.district
                                      ? " " + service.address.district
                                      : ""
                                  })`
                                : ""
                            }`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <MontageTimePicker
                      count={totalVehicles}
                      service={selectedService}
                      onClose={() => {}}
                      onSave={(resp) => {
                        setSelectedDateTimes(resp);
                      }}
                      showButton
                    />
                    {!!selectedDateTimes.length && (
                      <Stack
                        sx={{ ml: "auto" }}
                        direction="row"
                        alignItems="center"
                        gap={1}
                      >
                        <Typography sx={{ fontSize: "12px" }}>
                          {selectedDateTimes.length} adet randevu seçildi.
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          endIcon={<Iconify icon="ic:round-save" />}
                          onClick={() => {
                            updateDateTimes();
                          }}
                        >
                          Yeni Randevuları Kaydet
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                )}
            </Stack>
          </Box>
          {data.type === "service" && (
            <div>
              {data.is_rejected ? (
                <Typography
                  sx={{ py: 2, color: "error.main", fontWeight: 600 }}
                >
                  Reddedildi.
                </Typography>
              ) : (
                <Button
                  color="error"
                  variant="contained"
                  onClick={() => {
                    onReject();
                  }}
                >
                  Reddet
                </Button>
              )}
            </div>
          )}
          {
            // !data.service_provider && //
            data.type === "mobile" && (
              <Stack gap={2}>
                {isLoaded && (
                  <Stack
                    sx={{
                      borderRadius: 4,
                      overflow: "hidden",
                      ...(user?.role === "service_provider"
                        ? { userSelect: "none", pointerEvents: "none" }
                        : {}),
                    }}
                  >
                    <GoogleMap
                      mapContainerStyle={{ height: 500 }}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      //onClick={handleMapClick}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      {selectedPosition && (
                        <Marker position={selectedPosition} />
                      )}
                    </GoogleMap>
                  </Stack>
                )}
                {postError && <Alert color="error">{postError}</Alert>}
                {!data.is_rejected ? (
                  <Stack direction="row" gap={2}>
                    <Button
                      color="error"
                      variant="contained"
                      onClick={() => {
                        onReject();
                      }}
                    >
                      Reddet
                    </Button>
                    <Button
                      disabled={!selectedPosition}
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        setSavedPosition(selectedPosition);
                      }}
                    >
                      Randevu Oluştur
                    </Button>
                  </Stack>
                ) : (
                  <Typography
                    sx={{ py: 2, color: "error.main", fontWeight: 600 }}
                  >
                    Reddedildi.
                  </Typography>
                )}
              </Stack>
            )
          }
        </Stack>
      </Box>
      <MontageCalendar
        handleClose={() => {
          setSavedPosition(null);
        }}
        open={!!savedPosition}
        montage={data}
        onSaved={({ dates, service }) => {
          if (!savedPosition) {
            return;
          }
          onSave({ dates, service, position: savedPosition });
        }}
        maxCount={
          data.vehicles?.reduce((total, vehilce) => {
            total += vehilce.quantity;
            return total;
          }, 0) ?? 0
        }
      />
    </>
  );
};

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const escapeCSV = (value: string) => {
  if (value == null) return "";
  let stringValue = String(value);
  if (
    stringValue.includes('"') ||
    stringValue.includes(",") ||
    stringValue.includes("\n")
  ) {
    stringValue = `"${stringValue.replace(/"/g, '""')}"`;
  }
  return stringValue;
};

export const MontageListSection = () => {
  const { me } = useMe();
  const { user } = useAuth();
  const [selectedMontage, setSelectedMontage] = useState("");
  const [debouncedSearch, search, setSearch] = useDebouncedValue("");

  const params = new URLSearchParams();
  if (debouncedSearch) params.append("search", debouncedSearch);

  const serviceView = user?.role === "service_provider";

  if (user && user?.role === "service_provider") {
    params.append("service_provider", user?.id);
  }

  const { data: montageList, isLoading } = useSWR<any[]>(
    `/montage/application?${params.toString()}`,
    fetcher
  );

  useEffect(() => {
    mutate(`/montage/application?${params.toString()}`);
  }, [setSelectedMontage]);

  const exportToExcel = () => {
    const header = [
      "ID",
      "Firma Adı",
      "Yetkili Kişi Adı",
      "Yetkili Kişi E-mail",
      "Yetkili Kişi Telefon",
      "Şehir",
      "İlçe",
      "Cadde",
      "Referans Kodu",
      "Bayii",
      "Darphane Onayı",
      "Araç Bilgisi",
      "Başvuru Bilgisi",
      "Başvuru Tipi",
      "Durum",
      "Oluşturulma Tarihi",
      "Tamamlanan Araç Sayısı",
    ];

    const rows = [
      header,
      ...(montageList ?? []).map((item) => [
        item._id ?? "",
        item.company_name ?? "",
        item.authorized_person_name ?? "",
        item.authorized_person_email ?? "",
        item.authorized_person_phone ?? "",
        item.address?.city ?? "",
        item.address?.district ?? "",
        item.address?.street ?? "",
        item.reference ?? "",
        item.service_provider?.offical_title ?? "",
        item.mint_code ? "Alındı" : "Alınmadı",
        item.vehicles
          .map((v: any) => `${v.quantity} ${v.vehicle_type}`)
          .join(" - ") ?? "",
        item.application_date
          .map((date: string) => {
            const parsedDate = parseISO(date);
            const zonedDate = toZonedTime(parsedDate, "UTC");
            return format(zonedDate, "dd MMM yyyy HH:mm", {
              locale: tr,
            });
          })
          .join(" - "),
        item.type === "mobile" ? "Mobil" : "Standart",
        // @ts-ignore
        item.is_rejected ? "Reddedildi" : IMontageStatusLabel[item.status],
        new Date(item.createdAt).toLocaleString() ?? "",
        item.completed_vehicle_count ?? "",
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(rows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

    saveAs(blob, `basvurular-${new Date().valueOf()}.xlsx`);
  };

  const exportCSV = () => {
    exportToExcel();
    return;

    // const header = [
    //   "ID",
    //   "Firma Adı",
    //   "Yetkili Kişi Adı",
    //   "Yetkili Kişi E-mail",
    //   "Yetkili Kişi Telefon",
    //   "Şehir",
    //   "İlçe",
    //   "Cadde",
    //   "Referans Kodu",
    //   "Bayii",
    //   "Darphane Onayı",
    //   "Araç Bilgisi",
    //   "Başvuru Bilgisi",
    //   "Başvuru Tipi",
    //   "Durum",
    //   "Oluşturulma Tarihi",
    //   "Tamamlanan Araç Sayısı",
    // ];

    // const csvRows = [
    //   header.join(","),
    //   ...(montageList ?? []).map((item) => {
    //     const row: any = [
    //       escapeCSV(item._id),
    //       escapeCSV(item.company_name),
    //       escapeCSV(item.authorized_person_name),
    //       escapeCSV(item.authorized_person_phone),
    //       escapeCSV(item.authorized_person_email),
    //       escapeCSV(item.address?.city ?? ""),
    //       escapeCSV(item.address?.district ?? ""),
    //       escapeCSV(item.address?.street ?? ""),
    //       escapeCSV(item.reference ?? ""),
    //       escapeCSV(item.service_provider?.offical_title ?? ""),
    //       escapeCSV(item.mint_code ? "Alındı" : "Alınmadı"),
    //       escapeCSV(
    //         item.vehicles
    //           .map((v: any) => `${v.quantity} ${v.vehicle_type}`)
    //           .join(" - ")
    //       ),
    //       escapeCSV(
    //         item.application_date
    //           .map((date: string) => {
    //             const parsedDate = parseISO(date);
    //             const zonedDate = toZonedTime(parsedDate, "UTC");
    //             return format(zonedDate, "dd MMM yyyy HH:mm", {
    //               locale: tr,
    //             });
    //           })
    //           .join(" - ")
    //       ),
    //       escapeCSV(item.type === "mobile" ? "Mobil" : "Standart"),
    //       escapeCSV(
    //         // @ts-ignore
    //         item.is_rejected ? "Reddedildi" : IMontageStatusLabel[item.status]
    //       ),
    //       escapeCSV(
    //         format(item.createdAt, "dd MMM yyyy HH:mm", { locale: tr })
    //       ),
    //       escapeCSV(item.completed_vehicle_count ?? ""),
    //     ];

    //     return row.join(",");
    //   }),
    // ];

    // const csvString = csvRows.join("\n");

    // const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement("a");
    // a.href = url;
    // a.download = `basvurular-${new Date().valueOf()}.csv`;

    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
  };

  const futureMontageList = montageList?.filter((item) => {
    const now = new Date().valueOf();

    const times = (item.application_date ?? [])
      .map((t: string) => new Date(t).valueOf())
      .sort((a: number, b: number) => a - b);

    return times[0] > now;
  });
  return (
    <>
      {selectedMontage && (
        <MontageDetailModal
          selectedMontage={selectedMontage}
          setSelectedMontage={setSelectedMontage}
        />
      )}
      {!selectedMontage && (
        <Stack sx={{ gap: 2 }}>
          <Stack
            gap={2}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack direction="row" gap={1} alignItems="center">
              <TextField
                placeholder="Ara"
                sx={{ maxWidth: 200 }}
                size="small"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              {user?.role === "service_provider" &&
                futureMontageList?.length && (
                  <Typography sx={{ fontSize: "12px" }}>
                    Yakın zamanda <b>{futureMontageList.length}</b> adet montaj
                    başvurunuz bulunmakta.
                  </Typography>
                )}
            </Stack>
            {user?.role === "service_provider" && (
              <Link
                target="_blank"
                to={`/utts-montaj-basvuru?ref=S-${user?.id}`}
              >
                <Stack direction="row" alignItems="center" gap={1}>
                  Montaj Oluştur
                  <Iconify icon="solar:alt-arrow-right-line-duotone" />
                </Stack>
              </Link>
            )}
          </Stack>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ backgroundColor: "grey.200" }}>
                  <TableCell>
                    <Typography sx={TableLableStyle}>Tarih</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>İsim</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>Şirket</Typography>
                  </TableCell>
                  {!serviceView && (
                    <TableCell>
                      <Typography sx={TableLableStyle}>Bayii</Typography>
                    </TableCell>
                  )}
                  <TableCell>
                    <Typography sx={TableLableStyle}>Durum</Typography>
                  </TableCell>
                  <TableCell align="right">
                    {montageList?.length && (
                      <ButtonBase
                        onClick={() => {
                          exportCSV();
                        }}
                        sx={{
                          px: 1,
                          borderRadius: 2,
                          fontFamily: "inherit",
                          gap: 1,
                        }}
                      >
                        Tümünü İndir
                        <Iconify icon="vscode-icons:file-type-excel" />
                      </ButtonBase>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && !montageList?.length && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography sx={{ textAlign: "center", opacity: 0.75 }}>
                        Veri Bulunamadı
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {isLoading && (
                  <>
                    {Array(5)
                      .fill("")
                      .map((row, index) => (
                        <StyledTableRow key={index}>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          <TableCell>
                            <Skeleton />
                          </TableCell>
                          {!serviceView && (
                            <TableCell>
                              <Skeleton />
                            </TableCell>
                          )}
                        </StyledTableRow>
                      ))}
                  </>
                )}
                {montageList?.map((row, index) => (
                  <StyledTableRow key={index}>
                    <TableCell>
                      <Typography sx={{ fontSize: "14px" }}>
                        {fDateTime(row.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableLableStyle}>
                        {row.authorized_person_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={TableLableStyle}>
                        {row.company_name}
                      </Typography>
                    </TableCell>
                    {!serviceView && (
                      <TableCell>
                        <Stack alignItems="center" direction="row" gap={1}>
                          <Tooltip
                            title={
                              row?.service_provider?.offical_title ??
                              "Atama Bekleniyor"
                            }
                            arrow
                          >
                            <Chip
                              size="small"
                              label={
                                (
                                  (row?.service_provider
                                    ?.offical_title as string) ??
                                  "Atama Bekleniyor"
                                ).substring(0, 18) +
                                (row?.service_provider?.offical_title?.length >
                                18
                                  ? "..."
                                  : "")
                              }
                              color={
                                row?.service_provider?.offical_title
                                  ? "secondary"
                                  : "warning"
                              }
                            />
                          </Tooltip>
                          {row.type === "mobile" && (
                            <Tooltip title="Mobil Montaj" arrow>
                              <span style={{ fontSize: 0 }}>
                                <Iconify icon="hugeicons:mobile-navigator-01" />
                              </span>
                            </Tooltip>
                          )}
                        </Stack>
                      </TableCell>
                    )}
                    {row.is_rejected ? (
                      <TableCell>Reddedildi</TableCell>
                    ) : (
                      <>
                        <TableCell>
                          <Stack direction="row" alignItems="center" gap={1}>
                            {/* @ts-ignore */}
                            {IMontageStatusLabel[row.status]}
                            {user?.role === "admin" &&
                              row.type === "service" &&
                              row.application_date.length === 0 && (
                                <Tooltip
                                  arrow
                                  title="Lütfen randevu oluşturun."
                                >
                                  <span style={{ fontSize: 0 }}>
                                    <Iconify
                                      color="warning.main"
                                      icon="ic:round-warning"
                                    />
                                  </span>
                                </Tooltip>
                              )}
                          </Stack>
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <Link
                        to="#"
                        onClick={() => {
                          setSelectedMontage(row._id);
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          gap={1}
                          justifyContent="end"
                        >
                          Daha fazlası
                          <Iconify icon="solar:alt-arrow-right-line-duotone" />
                        </Stack>
                      </Link>
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </>
  );
};
