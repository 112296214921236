import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import { Circle, GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Footer, Header } from "../components";
import NearMeIcon from "@mui/icons-material/NearMe";
import { Country, State, City, IState, ICity } from "country-state-city";

const MontajNoktalari = () => {
  const dummyHizmetNoktalari = [
    {
      name: "Shell",
      address: "Shell, Fatih, Küçükçekmece Yolu, 34290 Küçükçekmece/İstanbul",
      sehir: "İstanbul",
      ilce: "Küçükçekmece",
      lat: 40.98691,
      lng: 28.76995,
    },
  ];

  const [cities, setCities] = useState<IState[]>([]);
  const [districts, setDistricts] = useState<ICity[]>([]);
  const [selectedCity, setSelectedCity] = useState<IState | null>(null);
  const [selectedDistrict, setSelectedDistrict] = useState<ICity | null>(null);
  const [mapCenter, setMapCenter] = useState({ lat: 40.98691, lng: 28.76995 });
  const [mapLoaded, setMapLoaded] = useState(false); // New state to track if map is loaded
  const [hizmetNoktalari, setHizmetNoktalari] =
    useState<any[]>(dummyHizmetNoktalari);
  const [hizmetTextField, setHizmetTextField] = useState("");
  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  const locations = [{ lat: 40.98691, lng: 28.76995 }];

  useEffect(() => {
    const cities = State.getStatesOfCountry("TR");
    setCities(cities);
  }, []);

  useEffect(() => {
    if (selectedCity) {
      const districts = City.getCitiesOfState("TR", selectedCity.isoCode);
      setDistricts(districts);
    }
  }, [selectedCity]);

  // Kullanıcının konumuna en yakın hizmet noktasını bulma fonksiyonu
  const getNearestLocation = (latitude: number, longitude: number) => {
    let nearestLocation = dummyHizmetNoktalari[0];
    let minDistance = Number.MAX_VALUE;

    dummyHizmetNoktalari.forEach((location) => {
      const distance = Math.sqrt(
        Math.pow(location.lat - latitude, 2) +
          Math.pow(location.lng - longitude, 2)
      );
      if (distance < minDistance) {
        minDistance = distance;
        nearestLocation = location;
      }
    });

    return nearestLocation;
  };

  const handleGetLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAA0pL05bGustwOYfrT2-wV5tZ4dp2Pwb0`
          );
          const data = await response.json();
          if (data.status === "OK") {
            console.log("data", data);
            const addressComponents = data.results[0].address_components;
            let stateName = "";
            let cityName = "";

            addressComponents.forEach((component: any) => {
              if (component.types.includes("administrative_area_level_1")) {
                stateName = component.long_name;
              }
              if (component.types.includes("administrative_area_level_2")) {
                cityName = component.long_name;
              }
            });
            console.log("il", data.results[0].address_components[4].long_name);
            console.log(
              "ilce",
              data.results[0].address_components[3].long_name
            );

            const selectedState = cities.find(
              (s) =>
                s.name
                  .toLowerCase()
                  .localeCompare(
                    data.results[0].address_components[4].long_name.toLowerCase(),
                    "tr",
                    { sensitivity: "base" }
                  ) === 0
            );
            if (selectedState) {
              console.log("selectedState", selectedState);
              setSelectedCity(selectedState);
              const citiesOfState = City.getCitiesOfState(
                "TR",
                selectedState.isoCode
              );
              const selectedCity = citiesOfState.find(
                (c) =>
                  c.name === data.results[0].address_components[3].long_name
              );
              if (selectedCity) {
                console.log("selectedCity", selectedCity);
                setSelectedDistrict(selectedCity); // Use the correct setter for ICity type
              }
            }

            const nearestLocation = getNearestLocation(latitude, longitude);
            setMapCenter({
              lat: nearestLocation ? nearestLocation.lat : latitude,
              lng: nearestLocation ? nearestLocation.lng : longitude,
            });
          } else {
            alert("Address not found");
          }
        },
        (error) => {
          console.error(error);
          alert("Error getting location");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const filteredHizmetNoktalari = dummyHizmetNoktalari.filter(
      (hizmetNoktasi) => {
        // Şehir filtresi
        const cityMatch = selectedCity
          ? hizmetNoktasi.sehir
              .toLowerCase()
              .localeCompare(selectedCity.name.toLowerCase(), "tr", {
                sensitivity: "base",
              }) === 0
          : true;

        // İlçe filtresi
        const districtMatch = selectedDistrict
          ? hizmetNoktasi.ilce.localeCompare(selectedDistrict.name, "tr", {
              sensitivity: "base",
            }) === 0
          : true;

        // Hizmet arama alanı filtresi
        const textFieldMatch = hizmetTextField
          ? hizmetNoktasi.name
              .toLowerCase()
              .includes(hizmetTextField.toLowerCase())
          : true;

        // Tüm filtreleme koşulları sağlanmalı
        return cityMatch && districtMatch && textFieldMatch;
      }
    );

    setHizmetNoktalari(filteredHizmetNoktalari);
  }, [hizmetTextField, selectedCity, selectedDistrict]);

  return (
    <Box mx={{ xs: 2, sm: 6, md: 6 }} my={2}>
      <Header />
      <Box
        py={3}
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
        }}
      >
        <Typography
          pt={2}
          variant="h2"
          sx={{ color: "#fff", textAlign: "center" }}
        >
          Montaj Noktaları
        </Typography>
        <Typography
          py={2}
          variant="h5"
          sx={{ color: "#ffffffb8", textAlign: "center" }}
        >
          <span style={{ color: "#fff" }}>1 / 2</span> Adım
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <Box
            sx={{ ml: 4, flexGrow: 1, height: 4, backgroundColor: "#fff" }}
          />
          <Box
            sx={{ mr: 4, flexGrow: 1, height: 4, backgroundColor: "#ffffff4c" }}
          />
        </Box>
        <Grid
          container
          gap={2}
          sx={{
            backgroundColor: "#015765",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            mt: 6,
            py: 2,
            width: "96%",
            marginLeft: "2%",
          }}
        >
          <Grid item xs={12} md={3} p={1}>
            <TextField
              sx={{
                backgroundColor: "#f3f3f3",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
              variant="outlined"
              placeholder="Hizmet Noktaları"
              value={hizmetTextField}
              onChange={(e) => setHizmetTextField(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={2} p={1}>
            <FormControl fullWidth>
              <InputLabel>İl seçin</InputLabel>
              <Select
                value={selectedCity?.isoCode || ""}
                sx={{
                  backgroundColor: "#f3f3f3",
                  borderRadius: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
                variant="outlined"
                onChange={(e) => {
                  const selectedCity = cities.find(
                    (city) => city.isoCode === e.target.value
                  );
                  if (selectedCity) {
                    setSelectedCity(selectedCity);
                  }
                }}
                fullWidth
              >
                {cities.map((city) => (
                  <MenuItem key={city.isoCode} value={city.isoCode}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2} p={1}>
            <FormControl fullWidth>
              <InputLabel>İlçe seçin</InputLabel>
              <Select
                value={selectedDistrict?.name || ""}
                sx={{
                  backgroundColor: "#f3f3f3",
                  borderRadius: 2,
                  "& .MuiOutlinedInput-root": {
                    borderRadius: 2,
                    "& fieldset": {
                      borderColor: "transparent",
                    },
                  },
                }}
                variant="outlined"
                aria-placeholder="İlçe seçin"
                onChange={(e) => {
                  const selectedDistrict = districts.find(
                    (ilce) => ilce.name === e.target.value
                  );
                  if (selectedDistrict) {
                    setSelectedDistrict(selectedDistrict);
                  }
                }}
                fullWidth
              >
                {districts.map((district) => (
                  <MenuItem key={district.name} value={district.name}>
                    {district.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={2} p={1}>
            <Button
              onClick={handleGetLocation}
              fullWidth
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 1,
                height: 58,
                color: "#002B32",
                backgroundColor: "#f3f3f3",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 2,
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                },
              }}
            >
              Konum bilgisi al{" "}
              <Box
                sx={{
                  cursor: "pointer",
                }}
              >
                <NearMeIcon />
              </Box>
            </Button>
          </Grid>

          <Grid item height={58} xs={12} md={2}>
            <Box
              onClick={() => {
                window.location.href = "/utts-montaj-basvuru";
              }}
              sx={{
                backgroundColor: "#002b32",
                color: "#fff",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "32px",
                border: "1px solid #36BFFA",
                cursor: "pointer",
              }}
            >
              <Typography
                variant="h6"
                sx={{ textAlign: "center", fontSize: 16 }}
              >
                Başvuruya Devam Et
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            backgroundColor: "#fff",
            borderBottomLeftRadius: "12px",
            borderBottomRightRadius: "12px",
            overflow: "hidden",
            width: "96%",
            marginLeft: "2%",
            minHeight: "600px",
          }}
        >
          <Grid item xs={12} md={4} sx={{ p: 3 }}>
            <Typography variant="h4" sx={{ color: "#293e10" }}>
              Hizmet Noktaları
            </Typography>
            <Divider sx={{ my: 2 }} />
            <List
              sx={{
                maxHeight: "400px",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#002B32",
                  borderRadius: "4px",
                },
              }}
            >
              {hizmetNoktalari.map((_, index) => (
                <React.Fragment key={index}>
                  <ListItem alignItems="flex-start">
                    <ListItemText primary={_.name} secondary={_.address} />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))}
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            sx={{ height: { xs: "300px", md: "auto" } }}
          >
            <LoadScript googleMapsApiKey="AIzaSyAA0pL05bGustwOYfrT2-wV5tZ4dp2Pwb0">
              <GoogleMap
                mapContainerStyle={mapContainerStyle}
                center={mapCenter}
                zoom={12}
                onLoad={() => {
                  setTimeout(() => {
                    setMapLoaded(true);
                  }, 300);
                }} // Set mapLoaded to true once the map is loaded
              >
                {mapLoaded &&
                  locations.map((location, index) => (
                    <React.Fragment key={index}>
                      <Marker
                        position={{ lat: location.lat, lng: location.lng }}
                        icon={{
                          strokeColor: "#173D00",
                          url: require("../assets/img/takkitmarker.png"), // Replace with your image path or URL
                          scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
                        }}
                      />
                      <Circle
                        center={{ lat: location.lat, lng: location.lng }}
                        radius={2000} // 5 km radius
                        options={{
                          fillColor: "#173D00",
                          fillOpacity: 0.2,
                          strokeColor: "#173D00",
                          strokeOpacity: 1,
                          strokeWeight: 1,
                        }}
                      />
                    </React.Fragment>
                  ))}
              </GoogleMap>
            </LoadScript>
          </Grid>
        </Grid>
        <Box height={30}></Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default MontajNoktalari;
