import React, { useMemo, useState } from "react";
import { Footer, Header } from "../components";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ServiceFormSection } from "../section";

const Categories = () => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      sx={{ p: 3, backgroundColor: "white", borderRadius: 2 }}
      gap={3}
    >
      <Box
        sx={{
          p: 3,
          flex: 1,
          backgroundColor: "#002B32",
          color: "#fff",
          borderRadius: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: 18,
              sm: 24,
            },
            mb: 1,
          }}
        >
          GOLD
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }}>
          <b>Sermaye İhtiyacı</b>: Yüksek (~1 milyon)
          <br />
          <b>Yatırım Seviyesi</b>: Yüksek
          <br />
          <b>Geri Dönüş Süresi</b>: ~2 ay
          <br />
          <b>Lisans ve Sigorta</b>: Yıllık Peşin
          <br />
          <b>Programlama Cihazı</b>: Gereklidir
          <br />
          <b>TTB Yatırımı</b>: Yüksek
          <br />
          <b>Personel</b>: En az 2 personel
          <br />
          <b>Araç Gereksinimi</b>: Ticari araç/motosiklet gerekli değil
          <br />
          <b>Tahmini Aylık Kazanç</b>: ~350.000 TL
          <br />
        </Typography>
      </Box>
      <Box
        sx={{
          p: 3,
          flex: 1,
          backgroundColor: "#008765",
          color: "#fff",
          borderRadius: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: {
              xs: 16,
              sm: 24,
            },
            mb: 1,
          }}
        >
          SILVER
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, sm: 16 } }}>
          <b>Sermaye İhtiyacı</b>: Orta (~500 bin)
          <br />
          <b>Yatırım Seviyesi</b>: Orta
          <br />
          <b>Geri Dönüş Süresi</b>: ~2 ay
          <br />
          <b>Lisans ve Sigorta</b>: Aylık Peşin
          <br />
          <b>Programlama Cihazı</b>: Gereklidir
          <br />
          <b>TTB Yatırımı</b>: Orta
          <br />
          <b>Personel</b>: En az 2 personel
          <br />
          <b>Araç Gereksinimi</b>: Ticari araç/motosiklet gerekli değil
          <br />
          <b>Tahmini Aylık Kazanç</b>: ~200.000 TL
          <br />
        </Typography>
      </Box>
    </Stack>
  );
};

const Step01 = ({ onNext }: { onNext?: () => void }) => {
  return (
    <Stack gap={3} sx={{ p: 1 }}>
      <Stack sx={{ backgroundColor: "#E6F3F0", p: 3, gap: 3, borderRadius: 2 }}>
        <Typography
          sx={{
            fontSize: {
              xs: 20,
              sm: 28,
            },
            color: "#000",
            fontWeight: "600",
          }}
        >
          KİT-TAK ile Ulusal Taşıt Tanıma Sistemi (UTTS) Yetkili Servisi olun!
        </Typography>
        <Typography sx={{ fontSize: { sm: 16, xs: 14 } }}>
          KİT-TAK ile Ulusal Taşıt Tanıma Sistemi (UTTS) Yetkili Servisi olun!{" "}
          <br />
          <br />
          Ulusal Taşıt Tanıma Sistemi (UTTS), Türkiye’de bulunan tüm şirket
          araçlarına takılması zorunlu olan Taşıt Tanıma Birimi (TTB) adı
          verilen cihazlar aracılığıyla, yakıt alımı sırasında taşıt bilgilerini
          güvenli bir şekilde kaydederek vergi kaybını önleyen bir sistemdir.
          <br />
          <br />
          Türkiye’nin UTTS (Ulusal Taşıt Tanıma Sistemi) montaj firması Kit-tak,
          devlet tarafından Ulusal Lisans ile yetkilendirilmiş bir UTTS firması
          olarak siz değerli iş ortaklarımıza kazançlı bir iş modeli
          sunmaktadır. <br />
          <br />
          <br />
          <b>Neden Kit-Tak İş Ortağı Olmalıyım?</b>
          <br />
          Kit-Tak.com, cazip bayilik modelleriyle iş ortaklarına benzersiz
          fırsatlar sunuyor. UTTS'nin zorunlu hale geldiği bu dönemde, hızla
          büyüyen bir pazarda yer alarak geleceğin iş modeline yatırım
          yapabilirsiniz.
          <br />
          <br />
          Kit-Tak’ın merkezi dijital altyapısını kullanarak tüm bayilik
          sürecinizi online olarak takip edebilir ve tamamlayabilirsiniz:
          <br />
          <br />
          <b>Bayilik Modellerimiz</b>
          <br />
          Kit-Tak.com, farklı yatırım seviyelerine uygun iki esnek bayilik
          modeli sunarak girişimcilerin ihtiyaçlarına ve bütçelerine özel
          çözümler sağlar. Detaylar aşağıdaki tabloda yer almaktadır:
        </Typography>
        <div>
          <Button
            color="secondary"
            variant="contained"
            onClick={() => onNext!()}
            sx={{ width: { xs: "100%", sm: "unset" } }}
          >
            Devam
          </Button>
        </div>
      </Stack>
      <Categories />
    </Stack>
  );
};

const Step02 = ({ onNext }: { onNext?: () => void }) => {
  return (
    <Stack gap={3} sx={{ p: 1 }}>
      <Stack sx={{ backgroundColor: "#E6F3F0", p: 3, gap: 3, borderRadius: 2 }}>
        <Typography
          sx={{
            fontSize: {
              xs: 20,
              sm: 28,
            },
            color: "#000",
            fontWeight: "600",
          }}
        >
          KİT-TAK ile Ulusal Taşıt Tanıma Sistemi (UTTS) Yetkili Servisi olun!
        </Typography>
        <Typography>
          <b> Bayilik Süreci</b>
          <br />
          1. Başvuru: Web sitemizden “UTTS BAYİLİK BAŞVURUSU” linkini tıklayarak
          başvurunuzu sadece 1 dakikada online olarak yapabilirsiniz.
          <br />
          2. Değerlendirme: Uzman ekibimiz başvurunuzu değerlendirir.
          <br />
          3. Sözleşme: Onay sonrası bayilik sözleşmenizi imzalayın.
          <br />
          4. Eğitim ve Kurulum: Teknik ekip tarafından sağlanacak eğitimlerin
          ardından işletmenizi faaliyete geçirin.
          <br />
          <br />
          <b>Sıkça Sorulan Sorular</b>
          <br />
          * Bayilik açma süresi nedir? Lisans onayından sonra 3 gün içinde
          işletmenizi faaliyete geçirebilirsiniz.
          <br />
          * Hangi şehirlerde bayilik açabilirim? Türkiye genelinde uygun
          bölgelerde bayilik açabilirsiniz.
          <br />
          <br />
          <b>Hemen Başvurun!</b>
          <br />
          Bu büyüme fırsatını kaçırmayın. Daha fazla bilgi almak ve başvurunuzu
          gerçekleştirmek için bizimle iletişime geçin:
          <br />
          <br />* Web Sitesi:{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="www.kit-tak.com"
            target="_blank"
          >
            www.kit-tak.com
          </a>
          <br />* E-posta:{" "}
          <a
            style={{ textDecoration: "underline" }}
            href="mailto:info@kit-tak.com"
          >
            info@kit-tak.com
          </a>
          <br />* Telefon:{" "}
          <a
            style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
            href="tel:908502198559"
          >
            {`0 850 219 85 59`}
          </a>
          <br />* GSM:{" "}
          <a
            style={{ whiteSpace: "nowrap", textDecoration: "underline" }}
            href="tel:905330704841"
          >
            {`0 533 070 48 41`}
          </a>
        </Typography>
        <div>
          <Button
            color="secondary"
            variant="contained"
            sx={{ width: { xs: "100%", sm: "unset" } }}
            onClick={() => onNext!()}
          >
            Devam
          </Button>
        </div>
      </Stack>
      <Categories />
    </Stack>
  );
};

const NedenKitTakOrtak = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentStep, setCurrentStep] = useState(0);

  const renderSteps = useMemo(() => {
    const stepsData = [
      {
        title:
          "Siz de Yetkili Montaj Servisi Olarak Büyüyen Bir Sektörde Güvenilir İlave Gelir Fırsatları Yakalayın!",
        description: `Siz de Yetkili Montaj Servisi Olarak Büyüyen Bir Sektörde Güvenilir İlave Gelir Fırsatları Yakalayın!

Türkiye Cumhuriyeti Hazine ve Maliye Bakanlığı Darphane ve Damga Matbaası Genel Müdürlüğü tarafından yönetilen Ulusal Taşıt Tanıma Sistemi (UTTS), Türkiye’de araç yönetimi ve taşıt tanıma alanında yenilikçi bir iş kolu olarak hayata geçirilmiştir.

Kit-tak platformu ile iş birliği içinde çalışan yetkili montaj servisleri, güvenilir ve düzenli bir iş ve gelir akışına sahip olmanın yanında, TTB montajı ve diğer bağlantılı hizmetlerle çeşitlendirilmiş yeni gelir imkanlarına erişim sağlamaktadır.
`,
        image: require("../assets/img/kittak1.png"),
      },
    ];

    const currentData = stepsData[0];

    switch (currentStep) {
      case 0:
        return (
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    xs: 20,
                    sm: 24,
                  },
                  color: "#008765",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Kit-Tak Avantajları
              </Typography>
              <Box
                px={{
                  xs: 2,
                  sm: 0,
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: 14,
                      sm: 16,
                    },
                    color: "#191C22",
                    textAlign: "center",
                    mt: 2,
                  }}
                >
                  İş Yeriniz İçin Güvenli İlave Gelir Yaratın! Türkiye’nin
                  Yetkili TTB Montaj Servisi Ağına Katılın ve<br></br>
                  Gelirlerinizi Güvence Altına Alın!
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#E6F3F0",
                  borderRadius: "8px",
                  height: "100%",
                  p: {
                    xs: 2,
                    sm: 3,
                  },
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: {
                      xs: 20,
                      sm: 28,
                    },
                    color: "#000",
                    fontWeight: "600",
                    mt: 1,
                  }}
                >
                  {currentData.title}
                </Typography>
                <Typography
                  sx={{
                    mb: 1,
                    fontSize: {
                      xs: 14,
                      sm: 16,
                    },
                    color: "#1C1B1C",
                    fontWeight: "400",
                    mt: 2,
                    whiteSpace: "pre-line",
                  }}
                >
                  {currentData.description}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  fontSize: 0,
                }}
              >
                <img
                  src={currentData.image}
                  alt="UTTS"
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        );
      case 1:
        return (
          <Step01
            onNext={() => {
              setCurrentStep((c) => c + 1);
            }}
          />
        );
      case 2:
        return (
          <Step02
            onNext={() => {
              setCurrentStep((c) => c + 1);
            }}
          />
        );
      case 3:
        return <ServiceFormSection />;
    }
  }, [currentStep]);

  return (
    <Container>
      <Box
        mx={{
          xs: 2,
          sm: 4,
          md: 6,
        }}
        my={2}
      >
        <Header />
        <Box
          py={3}
          sx={{
            backgroundColor: "#002B32",
            borderBottomLeftRadius: "24px",
            borderBottomRightRadius: "24px",
          }}
        >
          {/* Navigation Buttons */}
          <Box
            display={"flex"}
            flexDirection={{
              xs: "row",
              sm: "row",
            }}
            justifyContent={"space-between"}
            alignItems={"center"}
            px={{
              xs: 2,
              sm: 5,
            }}
          >
            <IconButton
              onClick={() => {
                if (currentStep > 0) {
                  setCurrentStep(currentStep - 1);
                }
              }}
              sx={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#dedede",
                },
              }}
            >
              <ArrowBackIosNewIcon
                sx={{
                  color: "#000",
                }}
              />
            </IconButton>
            <Typography
              pt={2}
              variant="h2"
              sx={{
                fontSize: {
                  xs: 22,
                  sm: 30,
                  md: 40,
                },
                fontWeight: "600",
                color: "#fff",
                textAlign: "center",
                flexGrow: 1,
              }}
            >
              Neden Kit-Tak İş Ortağı Olmalıyım?
            </Typography>
            <IconButton
              onClick={() => {
                if (currentStep < 3) {
                  setCurrentStep(currentStep + 1);
                }
              }}
              sx={{
                borderRadius: "50%",
                width: 50,
                height: 50,
                backgroundColor: "#ffffff",
                "&:hover": {
                  backgroundColor: "#dedede",
                },
              }}
            >
              <ArrowForwardIosIcon
                sx={{
                  color: "#000",
                }}
              />
            </IconButton>
          </Box>
          {/* Step Indicator */}
          <Typography
            py={2}
            variant="h5"
            sx={{
              color: "#ffffffb8",
              textAlign: "center",
            }}
          >
            <span
              style={{
                color: "#fff",
              }}
            >
              {currentStep + 1} / 4
            </span>{" "}
            Adım
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              mt: 2,
              px: {
                xs: 2,
                sm: 4,
              },
            }}
          >
            {[...Array(4)].map((_, index) => (
              <Box
                key={index}
                sx={{
                  mx: 0.5,
                  flexGrow: 1,
                  height: 4,
                  backgroundColor: currentStep >= index ? "#fff" : "#ffffff4c",
                }}
              />
            ))}
          </Box>
          <Box
            sx={{
              backgroundColor: "#F6F6F6",
              borderRadius: "8px",
              mt: 4,
              mx: {
                xs: 1,
                sm: 2,
                md: 4,
              },
              p: {
                xs: 2,
                sm: 3,
              },
            }}
          >
            {renderSteps}
          </Box>
        </Box>
        <Footer />
      </Box>
    </Container>
  );
};

export default NedenKitTakOrtak;
