import { Box, SxProps, Theme, useTheme } from "@mui/material";
import React, { ReactNode } from "react";

export const GreenPaper: React.FC<{
  children: ReactNode;
  sx?: SxProps<Theme>;
}> = ({ children, sx }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.secondary.main,
        borderBottomLeftRadius: 24,
        borderBottomRightRadius: 24,
        p: 3,
      }}
    >
      <Box
        sx={{
          backgroundColor: "#F9FAFB",
          borderRadius: 4,
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
