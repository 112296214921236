import { Container, Stack } from "@mui/material";
import { Footer, Header } from "../../components";
import { InvitationPasswordSection } from "../../section";

export const InvitationPasswordPage = () => {
  return (
    <Container sx={{ mt: 2 }}>
      <Header />
      <InvitationPasswordSection />
      <Footer />
    </Container>
  );
};
