import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import useSWR, { mutate } from "swr";
import { axiosInstance, fDateTime, fetcher } from "../../utils";
import { useDebouncedValue } from "../../hooks";
import {
  Alert,
  Box,
  Button,
  ButtonBase,
  Chip,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Radio,
  Select,
  setRef,
  Skeleton,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link, useSearchParams } from "react-router-dom";
import { Iconify } from "../../components";
import { useEffect, useState } from "react";
import { IReference, RefType } from "../../interface";
import { enqueueSnackbar } from "notistack";
import { format, parse, parseISO } from "date-fns";
import { tr } from "date-fns/locale/tr";
import { toZonedTime } from "date-fns-tz";
import MaskedInput from "react-text-mask";
import { Masks } from "../../data";

const RefForm = ({
  mutateString,
  onClose,
}: {
  mutateString: string;
  onClose: () => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data: citys } = useSWR("/montage/city", fetcher);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const styles = {
    row: {
      flexDirection: "row",
      gap: 3,
      ...(isMobile ? { flexDirection: "column" } : {}),
    },
    formControl: {
      flex: 1,
      ...(isMobile
        ? { width: "100% !important", flex: 1, minWidth: "100%" }
        : {}),
    },
    nativeLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  };

  const [form, setForm] = useState({
    name_surname: "",
    email: "",
    phone: "",
    iban: "",
    id_number: "",
    type: "freelance",
    company_name: "",
    tax_number: "",
    tax_office: "",
    company_iban: "",
    address: {
      city: "",
      district: "",
      neighborhood: "",
    },
  } as IReference);

  const onChangeForm = (key: string, value: any, extend?: any) => {
    const f: any = { ...form };
    f[key] = value;

    let ff = { ...f };
    if (extend) {
      ff = { ...f, ...extend };
    }
    setForm(ff);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    setLoading(true);

    try {
      const filteredForm = Object.keys(form).reduce((acc, key) => {
        const typedKey = key as keyof IReference;

        if (
          form[typedKey] &&
          (typeof form[typedKey] !== "object" ||
            Object.values(form[typedKey] as object).some((val) => val))
        ) {
          // @ts-ignore
          acc[typedKey] = form[typedKey];
        }
        return acc;
      }, {} as IReference);

      await axiosInstance.post("/reference/create", filteredForm);
      enqueueSnackbar("Referans linki oluşturuldu.", { variant: "success" });
      mutate(mutateString);
      onClose();
    } catch (error: any) {
      let errorString = "Beklenmedik bir hata oluştu.";
      const { data } = error.response ?? {};
      if (data) {
        const { errors } = data;
        if (errors) {
          errorString = errors
            ? errors?.join("<br/>")
            : "Beklenmedik bir hata oluştu.";
          console.log(errors);
        }
      }
      setError(errorString);
    }

    setLoading(false);
  };

  const computedDistict =
    form.address?.city && citys ? citys[form.address.city] : [];

  return (
    <Stack sx={{ px: 3, pb: 3 }}>
      <form onSubmit={onSubmit}>
        <Stack gap={2}>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              label="İsim Soyisim"
              variant="outlined"
              value={form.name_surname}
              onChange={(e) => onChangeForm("name_surname", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <TextField
              required
              type="email"
              label="Mail Adresi"
              variant="outlined"
              value={form.email}
              onChange={(e) => onChangeForm("email", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <MaskedInput
              value={form.phone}
              onChange={(e) => onChangeForm("phone", e.target.value)}
              guide={false}
              render={(innerRef, props) => (
                <TextField
                  {...props}
                  inputRef={innerRef}
                  label="Telefon Numarası"
                  // placeholder="+90 "
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
              mask={[
                "0",
                " ",
                "(",
                /\d/,
                /\d/,
                /\d/,
                ")",
                " ",
                /\d/,
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
                " ",
                /\d/,
                /\d/,
              ]}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            <TextField
              label="TC Kimlik Numarası"
              variant="outlined"
              value={form.id_number}
              onChange={(e) => onChangeForm("id_number", e.target.value)}
            />
          </FormControl>
          <FormControl sx={styles.formControl}>
            {/* <TextField
              label="IBAN Numarası"
              variant="outlined"
              value={form.iban}
              onChange={(e) => onChangeForm("iban", e.target.value)}
            /> */}
            <MaskedInput
              value={form.iban}
              onChange={(e) => onChangeForm("iban", e.target.value)}
              guide={false}
              render={(innerRef, props) => (
                <TextField
                  {...props}
                  inputRef={innerRef}
                  placeholder="TR "
                  label="IBAN Numarası"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
              mask={Masks.iban}
            />
          </FormControl>
          <Stack sx={styles.row}>
            <FormControl>
              <label
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Radio
                  //@ts-ignore
                  checked={form.type === "owner"}
                  onChange={(e) => {
                    onChangeForm(
                      "type",
                      e.target.checked ? "owner" : "freelance"
                    );
                  }}
                />
                <Typography>Şirketim var</Typography>
              </label>
            </FormControl>
            <FormControl>
              <label style={styles.nativeLabel as any}>
                <Radio
                  //@ts-ignore
                  checked={form.type === "freelance"}
                  onChange={(e) => {
                    onChangeForm(
                      "type",
                      e.target.checked ? "freelance" : "owner",
                      {
                        company_name: "",
                        company_iban: "",
                        tax_number: "",
                        tax_office: "",
                        address: { city: "", district: "", neighborhood: "" },
                      }
                    );
                  }}
                />
                <Typography>Şirketim yok</Typography>
              </label>
            </FormControl>
          </Stack>
          {/* @ts-ignore */}
          {form.type === "owner" && (
            <>
              <Stack sx={styles.row}>
                <TextField
                  fullWidth
                  label="Şirket Ünvanı"
                  variant="outlined"
                  value={form.company_name}
                  onChange={(e) => onChangeForm("company_name", e.target.value)}
                />
              </Stack>
              <Stack sx={styles.row}>
                <TextField
                  fullWidth
                  label="Vergi Dairesi"
                  variant="outlined"
                  value={form.tax_office}
                  onChange={(e) => onChangeForm("tax_office", e.target.value)}
                />
              </Stack>
              <Stack sx={styles.row}>
                <TextField
                  fullWidth
                  label="Vergi No"
                  variant="outlined"
                  value={form.tax_number}
                  onChange={(e) => onChangeForm("tax_number", e.target.value)}
                />
              </Stack>
              <Stack sx={{ ...styles.row, gap: 1 }}>
                <FormControl sx={styles.formControl}>
                  <InputLabel>İl</InputLabel>
                  <Select
                    label="İl"
                    value={form.address?.city}
                    onChange={(e) => {
                      onChangeForm("address", {
                        city: e.target.value,
                        district: "",
                        neighborhood: "",
                      });
                    }}
                  >
                    {!!citys &&
                      Object.keys(citys).map((key) => (
                        <MenuItem value={key} key={key}>
                          {key}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormControl sx={styles.formControl}>
                  <InputLabel>İlçe</InputLabel>
                  <Select
                    value={form.address?.district}
                    onChange={(e) => {
                      const address = form.address;
                      onChangeForm("address", {
                        ...address,
                        district: e.target.value,
                      });
                    }}
                    label="İlçe"
                    disabled={!form.address?.city}
                  >
                    {computedDistict.map((row: any, index: number) => (
                      <MenuItem key={index} value={row.district}>
                        {`${row.district}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={styles.formControl}>
                  <TextField
                    label="Mahalle"
                    variant="outlined"
                    value={form.address?.neighborhood}
                    onChange={(e) => {
                      const address = form.address;
                      onChangeForm("address", {
                        ...address,
                        neighborhood: e.target.value,
                      });
                    }}
                  />
                </FormControl>
              </Stack>
              <Stack sx={{ ...styles.row, "&>*": { width: "100%" } }}>
                {/* <TextField
                  fullWidth
                  label="Şirket IBAN Numarası"
                  variant="outlined"
                  value={form.company_iban}
                  onChange={(e) => onChangeForm("company_iban", e.target.value)}
                /> */}
                <MaskedInput
                  style={{ width: "100%" }}
                  value={form.company_iban}
                  onChange={(e) => onChangeForm("company_iban", e.target.value)}
                  guide={false}
                  render={(innerRef, props) => (
                    <TextField
                      {...props}
                      inputRef={innerRef}
                      fullWidth
                      placeholder="TR "
                      label="Şirket IBAN Numarası"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  )}
                  mask={Masks.iban}
                />
              </Stack>
            </>
          )}
          {error && (
            <Alert severity="error">
              <span dangerouslySetInnerHTML={{ __html: error }} />
            </Alert>
          )}
          <Button
            disabled={loading}
            type={"submit"}
            variant="contained"
            color="secondary"
          >
            Link Oluştur
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

const ModalItem = ({
  title,
  value,
}: {
  title: string;
  value?: string | number;
}) => {
  return (
    <Stack gap={1} sx={{ flex: 1 }}>
      <Typography
        sx={{
          fontSize: "16px",
          lineHeight: "24px",
          color: "grey.700",
        }}
      >
        {title}
      </Typography>
      {value && (
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "30px",
            color: "gray.900",
            fontWeight: 500,
          }}
        >
          {value}
        </Typography>
      )}
    </Stack>
  );
};

const RefPreview = ({
  reference,
  setRef,
}: {
  reference: IReference;
  setRef: () => void;
}) => {
  console.log(reference);
  return (
    <Stack gap={3}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ p: 3, pb: 0 }}
      >
        <Typography sx={{ fontSize: "24px", lineHeight: "32px" }}>
          Referans Bilgileri
        </Typography>
        <IconButton
          onClick={() => {
            setRef();
          }}
        >
          <Iconify icon="ic:baseline-close" />
        </IconButton>
      </Stack>
      <Stack
        gap={3}
        sx={{
          "&>*": {
            borderBottom: "1px solid",
            borderBottomColor: "divider",
            px: 3,
            pb: 3,
          },
          "&>*:last-child": {
            borderBottom: "0px",
          },
        }}
      >
        <Stack direction="row" gap={1}>
          <ModalItem title="İsim Soyisim" value={reference.name_surname} />
          <ModalItem title="E-mail" value={reference.email} />
          {reference.type === "owner" ? (
            <ModalItem
              title="Şirket Ünvanı"
              value={reference.company_name ?? "-"}
            />
          ) : (
            <Box sx={{ flex: 1 }} />
          )}
        </Stack>
        {reference.type === "owner" && (
          <Stack direction="row" gap={1}>
            <ModalItem
              title="Vergi Dairesi"
              value={reference.tax_office ?? "-"}
            />
            <ModalItem
              title="Vergi Numarası"
              value={reference.tax_number ?? "-"}
            />
          </Stack>
        )}
        <Stack direction="row" gap={1}>
          <ModalItem title="Telefon Numarası" value={reference.phone} />
          <ModalItem title="TC Kimlik Numarası" value={reference.id_number} />
          <ModalItem
            title="Referans Sayısı"
            value={reference.report?.reduce((total, item) => {
              return total + item.total;
            }, 0)}
          />
          <ModalItem
            title="Onaylanan"
            value={reference.report?.reduce((total, item) => {
              return total + item.confirmed;
            }, 0)}
          />
        </Stack>
        <Stack direction="row" gap={1}>
          <ModalItem
            title="Bayi Referans Sayısı"
            value={reference.services?.length ?? "-"}
          />
        </Stack>

        <Stack direction="row" gap={1}>
          <ModalItem title="IBAN Numarasi" value={reference.iban} />
          {reference.type === "owner" ? (
            <ModalItem
              title="Şirket IBAN Numarasi"
              value={reference.company_iban ?? "-"}
            />
          ) : (
            <Box sx={{ flex: 1 }} />
          )}
        </Stack>
        <Stack direction="row" gap={1}>
          <ModalItem title="İl" value={reference.address?.city ?? "-"} />
          <ModalItem title="İlçe" value={reference.address?.district ?? "-"} />
          <ModalItem
            title="Mahalle"
            value={reference.address?.neighborhood ?? "-"}
          />
        </Stack>
        {(reference.report ?? []).map((report, index) => (
          <Stack gap={2} key={index}>
            <ModalItem
              title={format(
                parse(report.date, "MM-yyyy", new Date()),
                "MMMM yyyy",
                { locale: tr }
              )}
            />
            <Stack direction="row">
              <ModalItem title="Referans Sayısı" value={report.total} />
              <ModalItem title="Onaylanan" value={report.confirmed} />
              <Box sx={{ flex: 1 }} />
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

const TableLableStyle = {
  fontSize: "16px",
  lineHeight: "24px",
  fontWeight: 500,
  color: "#3D3D3D",
};

const escapeCSV = (value: string | number) => {
  if (value == null) return "";
  let stringValue = String(value);
  if (
    stringValue.includes('"') ||
    stringValue.includes(",") ||
    stringValue.includes("\n")
  ) {
    stringValue = `"${stringValue.replace(/"/g, '""')}"`;
  }
  return stringValue;
};

export const RefListSection = () => {
  const [searchParams] = useSearchParams();

  const [debouncedSearch, search, setSearch] = useDebouncedValue("");
  const [copySuccess, setCopySuccess] = useState("");
  const [showCreate, setShowCreate] = useState(false);

  const [selectedRef, setSelectedRef] = useState<IReference | null>(null);

  const params = new URLSearchParams();
  if (debouncedSearch) params.append("search", debouncedSearch);

  const { data, isLoading } = useSWR<IReference[]>(
    `/reference/list?${params.toString()}`,
    fetcher
  );

  useEffect(() => {
    if (searchParams.get("search")) {
      setSearch(searchParams.get("search") ?? "");
    }
  }, [searchParams]);

  const handleCopyClick = (ref: string) => {
    const url = `${process.env.REACT_APP_FRONTEND_URL}/?ref=${ref}`;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        setCopySuccess(ref);
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        console.error("Clipboard copy failed:", err);
        setCopySuccess("");
      });
  };

  const exportToExcel = () => {
    // Header tanımlaması
    const header = [
      "ID",
      "Referans Kodu",
      "Ad Soyad",
      "E-mail",
      "Telefon",
      "IBAN",
      "Şehir",
      "İlçe",
      "Cadde",
      "Şirket Adı",
      "Şirket IBAN",
      "Vergi Dairesi",
      "Vergi Numarası",
      "Oluşturulma Tarihi",
      "Toplam Montaj Referans",
      "Onaylanan Montaj Referans",
      "Bayi Referans",
      "Raporlar",
    ];

    // Data hazırlama
    const processedData = (data ?? []).map((item) => {
      const row = [
        item._id,
        item.ref ?? "",
        item.name_surname,
        item.email,
        item.phone ?? "",
        item.iban ?? "",
        item.address?.city ?? "",
        item.address?.district ?? "",
        item.address?.street ?? "",
        item.company_name ?? "",
        item.company_iban ?? "",
        item.tax_office ?? "",
        item.tax_number ?? "",
        format(item.createdAt, "dd MMM yyyy HH:mm", { locale: tr }),
        item.report?.reduce((total, item) => total + item.total, 0) ?? "-",
        item.report?.reduce((total, item) => total + item.confirmed, 0) ?? "-",
        item.services?.length ?? "-",
        // Raporlar kısmı
        (item.report ?? [])
          .map((rep) => {
            const dateStr = format(
              parse(rep.date, "MM-yyyy", new Date()),
              "MMMM yyyy",
              { locale: tr }
            );
            return `${dateStr} ${rep.confirmed}/${rep.total}`;
          })
          .join(", "),
      ];
      return row;
    });

    // Excel sayfasını oluştur
    const worksheet = XLSX.utils.aoa_to_sheet([header, ...processedData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Excel dosyasını oluştur ve indir
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, `referanslar-${new Date().valueOf()}.xlsx`);
  };

  const exportCSV = () => {
    exportToExcel();
    return;

    const header = [
      "ID",
      "Referans Kodu",
      "Ad Soyad",
      "E-mail",
      "Telefon",
      "IBAN",
      "Şehir",
      "İlçe",
      "Cadde",
      "Şirket Adı",
      "Şirket IBAN",
      "Vergi Dairesi",
      "Vergi Numarası",
      "Oluşturulma Tarihi",
      "Toplam Referans",
      "Onaylanan Referans",
    ];

    const csvRows = [
      header.join(","),
      ...(data ?? []).map((item) => {
        const row: any = [
          escapeCSV(item._id),
          escapeCSV(item.ref ?? ""),
          escapeCSV(item.name_surname),
          escapeCSV(item.email),
          escapeCSV(item.phone ?? ""),
          escapeCSV(item.iban ?? ""),
          escapeCSV(item.address?.city ?? ""),
          escapeCSV(item.address?.district ?? ""),
          escapeCSV(item.address?.street ?? ""),
          escapeCSV(item.company_iban ?? ""),
          escapeCSV(item.company_name ?? ""),
          escapeCSV(item.tax_office ?? ""),
          escapeCSV(item.tax_number ?? ""),
          escapeCSV(
            format(item.createdAt, "dd MMM yyyy HH:mm", { locale: tr })
          ),
          escapeCSV(
            item.report?.reduce((total, item) => {
              return total + item.total;
            }, 0) ?? "-"
          ),
          escapeCSV(
            item.report?.reduce((total, item) => {
              return total + item.confirmed;
            }, 0) ?? "-"
          ),

          ...(item.report ?? []).map((rep) => {
            const d = format(
              parse(rep.date, "MM-yyyy", new Date()),
              "MMMM yyyy",
              { locale: tr }
            );
            return escapeCSV(`${d} ${rep.confirmed}/${rep.total}`);
          }),
        ];

        return row.join(",");
      }),
    ];

    const csvString = csvRows.join("\n");

    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8" });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `referanslar-${new Date().valueOf()}.csv`;

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <Dialog
        open={showCreate}
        maxWidth="sm"
        fullWidth
        onClose={() => {
          setShowCreate(false);
        }}
      >
        <Stack gap={2}>
          <Stack
            direction="row"
            justifyContent="space-between"
            p={3}
            sx={{ borderBottom: "1px solid", borderBottomColor: "divider" }}
            alignItems="center"
          >
            <Typography sx={{ fontSize: "24px", fontWeight: 500 }}>
              Referans linki oluşturma
            </Typography>
            <Stack direction="row" gap={1}>
              <IconButton
                onClick={() => {
                  setShowCreate(false);
                }}
              >
                <Iconify icon="ic:baseline-close" />
              </IconButton>
            </Stack>
          </Stack>
          <RefForm
            mutateString={`/reference/list?${params.toString()}`}
            onClose={() => {
              setShowCreate(false);
            }}
          />
        </Stack>
      </Dialog>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={!!selectedRef}
        onClose={() => {
          setSelectedRef(null);
        }}
      >
        {selectedRef && (
          <RefPreview
            reference={selectedRef}
            setRef={() => {
              setSelectedRef(null);
            }}
          />
        )}
      </Dialog>
      <Stack gap={2}>
        <Stack
          gap={2}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TextField
            placeholder="Ara"
            sx={{ maxWidth: 350 }}
            size="small"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
          <Stack direction="row" gap={1}>
            <Button
              onClick={() => {
                setShowCreate(true);
              }}
              variant="contained"
              color="secondary"
              size="small"
            >
              Referans Linki Oluştur
            </Button>
          </Stack>
        </Stack>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow sx={{ backgroundColor: "grey.200" }}>
                <TableCell>
                  <Typography sx={TableLableStyle}>Tarih</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>İsim</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>Referans Linki</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    Referans Kişi Sayısı
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={TableLableStyle}>
                    Bayi Referans Sayısı
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {data?.length && (
                    <ButtonBase
                      onClick={() => {
                        exportCSV();
                      }}
                      sx={{
                        px: 1,
                        borderRadius: 2,
                        fontFamily: "inherit",
                        gap: 1,
                      }}
                    >
                      Tümünü İndir
                      <Iconify icon="vscode-icons:file-type-excel" />
                    </ButtonBase>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!data?.length && isLoading && (
                <>
                  {Array(5)
                    .fill("")
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                        <TableCell>
                          <Skeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              )}
              {data?.map((item) => (
                <TableRow key={item._id}>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {fDateTime(item.createdAt)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {item.name_surname}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Typography sx={TableLableStyle}>
                        <span>{`${process.env.REACT_APP_FRONTEND_URL}/?ref=${item.ref}`}</span>
                      </Typography>
                      <IconButton
                        onClick={() => handleCopyClick(item.ref)}
                        color="secondary"
                        size="small"
                      >
                        <Iconify icon="ic:round-content-copy" />
                      </IconButton>
                    </Stack>
                    {copySuccess === item.ref && (
                      <Typography
                        variant="body2"
                        color="success.main"
                        sx={{
                          position: "absolute",
                          backgroundColor: "#fff",
                          border: "1px solid",
                          borderColor: "divider",
                          px: 2,
                          py: 1,
                          borderRadius: 2,
                        }}
                      >
                        Link kopyalandı!
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {item.services?.length ?? "-"}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={TableLableStyle}>
                      {item.report?.reduce((total, item) => {
                        return total + item.total;
                      }, 0)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Link
                      to="#"
                      onClick={() => {
                        setSelectedRef({ ...item });
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        justifyContent="end"
                      >
                        Daha fazlası
                        <Iconify icon="solar:alt-arrow-right-line-duotone" />
                      </Stack>
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </>
  );
};
