import { AdminLayout } from "../../../components";
import { ServiceListSection } from "../../../section";

export const ServiceListPage = () => {
  return (
    <AdminLayout>
      <ServiceListSection />
    </AdminLayout>
  );
};
