export const IconSuccess = () => {
  return (
    <svg
      width="121"
      height="120"
      viewBox="0 0 121 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="120" height="120" rx="60" fill="#E5FFEA" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M60.4987 85.6666C74.674 85.6666 86.1654 74.1752 86.1654 59.9999C86.1654 45.8246 74.674 34.3333 60.4987 34.3333C46.3234 34.3333 34.832 45.8246 34.832 59.9999C34.832 74.1752 46.3234 85.6666 60.4987 85.6666ZM70.7477 53.5656C71.0601 53.2532 71.0601 52.7467 70.7477 52.4342C70.4353 52.1218 69.9288 52.1218 69.6163 52.4342L56.2061 65.8445L51.4041 60.6255C51.1049 60.3003 50.5988 60.2793 50.2737 60.5784C49.9486 60.8776 49.9275 61.3837 50.2266 61.7088L55.5933 67.5416C55.7409 67.702 55.9475 67.7952 56.1654 67.7997C56.3833 67.8043 56.5936 67.7197 56.7477 67.5656L70.7477 53.5656Z"
        fill="#039855"
      />
    </svg>
  );
};
