import useSWR from "swr";
import { AdminLayout } from "../../../components";
import { fetcher } from "../../../utils";
import { RefListSection } from "../../../section";

export const RefListPage = () => {
  return (
    <AdminLayout>
      <RefListSection />
    </AdminLayout>
  );
};
