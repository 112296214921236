/* eslint-disable jsx-a11y/alt-text */
// src/components/MontajBasvuruForm.tsx

import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  Checkbox,
  Button,
  Radio,
  Container,
  useTheme,
  useMediaQuery,
  Stack,
  Divider,
  Tooltip,
  Alert,
} from "@mui/material";
import {
  Footer,
  Header,
  Iconify,
  IconSuccess,
  MontageTimePicker,
} from "../components";
import useSWR from "swr";
import { axiosInstance, fetcher } from "../utils";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { format } from "date-fns";
import { tr } from "date-fns/locale";
import { useStoredRef } from "../hooks";
import { ServiceVehicleTypes } from "../data";
import { enqueueSnackbar } from "notistack";
import MaskedInput from "react-text-mask";

interface InputData {
  select: string;
  text: string;
}

const ShowTooltip = ({
  children,
  show,
  title,
}: {
  children: any;
  show: boolean;
  title: string;
}) => {
  if (!show) {
    return children;
  }

  return (
    <Tooltip arrow title={title} color="error">
      <span style={{ opacity: 0.5 }}>{children}</span>
    </Tooltip>
  );
};

const MontajBasvuruForm = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const { reference, clearReference } = useStoredRef();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>("");
  const [success, setSuccess] = useState(false);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPosition, setSelectedPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [savedPosition, setSavedPosition] =
    useState<google.maps.LatLngLiteral | null>(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
  });

  const [mapCenter, setMapCenter] = useState<google.maps.LatLngLiteral>({
    lat: 40.98691,
    lng: 28.76995,
  });

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    map.setZoom(10);
    map.setCenter(mapCenter);

    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const [vehicleForm, setVehicleForm] = useState({
    quantity: 1,
    vehicle_type: "",
  });

  const [form, setForm] = useState<any>({
    company_name: "",
    authorized_person_name: "",
    authorized_person_email: "",
    authorized_person_phone: "",
    city: "",
    district: "",
    // street: "",
    vehicles: [],
    type: "service",
    mint_code: false,
    application_date: [],
    lat: 0,
    lng: 0,
    service_provider: "",
    reference: reference ?? "",
  });

  useEffect(() => {
    setForm({
      ...form,
      reference: reference ?? "",
    });
  }, [reference]);

  const { data: services } = useSWR("/service-provider/application", fetcher);
  const { data: citys } = useSWR("/montage/city", fetcher);

  const computedDistict = form.city && citys ? citys[form.city] : [];
  const selectedDistrict =
    computedDistict?.find((row: any) => row?.district === form?.district) ??
    null;
  const totalVehicles =
    form.vehicles?.reduce((total: number, row: any) => {
      total += row.quantity;
      return total;
    }, 0) ?? 0;

  const styles = {
    row: {
      flexDirection: "row",
      gap: 3,
      ...(isMobile ? { flexDirection: "column" } : {}),
    },
    formControl: {
      flex: 1,
      ...(isMobile
        ? { width: "100% !important", flex: 1, minWidth: "100%" }
        : {}),
    },
    nativeLabel: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    divider: {},
    vehicle_input: {
      maxWidth: "180px",
      ...(isMobile ? { maxWidth: "initial", width: "100%" } : {}),
    },
  };

  const onChangeForm = (
    key: string,
    value: any,
    key2?: any,
    value2?: any,
    extend?: any
  ) => {
    const f: any = { ...form };
    f[key] = value;
    if (key2 || value2) {
      f[key2] = value2;
    }
    let ff = { ...f };
    if (extend) {
      ff = { ...f, ...extend };
    }
    setForm(ff);
  };

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    if (event.latLng) {
      setSelectedPosition({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      });
      onChangeForm("lat", event.latLng.lat(), "lng", event.latLng.lng());
    }
  };

  const onSubmit = async () => {
    if (!form.city || !form.district) {
      setError("Şehir yada ilçe seçiniz.");
      enqueueSnackbar("Şehir yada ilçe seçiniz.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }
    if (form.type === "mobile" && (!form.lat || !form.lng)) {
      setError("Lütfen haritan konum seçiniz.");
      enqueueSnackbar("Lütfen haritan konum seçiniz.", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
      return;
    }
    if (form.type === "service") {
      if (totalVehicles === 0) {
        setError("Lütfen araç girişi yapınız.");
        enqueueSnackbar("Lütfen araç girişi yapınız.", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        return;
      }
      if (form.application_date.length === 0 && totalVehicles <= 15) {
        setError("Lütfen randevu tarihini belirleyin.");
        enqueueSnackbar("Lütfen randevu tarihini belirleyin.", {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
        return;
      }
    }

    setError("");

    const timesWithUTC: { date: string; time: string }[] = [];

    const times = form.application_date;
    times.forEach((row: { time: string; date: string }) => {
      const utcDate = new Date();

      const { time, date } = row; // yyyy.MM.dd hh:mm

      const dateParts = date.split(".");
      const timeParts = time.split(":");

      utcDate.setUTCHours(parseInt(timeParts[0]));
      utcDate.setUTCMinutes(parseInt(timeParts[1]));

      utcDate.setUTCDate(parseInt(dateParts[2]));
      utcDate.setUTCMonth(parseInt(dateParts[1]) - 1);
      utcDate.setUTCFullYear(parseInt(dateParts[0]));
      utcDate.setUTCSeconds(0);
      utcDate.setUTCMilliseconds(0);

      console.log(time, date, "->", utcDate.toISOString());

      timesWithUTC.push({
        date: utcDate.toISOString(),
        time: time,
      });
    });

    const body = { ...form };

    if (!body.service_provider) {
      delete body.service_provider;
    }

    body.application_date = timesWithUTC.map((row) => row.date) ?? [];

    setLoading(true);
    try {
      const response = await axiosInstance.post(
        `${process.env.REACT_APP_BACKEND_URL}/montage/application`,
        body
      );

      if (response.status === 201 || response.status === 200) {
        setSuccess(true);
        clearReference();
      } else {
        const { errors } = response.data;

        const errorString = errors
          ? errors
              ?.map((err: any) => (err.messages ?? []).join("\n"))
              .join("\n")
          : "Beklenmedik bir hata oluştu.";

        setError(
          "Başvuru gönderilirken beklenmedik bir hata oluştu.<br/>" +
            errorString
        );
      }
    } catch (error: any) {
      const { data } = error.response;
      const { errors } = data;
      const errorString = errors
        ? errors?.map((err: any) => (err.messages ?? []).join("\n")).join("\n")
        : "Beklenmedik bir hata oluştu.";

      setError(
        "Başvuru gönderilirken beklenmedik bir hata oluştu.<br/>" + errorString
      );
    }
    setLoading(false);
  };

  const filteredServices =
    form.type === "service"
      ? (services ?? []).filter(
          (service: { vehicle_types_for_service: string[] }) => {
            const vehicles = form.vehicles.map(
              (v: { vehicle_type: string }) => v.vehicle_type
            );

            const findVehicle = vehicles.some(
              (v: string) => !service.vehicle_types_for_service.includes(v)
            );

            return !findVehicle;
          }
        )
      : services ?? [];

  return (
    <Container sx={{ mt: 2 }}>
      <Header />

      <Box
        sx={{
          backgroundColor: "#002B32",
          borderBottomLeftRadius: "24px",
          borderBottomRightRadius: "24px",
          pb: 4,
        }}
      >
        <Typography
          component={"h1"}
          sx={{
            fontSize: "60px",
            lineHeight: "90px",
            textAlign: "center",
            color: "#fff",
            mb: "48px",
            ...(isMobile
              ? { fontSize: "34px", lineHeight: "46px", mb: "24px" }
              : {}),
          }}
        >
          Montaj Başvurusu
        </Typography>
        <Box sx={{ mx: 4, backgroundColor: "#fff", borderRadius: 4, p: 3 }}>
          {success && (
            <Stack alignItems="center" gap={3} sx={{ py: 4 }}>
              <IconSuccess />
              <Typography
                sx={{
                  fontSize: "32px",
                  lineHeight: "40px",
                  textAlign: "center",
                }}
              >
                {form.type === "mobile"
                  ? "Mobil Randevu Talebiniz Alınmıştır"
                  : "Randevunuz Başarıyla Oluşturulmuştur"}
              </Typography>
              <Typography
                sx={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  textAlign: "center",
                }}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      form.type === "mobile"
                        ? "Randevunuz en kısa sürede Müşteri Deneyimi ekibimiz tarafından değerlendirilecek<br/> Randevu bilgileri tarafınıza e-mail yolu ile iletilecektir.<br/>Herhangi bir değişiklik ya da ek bilgi için bizimle iletişime geçebilirsiniz."
                        : "Randevu saatinden <b>en az 10 dakika öncesinde</b> montaj noktasında olmanızı rica ederiz.<br/>Herhangi bir değişiklik ya da ek bilgi için bizimle iletişime geçebilirsiniz.",
                  }}
                />
              </Typography>
            </Stack>
          )}
          {!success && (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
            >
              <Stack gap={3}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Firma Bilgileri
                  </Typography>
                </Stack>
                <Stack direction="row" sx={styles.row}>
                  <FormControl sx={styles.formControl}>
                    <TextField
                      label="Firma İsmi"
                      value={form.company_name}
                      onChange={(e) => {
                        onChangeForm("company_name", e.target.value);
                      }}
                      required
                    />
                  </FormControl>
                  <FormControl sx={styles.formControl}>
                    <TextField
                      value={form.authorized_person_name}
                      onChange={(e) => {
                        onChangeForm("authorized_person_name", e.target.value);
                      }}
                      label="Yetkili İsim Soyisim"
                      required
                    />
                  </FormControl>
                </Stack>
                <Stack direction="row" sx={styles.row}>
                  <FormControl sx={styles.formControl}>
                    <TextField
                      type="email"
                      value={form.authorized_person_email}
                      onChange={(e) => {
                        onChangeForm("authorized_person_email", e.target.value);
                      }}
                      label="Yetkili Mail Adresi"
                      required
                    />
                  </FormControl>
                  <FormControl sx={styles.formControl}>
                    {/* <TextField
                      value={form.authorized_person_phone}
                      onChange={(e) => {
                        onChangeForm("authorized_person_phone", e.target.value);
                      }}
                      label="Yetkili Telefon Numarası"
                      required
                    /> */}
                    <MaskedInput
                      required
                      value={form.authorized_person_phone}
                      onChange={(e) => {
                        onChangeForm("authorized_person_phone", e.target.value);
                      }}
                      guide={false}
                      render={(innerRef, props) => (
                        <TextField
                          {...props}
                          inputRef={innerRef}
                          label="Yetkili Telefon Numarası"
                          // placeholder="+90 "
                          slotProps={{
                            inputLabel: {
                              shrink: true,
                            },
                          }}
                        />
                      )}
                      mask={[
                        "0",
                        " ",
                        "(",
                        /\d/,
                        /\d/,
                        /\d/,
                        ")",
                        " ",
                        /\d/,
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                        " ",
                        /\d/,
                        /\d/,
                      ]}
                    />
                  </FormControl>
                </Stack>
                <Divider sx={styles.divider} />
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Montaj Hizmeti Talep Edilen Adres Bilgisi
                  </Typography>
                </Stack>
                <Stack sx={styles.row}>
                  <FormControl sx={styles.formControl}>
                    <InputLabel>İl</InputLabel>
                    <Select
                      label="İl"
                      value={form.city}
                      onChange={(e) => {
                        onChangeForm("city", e.target.value);
                      }}
                    >
                      {!!citys &&
                        Object.keys(citys).map((key) => (
                          <MenuItem value={key} key={key}>
                            {key}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={styles.formControl}>
                    <InputLabel>İlçe</InputLabel>
                    <Select
                      value={form.district}
                      onChange={(e) => {
                        onChangeForm(
                          "district",
                          e.target.value,
                          "type",
                          "service"
                        );
                      }}
                      label="İlçe"
                      disabled={!form.city}
                    >
                      {computedDistict.map((row: any, index: number) => (
                        <MenuItem key={index} value={row.district}>
                          {`${row.district}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Divider sx={styles.divider} />
                <Stack>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Montaj Hizmeti Alınacak Araç Bilgisi
                  </Typography>
                </Stack>
                <Stack gap={1}>
                  <div>
                    <Stack direction="row" gap={2} flexWrap="wrap">
                      <TextField
                        sx={styles.vehicle_input}
                        disabled={!selectedDistrict}
                        value={vehicleForm.quantity}
                        onChange={(e) => {
                          setVehicleForm((f) => ({
                            ...f,
                            quantity: parseInt(e.target.value),
                          }));
                        }}
                        type={"number"}
                        label={"Adet"}
                      />
                      <FormControl
                        sx={{
                          width: 180,
                          ...styles.formControl,
                          flex: "initial",
                        }}
                      >
                        <InputLabel>Araç Tipi</InputLabel>
                        <Select
                          disabled={!selectedDistrict}
                          value={vehicleForm.vehicle_type}
                          onChange={(e) => {
                            setVehicleForm((f) => ({
                              ...f,
                              vehicle_type: e.target.value,
                            }));
                          }}
                          label={"Araç Tipi"}
                        >
                          {ServiceVehicleTypes.map((type) => {
                            return <MenuItem value={type}>{type}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                      <Button
                        disabled={
                          !selectedDistrict ||
                          !vehicleForm.quantity ||
                          !vehicleForm.vehicle_type.length ||
                          vehicleForm.quantity <= 0
                        }
                        variant="contained"
                        color="secondary"
                        startIcon={<Iconify icon="ic:round-add" />}
                        onClick={() => {
                          const f = { ...form };
                          const vf = { ...vehicleForm };

                          if (parseInt((vf?.quantity ?? "").toString()) <= 0)
                            return;

                          f.vehicles.push(vf as any);
                          f.application_date = [];
                          f.service_provider = "";
                          setForm(f);

                          setVehicleForm({ quantity: 1, vehicle_type: "" });
                        }}
                      >
                        Taşıt Ekle
                      </Button>
                    </Stack>

                    {!!(form.vehicles ?? []).length && (
                      <Stack gap={1} direction="row" mt={1} flexWrap="wrap">
                        {(form.vehicles ?? []).map(
                          (row: any, index: number) => (
                            <Stack
                              sx={{
                                backgroundColor: "#ecfbf2",
                                px: 2,
                                py: 1,
                                borderRadius: 2,
                              }}
                              key={index}
                              direction="row"
                              alignItems="center"
                              gap={1}
                            >
                              <Typography>
                                {row.quantity} {row.vehicle_type}
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  const f = { ...form };
                                  if (
                                    totalVehicles - row.quantity <
                                    selectedDistrict.count
                                  ) {
                                    f.type = "service";
                                    f.service_provider = "";
                                    f.application_date = [];
                                    f.lat = 0;
                                    f.lng = 0;
                                  }
                                  f.vehicles.splice(index, 1);
                                  setForm(f);
                                }}
                              >
                                <Iconify icon="ic:round-close" />
                              </IconButton>
                            </Stack>
                          )
                        )}
                      </Stack>
                    )}
                  </div>
                </Stack>
                <Stack
                  sx={{
                    ...styles.row,
                  }}
                >
                  <ShowTooltip
                    show={
                      selectedDistrict && totalVehicles < selectedDistrict.count
                    }
                    title="Belirtilen araç sayısı mobil montaj başvurusu için uygun değil."
                  >
                    <FormControl>
                      <label
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Radio
                          disabled={
                            !selectedDistrict ||
                            totalVehicles < selectedDistrict.count
                          }
                          checked={form.type === "mobile"}
                          onChange={(e) => {
                            onChangeForm(
                              "type",
                              e.target.checked ? "mobile" : "service",
                              null,
                              null,
                              { service_provider: "", application_date: [] }
                            );
                          }}
                        />
                        <Typography>Mobil Montaj İstiyorum</Typography>
                      </label>
                    </FormControl>
                  </ShowTooltip>

                  <FormControl>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        disabled={!selectedDistrict}
                        checked={form.type === "service"}
                        onChange={(e) => {
                          onChangeForm(
                            "type",
                            e.target.checked ? "service" : "mobile",
                            null,
                            null,
                            { lat: 0, lng: 0, application_date: [] }
                          );
                        }}
                      />
                      <Typography>Servis Montaj İstiyorum</Typography>
                    </label>
                  </FormControl>
                </Stack>
                <Divider sx={styles.divider} />
                <Stack
                  direction={isMobile ? "column" : "row"}
                  alignItems={isMobile ? "" : "center"}
                  gap={1}
                  justifyContent="space-between"
                >
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "30px",
                      fontWeight: 500,
                    }}
                  >
                    Montaj Hizmeti Alınacak Adres Bilgisi
                  </Typography>
                  {form.type === "service" && !!totalVehicles && (
                    <Typography sx={{ fontSize: isMobile ? "12px" : "14px" }}>
                      Seçilen araç tiplerine göre, toplam{" "}
                      <b>{services?.length ?? "-"}</b> bayiden{" "}
                      <b>{filteredServices?.length ?? "-"}</b> adet bayi hizmet
                      verebilmektedir.
                    </Typography>
                  )}
                </Stack>
                {form.type === "mobile" && isLoaded && (
                  <Stack sx={{ borderRadius: 4, overflow: "hidden" }}>
                    <GoogleMap
                      mapContainerStyle={{ height: 500 }}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      onClick={handleMapClick}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      {selectedPosition && (
                        <Marker position={selectedPosition} />
                      )}
                    </GoogleMap>
                  </Stack>
                )}
                {form.type === "service" && (
                  <>
                    <Stack sx={styles.row}>
                      <FormControl sx={styles.formControl}>
                        <InputLabel>Tüm Bayiiler</InputLabel>
                        <Select
                          value={form.service_provider}
                          onChange={(e) => {
                            onChangeForm(
                              "service_provider",
                              e.target.value,
                              "application_date",
                              []
                            );
                          }}
                          disabled={!selectedDistrict}
                          label="Tüm Bayiiler"
                        >
                          {(services ?? []).map((service: any) => (
                            <MenuItem
                              disabled={
                                filteredServices.findIndex(
                                  (f: any) => f._id === service._id
                                ) < 0
                              }
                              key={service._id}
                              value={service._id}
                            >
                              {`${
                                service.address
                                  ? `${
                                      service.address.city
                                        ? service.address.city
                                        : ""
                                    } - ${
                                      service.address.district
                                        ? " " + service.address.district
                                        : ""
                                    }`
                                  : ""
                              } - ${service.offical_title} - ${
                                service.address.fullAddress
                                  ? " " + service.address.fullAddress
                                  : ""
                              }`.trim()}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Stack>
                    <MontageTimePicker
                      count={totalVehicles}
                      title={
                        services?.find(
                          (s: any) => s._id === form?.service_provider
                        )?.offical_title ?? ""
                      }
                      onSave={(times) => {
                        const f = { ...form };
                        f.application_date = times;
                        setForm(f);
                      }}
                      onClose={() => {}}
                      service={form.service_provider}
                    />
                    {!!form.application_date.length && (
                      <Stack sx={{ ...styles.row, flexWrap: "wrap" }}>
                        {form.application_date.map(
                          (
                            d: { date: string; time: string },
                            index: number
                          ) => {
                            console.log(d, d.date);
                            const [year, month, day] = d.date.split(".");
                            const f = new Date(`${year}-${month}-${day}`);
                            const dateString = format(f, "dd MMMM yyyy", {
                              locale: tr,
                            });
                            return (
                              <Box
                                sx={{ overflow: "hidden", borderRadius: 2 }}
                                key={index}
                              >
                                <Box
                                  sx={{
                                    backgroundColor: "#ecfbf2",
                                    borderRadius: 2,
                                    px: 2,
                                    py: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 2,
                                    fontSize: "14px",
                                  }}
                                >
                                  {`${dateString} ${d.time}`}
                                  {/* <Iconify icon="ic:round-close" /> */}
                                </Box>
                              </Box>
                            );
                          }
                        )}
                      </Stack>
                    )}
                  </>
                )}
                <Stack sx={styles.row}>
                  <FormControl>
                    <label
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Radio
                        checked={form.mint_code}
                        onChange={(e) => {
                          onChangeForm(
                            "mint_code",
                            e.target.checked ? true : false
                          );
                        }}
                      />
                      <Typography>Darphane aktivasyon kodu aldım.</Typography>
                    </label>
                  </FormControl>
                  <FormControl>
                    <label style={styles.nativeLabel as any}>
                      <Radio
                        checked={!form.mint_code}
                        onChange={(e) => {
                          onChangeForm(
                            "mint_code",
                            e.target.checked ? false : true
                          );
                        }}
                      />
                      <Typography>Darphane aktivasyon kodu almadım.</Typography>
                    </label>
                  </FormControl>
                </Stack>
                <Divider sx={styles.divider} />
                <Stack sx={styles.row}>
                  <Stack>
                    <label style={styles.nativeLabel as any}>
                      <Checkbox required />
                      <Typography>
                        Yukardaki şartları sağladığımı, ilgili bilgilerin doğru
                        olduğunu ve başvurumun kabul edilmesi halinde bunu
                        tevsik eden evrakları temin edeceğimi beyan ederim.
                      </Typography>
                    </label>
                    <label style={styles.nativeLabel as any}>
                      <Checkbox required />
                      <Typography>
                        KVKK yürürlüğündeki{" "}
                        <span
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            window.open("/gizlilik", "_blank");
                          }}
                        >
                          metni
                        </span>{" "}
                        okudum ve onaylıyorum.
                      </Typography>
                    </label>
                  </Stack>
                </Stack>
                {error && (
                  <Alert severity="error">
                    <span dangerouslySetInnerHTML={{ __html: error }}></span>
                  </Alert>
                )}
                <Stack>
                  <Box>
                    <Button
                      type="submit"
                      disabled={loading}
                      variant="contained"
                      color="secondary"
                    >
                      Randevu Başvurusunda Bulun
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </form>
          )}
        </Box>
      </Box>
      <Footer />
    </Container>
  );
};

export default MontajBasvuruForm;
