import { AdminLayout } from "../../../components";
import { ServiceAppListSection } from "../../../section";

export const ServiceApplicationList = () => {
  return (
    <AdminLayout>
      <ServiceAppListSection />
    </AdminLayout>
  );
};
