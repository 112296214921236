import useSWR from "swr";
import { useAuth } from "../context";
import { IServiceProvider } from "../interface";
import { fetcher } from "../utils";

export const useMe = () => {
  const { user } = useAuth();

  const { data: response } = useSWR<{
    data: IServiceProvider;
  }>(user ? `/api/auth/me` : null, fetcher, { keepPreviousData: false });

  const { data } = response ?? {};

  return { me: data };
};
