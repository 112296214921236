import {
  Alert,
  Box,
  Button,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import useSWR from "swr";
import { fetcher } from "../../utils";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { PATHS } from "../../routes";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const InvitationPasswordSection = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showAgainPassword, setShowPasswordAgain] = useState(false);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { token } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { data, error: inviteError } = useSWR(
    `/service-provider/invitation/${token}`,
    fetcher
  );

  if (!token || inviteError) {
    return <Navigate to="/" />;
  }

  if (!data) {
    return <Box>Loading...</Box>;
  }

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setError("");
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const password = formData.get("password") as string;
    const passwordAgain = formData.get("password-again") as string;

    if (password !== passwordAgain) {
      setError("Şifreler uyuşmuyor");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/service-provider/invitation/${token}/password`,
        {
          password,
        }
      );
      if (response.status === 200) {
        enqueueSnackbar("Şifre işlemi tamamlandı", { variant: "success" });
        navigate(PATHS.admin.login);
      }
    } catch (error: any) {
      console.log(error);
      let errorString = "Beklenmedik bir hata oluştu.";
      const { data } = error.response ?? {};
      if (data) {
        const { errors } = data;
        if (errors) {
          errorString = errors
            ? errors?.join("\n")
            : "Beklenmedik bir hata oluştu.";
          console.log(errors);
        }
      }
      setError(errorString);
    }
    setLoading(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: "secondary.main",
        p: 2,
        borderBottomLeftRadius: "24px",
        borderBottomRightRadius: "24px",
      }}
    >
      <Typography
        sx={{
          textAlign: "center",
          fontSize: isMobile ? "24px" : "60px",
          lineHeight: isMobile ? "38px" : "72px",
          color: "#fff",
          mb: 4,
        }}
      >
        Üye Ol
      </Typography>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#fff",
          borderRadius: "12px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <form
          onSubmit={onSubmit}
          style={{
            width: "100%",
            maxWidth: "542px",
            padding: 24,
            border: "1px solid",
            borderColor: theme.palette.divider,
            borderRadius: "12px",
          }}
        >
          <Stack gap={3}>
            <Typography
              sx={{
                fontSize: isMobile ? "18px" : "24px",
                lineHeight: isMobile ? "24px" : "32px",
              }}
            >
              Üyelik işlemini tamamlamak için bir şifre oluşturmanız
              gerekmektedir.
            </Typography>
            <FormControl>
              <TextField
                label="Email"
                value={data.email}
                disabled
                name={"email"}
              />
            </FormControl>
            <FormControl>
              <TextField
                required
                label="Şifre"
                type={showPassword ? "text" : "password"}
                name={"password"}
                slotProps={{
                  input: {
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setShowPassword((p) => !p);
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  },
                }}
              />
            </FormControl>
            <FormControl>
              <TextField
                required
                label="Şifre tekrarla"
                type={showAgainPassword ? "text" : "password"}
                name={"password-again"}
                slotProps={{
                  input: {
                    endAdornment: (
                      <IconButton
                        onClick={() => {
                          setShowPasswordAgain((p) => !p);
                        }}
                      >
                        {showAgainPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  },
                }}
              />
            </FormControl>
            {error && (
              <Alert severity="error">
                <span dangerouslySetInnerHTML={{ __html: error }} />
              </Alert>
            )}
            <Button
              type="submit"
              disabled={loading}
              variant="contained"
              color="secondary"
            >
              Üyeliği Tamamla
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};
