import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";

export const Footer = () => {
  return (
    <Box
      mt={2}
      py={{ xs: 3, sm: 5 }}
      px={{ xs: 2, sm: 4, md: 8, lg: 12 }}
      sx={{
        backgroundColor: "#002B32",
        borderRadius: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", md: "center" },
          gap: { xs: 4, md: 0 },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: 4, sm: 8 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            <Typography
              onClick={() => {
                window.location.href = "/hakkimizda";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Hakkımızda
            </Typography>

            <Typography
              onClick={() => {
                window.location.href = "https://www.darphane.gov.tr/";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Darphane
            </Typography>

            <Typography
              onClick={() => {
                window.location.href = "/utts-sık-sorulan-sorular";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Sık Sorulan Sorular
            </Typography>
            <Typography
              onClick={() => {
                window.location.href = "/utts-kayit-sureci";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              UTTS Kayıt Süreci
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
            }}
          >
            <Typography
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BACKEND_URL}/uploads/mevzuat.pdf`,
                  "_blank"
                );
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Mevzuat
            </Typography>

            <Typography
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_BACKEND_URL}/uploads/yetki-belgesi.pdf`,
                  "_blank"
                );
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Yetki Belgesi
            </Typography>

            <Typography
              onClick={() => {
                window.location.href = "/gizlilik";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Gizlilik
            </Typography>

            <Typography
              onClick={() => {
                window.location.href = "/utts-kittak-is-ortagi";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Neden Kit-Tak İş Ortaklığı
            </Typography>

            <Typography
              onClick={() => {
                window.location.href = "/reference/login";
              }}
              sx={{
                fontSize: { xs: 14, sm: 15 },
                color: "white",
                fontWeight: "regular",
                cursor: "pointer",
              }}
            >
              Referans Girişi
            </Typography>
          </Box>
        </Box>
        <Box sx={{ mt: { xs: 2, md: 0 } }}>
          {/*    <Button
            onClick={() => {
              window.location.href = "/utts-musteri-temsilcisi";
            }}
            sx={{
              backgroundColor: "#FDC448",
              color: "white",
              borderRadius: 20,
              px: { xs: 3, sm: 5 },
              py: { xs: 1.5, sm: 3 },
              fontSize: { xs: 14, sm: 16, md: 18 },
              fontWeight: "medium",
              textTransform: "none",
              "&:hover": {
                backgroundColor: "#e0b33d",
              },
            }}
          >
            <Typography
              sx={{
                color: "#02021E",
                fontSize: { xs: 11, sm: 12, md: 13, lg: 14, xl: 16 },
                fontWeight: "medium",
                textTransform: "none",
                whiteSpace: "nowrap",
              }}
            >
              MÜŞTERİ TEMSİLCİMİZ YANINDA
            </Typography>
          </Button> */}
        </Box>
      </Box>
      <Divider
        sx={{
          backgroundColor: "#FDC448",
          height: 1,
          width: "100%",
          mt: { xs: 4, sm: 6 },
        }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        <Typography
          sx={{
            color: "white",
            fontSize: { xs: 12, sm: 14, md: 15 },
            fontWeight: "regular",
            mt: 4,
          }}
        >
          © 2025 Kit-Tak - Tüm Hakları Saklıdır
        </Typography>
      </Box>
    </Box>
  );
};
