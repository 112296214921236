import { AdminLayout } from "../../../components";
import { ReservationAllSection } from "../../../section";

export const ReservationAllPage = () => {
  return (
    <AdminLayout>
      <ReservationAllSection />
    </AdminLayout>
  );
};
