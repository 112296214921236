import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

export const RefCatcher = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const reference = searchParams.get("ref");

    if (reference) {
      const storedRef = localStorage.getItem("reference");
      if (!storedRef || storedRef !== reference) {
        localStorage.setItem("reference", reference);
        localStorage.setItem("reference_time", new Date().toISOString());
      }
    }
  }, [searchParams]);

  return null;
};
