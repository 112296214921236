import {
  Button,
  Container,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {
  CircularProgressModal,
  Footer,
  GreenPaper,
  Header,
} from "../../components";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { axiosInstance } from "../../utils";

export const RefLoginPage = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      await axiosInstance.post("/reference/login/email", { email });
      enqueueSnackbar("Giriş bağlantısı mail adresine gönderildi.", {
        variant: "success",
      });

      navigate("/");
    } catch (error) {
      enqueueSnackbar("Beklemedik bir hata oluştu.", { variant: "error" });
    }

    setLoading(false);
  };

  return (
    <Container sx={{ mt: 2 }}>
      <Header />
      <GreenPaper>
        <Stack
          gap={2}
          sx={{ p: 3, py: 6, alignItems: "center", justifyContent: "center" }}
        >
          <form onSubmit={onSubmit} style={{ maxWidth: 400, width: "100%" }}>
            <Stack
              gap={3}
              sx={{
                border: "1px solid",
                borderColor: "divider",
                p: 3,
                width: "100%",
                borderRadius: 2,
              }}
            >
              <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
                Referanslarım
              </Typography>
              <Stack gap={0.25}>
                <FormControl>
                  <TextField
                    required
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>
                <Typography sx={{ fontSize: "12px", color: "grey.600" }}>
                  Giriş bağlantı linkini mail adresine göndereceğiz.
                </Typography>
              </Stack>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading}
              >
                Giriş Bağlantısı Gönder
              </Button>
            </Stack>
          </form>
        </Stack>
      </GreenPaper>
      <Footer />
    </Container>
  );
};
