export const PATHS = {
  admin: {
    root: "/admin",
    login: "/admin/login",
    montageApplication: {
      root: "/admin/montage-application",
      detail: {
        root: "/admin/montage-application/detail/:id",
        get: (id: string) => `/admin/montage-application/detail/${id}`,
      },
    },
    servicesProviders: {
      root: "/admin/service-providers",
      application: "/admin/service-providers/application",
      profile: {
        root: `/admin/service-providers/profile/:id`,
        get: (id: string) => `/admin/service-providers/profile/${id}`,
      },
    },
    reservation: {
      root: "/admin/reservation",
      detail: {
        root: "/admin/reservation/:id",
        get: (id: string) => `/admin/reservation/${id}`,
      },
    },
    referance: {
      root: "/admin/referance",
    },
    service: {
      root: "/admin/service",
      profile: { root: "/admin/service/profile" },
      onboarding: "/admin/service/onboarding",
    },
  },
  ref: {
    root: `/ref/:token`,
    login: "/reference/login",
  },
  forgotPassword: "forgot-password/:token",
};
